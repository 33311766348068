import { useMemo } from 'react';

const useFormattedDate = (isoDate, isFullFormat = false) => {
  const formattedDate = useMemo(() => {
    if (isFullFormat) {
      return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      })
        .format(new Date(isoDate))
        .replace(',', ' at');
    }

    return new Intl.DateTimeFormat('ru-RU', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(new Date(isoDate));
  }, [isoDate, isFullFormat]);

  return formattedDate;
};

export default useFormattedDate;
