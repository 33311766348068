import 'react-datepicker/dist/react-datepicker.css';
import './analytics.scss';
import Header from '../../components/header/Header';
import { useRef, useState, useEffect } from "react";
import DatePicker from 'react-datepicker';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip
} from 'chart.js';
import MonthSection from '../../components/month/MonthSection';
import { useNavigate, NavLink } from 'react-router-dom';
import config from '../../config';

ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip
)
const Platforms= () => {
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date("2023-01-1"));
    const [endDate, setEndDate] = useState(new Date());
    const [endDateRequest, setEndDateRequest] = useState(new Date());
    const [authToken, setAuthToken] = useState(Cookies.get('authToken'));

    const startDateOptions = { year: '2-digit', month: 'short' };
    const startDateFormattedDate = startDate.toLocaleDateString('en', startDateOptions);

    const endDateOptions = { year: '2-digit', month: 'short' };

    const [dataCheckbox, setDataCheckbox] = useState([true, true, true, true, true, true,]);

    const [isBlockVisible, setIsBlockVisible] = useState(false);

    const dateShow = () => {
        setIsBlockVisible(true);
    };

    const dateHide = () => {
        setIsBlockVisible(false);
        if(endDate === null) {
            setEndDate(startDate);
            setEndDateRequest(startDate)
        } else {
            setEndDateRequest(endDate)
        }

  
    };

    const dataPickerLogoutRef = useRef(null);

    const [byPaymentDate, setByPaymentDate] = useState(true);
    const [byCohort, setByCohort] = useState(true);

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;

        if (name === 'byPaymentDate') {
            setByPaymentDate(checked);
        } else if (name === 'byCohort') {
            setByCohort(checked);
        }
    };

    useEffect(() => {
        getPayments();
    }, [byPaymentDate, byCohort, endDateRequest]);

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [startDate, endDate]);


    const handleChange = ([newStartDate, newEndDate]) => {
        setStartDate(newStartDate);
        setEndDate(newEndDate);
        // if (newEndDate) {
        //     setEndDateRequest(newEndDate)
        // }

    };

    const handleClickOutside = (event) => {
        if (dataPickerLogoutRef.current && !dataPickerLogoutRef.current.contains(event.target)) {
            setIsBlockVisible(false);
            if(endDate === null) {
                setEndDate(startDate);
                setEndDateRequest(startDate)
            }else {
                setEndDateRequest(endDate)
            }
            // getPayments();
        }
    };
    const bgStyle = (color) => ({
        background: color,
    });

    const lineStyle = (color, width) => {
        const customWidth = width + '%';
        return {
            background: color,
            width: customWidth
        };
    };

    const blueColor = '#586BD8';
    const greenColor = '#17CC37';
    const orangeColor = '#FFA215';
    const lightBlueColor = '#6CAFFB';
    const redColor = '#CC2C2C';
    const yellowColor = '#EBD729';

    const yearStart = startDate.getFullYear();
    const monthStart = (startDate.getMonth() + 1).toString().padStart(2, '0');
    const dayStart = startDate.getDate().toString().padStart(2, '0');

    const yearEnd = endDateRequest.getFullYear();
    const monthEnd = (endDateRequest.getMonth() + 1).toString().padStart(2, '0');
    const dayEnd = new Date(endDateRequest.getFullYear(), endDateRequest.getMonth() + 1, 0).getDate().toString().padStart(2, '0');
    const [xLabels, setXLabels] = useState([]);
    const [dataLabels, setDataLabels] = useState([]);
    const [gross, setGross] = useState('');
    const [net, setNet] = useState('');
    const [refunded, setRefunded] = useState('');
    const [alert, setAlert] = useState('');
    const [fraud, setFraud] = useState('');
    const [dispute, setDispute] = useState('');
    const [dataCounts, setDataCounts] = useState([]);

    function getPayments() {
        const url = `${config.apiBaseUrl}admins/payments/platform?dateStart=${yearStart}-${monthStart}-${dayStart}&dateEnd=${yearEnd}-${monthEnd}-${dayEnd}`;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('authToken')}`,
        };

        axios.get(url, { headers })
            .then(response => {
                const dates = Object.keys(response.data.data).map(dateKey => {
                    const dateStr = dateKey;
                    return dateStr;
                });
                setXLabels(dates);
                const grossValues = Object.values(response.data.data).map(item => item.net + item.refunded + item.alert + item.fraud + item.dispute);
                const netValues = Object.values(response.data.data).map(item => item.net);
                const refundedValues = Object.values(response.data.data).map(item => item.refunded);
                const alertValues = Object.values(response.data.data).map(item => item.alert);
                const fraudValues = Object.values(response.data.data).map(item => item.fraud);
                const disputeValues = Object.values(response.data.data).map(item => item.dispute);

                function reduceAndSanitize(values) {
                    return values.reduce((total, currentValue) => {
                        if (typeof currentValue === 'number' && !isNaN(currentValue)) {
                            const truncatedValue = parseInt(currentValue.toString().slice(0, -2));
                            if (!isNaN(truncatedValue)) {
                                return total + truncatedValue;
                            }
                        }
                        return total;
                    }, 0);
                }

                setGross(reduceAndSanitize(grossValues));
                setNet(reduceAndSanitize(netValues));
                setRefunded(reduceAndSanitize(refundedValues));
                setAlert(reduceAndSanitize(alertValues));
                setFraud(reduceAndSanitize(fraudValues));
                setDispute(reduceAndSanitize(disputeValues));

                const dataLabels = [grossValues, netValues, refundedValues, alertValues, fraudValues, disputeValues];
                const truncatedData = dataLabels.map(row => row.map(value => {
                    if (typeof value === 'number') {
                        const stringValue = value.toString();
                        const truncatedValue = parseInt(stringValue.slice(0, -2));

                        if (!isNaN(truncatedValue)) {
                            return truncatedValue;
                        } else {
                            return 0;
                        }
                    }
                }));
                setDataLabels(truncatedData);

                const grossCount = Object.values(response.data.data).map(item => item.countAll);
                const netCount = Object.values(response.data.data).map(item => item.netCount);
                const refundedCount = Object.values(response.data.data).map(item => item.refundCount);
                const alertCount = Object.values(response.data.data).map(item => item.alertCount);
                const fraudCount = Object.values(response.data.data).map(item => item.fraudCount);
                const disputeCount = Object.values(response.data.data).map(item => item.disputeCount);
                setDataCounts([grossCount, netCount, refundedCount, alertCount, fraudCount, disputeCount])
            })
            .catch(error => {
                console.error('Error:', error);
                const axiosConfig = {
                    method: 'post',
                    url: `${config.apiBaseUrl}admins/auth/refresh`,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {
                        refreshToken: Cookies.get('refreshToken'),
                    },
                };
                axios(axiosConfig)
                    .then((response) => {
                        const token = response.data.accessToken
                        const refreshToken = response.data.refreshToken
                        Cookies.set('authToken', token, { expires: 7 });
                        Cookies.set('refreshToken', refreshToken, { expires: 7 });
                        getPayments();
                    })
                    .catch((error) => {
                        console.error('Error:', error);
                        Cookies.remove('authToken');
                        Cookies.remove('refreshToken');
                        navigate("/");
                    });
            });
    }

    const datasetColors = [
        blueColor,
        greenColor,
        orangeColor,
        lightBlueColor,
        redColor,
        yellowColor,
    ];

    const datasetLabels = [
        "Gross volume",
        "Net volume",
        "Refunded",
        "Alert",
        "Fraud",
        "Dispute",
    ];
    const datasets = datasetLabels
        .map((label, index) => ({
            label: label,
            data: dataLabels[index], // Replace this with your actual data
            borderColor: datasetColors[index],
            pointBackgroundColor: datasetColors[index],
            borderWidth: 2,
            pointRadius: 7,
            pointHoverRadius: 7,
            pointBorderColor: '#FFF',

        }))
        .filter((dataset, index) => dataCheckbox[index]);

    const data = {
        labels: xLabels,
        datasets
    };


    const getOrCreateTooltip = (chart) => {
        let tooltipEl = chart.canvas.parentNode.querySelector('div');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            //   tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
            //   tooltipEl.style.borderRadius = '3rem';
            //   tooltipEl.style.color = 'white';
            tooltipEl.style.opacity = 1;
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transform = 'translate(-50%, 0)';
            tooltipEl.style.transition = 'all .1s ease';

            const table = document.createElement('table');
            table.style.margin = '0rem';

            tooltipEl.appendChild(table);
            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    };

    const externalTooltipHandler = (context) => {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set Text
        if (tooltip.dataPoints) {

            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.dataPoints.map(b => b.formattedValue);

            const tableHead = document.createElement('thead');

            titleLines.forEach((title, i) => {
                const tr = document.createElement('tr');
                tr.style.borderWidth = 0;

                const th = document.createElement('th');
                th.style.borderWidth = 0;
                const text = document.createTextNode(title);
                const colors = tooltip.labelColors[i];
                const span = document.createElement('span');
                span.style.background = colors.backgroundColor;
                span.style.marginRight = '4rem';
                span.style.borderRadius = '50%';
                span.style.height = '6rem';
                span.style.width = '6rem';
                span.style.display = 'inline-block';

                th.appendChild(span);
                th.appendChild(text);
                tr.appendChild(th);
                tableHead.appendChild(tr);
            });

            const tableBody = document.createElement('tbody');
            bodyLines.forEach((body, i) => {

                const tr = document.createElement('tr');
                tr.style.backgroundColor = 'inherit';
                tr.style.borderWidth = 0;

                const td = document.createElement('td');
                td.style.borderWidth = 0;

                const text = document.createTextNode('Total: ');

                const span = document.createElement('span');
                span.textContent = '$' + body;

                td.appendChild(text);
                td.appendChild(span);
                tr.appendChild(td);
                tableBody.appendChild(tr);
            });

            const tableRoot = tooltipEl.querySelector('table');

            // Remove old children
            while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
            }

            // Add new children
            tableRoot.appendChild(tableHead);
            tableRoot.appendChild(tableBody);
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = positionX + tooltip.caretX + 'rem';
        tooltipEl.style.top = positionY + tooltip.caretY - 115 + 'rem';
        tooltipEl.style.font = tooltip.options.bodyFont.string;
        // tooltipEl.style.padding = tooltip.options.padding + 'rem ' + tooltip.options.padding + 'rem';
        tooltipEl.className = `custom-tooltip`;
    };

    const options = {
        maintainAspectRatio: false,
        onHover: function (event, chartElement) {
            if (chartElement && chartElement.length > 0) {
                var index = chartElement[0]._index;
            }
        },
        plugins: {

            tooltip: {
                enabled: false,
                position: 'nearest',
                external: externalTooltipHandler,
            },
        },
        scales: {
            y: {
                ticks: {
                    suggestedMin: 0,
                    borderDash: [5, 5],

                    color: "#A7A8AA",
                    font: {
                        family: 'Italian Plate No2 Expanded',
                        size: 14,

                        weight: '500'
                    },
                    callback: function (value, index, array) {
                        const formattedValue = value < 1000000 ? value / 1000 + 'K' : value / 1000000 + 'M';
                        return '$' + formattedValue;
                    },
                },
                grid: {
                    display: true,
                    color: '#F2F4F6',

                },
            },
            x: {
                ticks: {
                    beginAtZero: true,
                    font: {
                        family: 'Italian Plate No2 Expanded',
                        size: 14,
                        color: "#A7A8AA",
                        weight: '500'
                    },

                },
                border: {
                    dash: [10, 10],
                },
                grid: {
                    display: true,
                    color: '#F2F4F6',


                },
            }
        }
    }

    const checkboxData = datasetLabels.map((label, index) => (
        <label key={index}>
            <input
                type="checkbox"
                checked={dataCheckbox[index]}
                onChange={() => {
                    const updatedDataCheckbox = [...dataCheckbox];
                    updatedDataCheckbox[index] = !updatedDataCheckbox[index];
                    setDataCheckbox(updatedDataCheckbox);
                }}
            />
            <div className="analytics-block-visibility__icon" style={bgStyle(datasetColors[index])}>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
                        <path d="M3.66016 7.04723L1.29726 4.68433C1.12292 4.50999 0.840253 4.50999 0.665912 4.68433C0.491571 4.85867 0.491571 5.14133 0.665912 5.31567L3.34448 7.99424C3.51883 8.16859 3.80149 8.16859 3.97583 7.99424L9.33297 2.6371C9.50731 2.46276 9.50731 2.1801 9.33297 2.00576C9.15863 1.83141 8.87597 1.83141 8.70163 2.00576L3.66016 7.04723Z" fill="white" stroke="white" stroke-width="0.78125" />
                    </svg>
                </span>
            </div>
            <p>{label}</p>
        </label>
    ));

    return (
        <div className='main'>
            <Header />
            <div className="content">
                <div className="container">
                    <div className="analytics">
                        <div className="analytics__title">
                            <NavLink to="/analytics/overview" activeClassName="active">
                                Overview
                            </NavLink>
                            <NavLink to="/analytics/cohorts" activeClassName="active">
                                Cohorts
                            </NavLink>
                            <NavLink to="/analytics/products" activeClassName="active">
                                Products
                            </NavLink>
                            <NavLink to="/analytics/platforms" activeClassName="active">
                                Platforms
                            </NavLink>
                        </div>
                        <div className="analytics-top">
                            <span>Choose month:</span>
                            <div className="analytics-top-date-wrap">
                                <div className="analytics-top-date" onClick={dateShow}>
                                    <div className="analytics-top-date-icon">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7979 4.93255H3.19792C2.60881 4.93255 2.13125 5.41012 2.13125 5.99922V12.9326C2.13125 13.5217 2.60881 13.9992 3.19792 13.9992H12.7979C13.387 13.9992 13.8646 13.5217 13.8646 12.9326V5.99922C13.8646 5.41012 13.387 4.93255 12.7979 4.93255ZM3.19792 1.19922C1.72516 1.19922 0.53125 2.39313 0.53125 3.86589V12.9326C0.53125 14.4053 1.72516 15.5992 3.19792 15.5992H12.7979C14.2707 15.5992 15.4646 14.4053 15.4646 12.9326V3.86589C15.4646 2.39313 14.2707 1.19922 12.7979 1.19922H3.19792Z" fill="black" />
                                            <rect x="3.19824" y="0.402344" width="1.6" height="2.13333" rx="0.8" fill="black" />
                                            <rect x="11.1982" y="0.402344" width="1.6" height="2.13333" rx="0.8" fill="black" />
                                        </svg>
                                    </div>
                                    <div className="analytics-top-date-text">
                                        {startDateFormattedDate} -
                                        {endDate && <div>{endDate.toLocaleDateString('en', endDateOptions)}</div>}
                                    </div>
                                    <div className="analytics-top-date-arrow">
                                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11 1L6 6L1 1" stroke="#A7A8AA" stroke-width="1.9" stroke-linecap="round" />
                                        </svg>
                                    </div>
                                </div>
                                {isBlockVisible && (
                                    <div className="analytics-top-date-block" ref={dataPickerLogoutRef} >
                                        <DatePicker
                                            selected={startDate}
                                            onChange={handleChange}
                                            selectsRange
                                            startDate={startDate}
                                            endDate={endDate}
                                            maxDate={new Date()}
                                            showMonthYearPicker
                                            inline
                                        />
                                        <div className="analytics-top-date__btn" onClick={dateHide}>
                                            Select
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
                        <div className="analytics-block">
                            <div className="analytics-block-top">
                                <div className="analytics-block-item">
                                    <i style={bgStyle(blueColor)}></i>
                                    <span>Gross volume</span>
                                    <strong>${gross.toLocaleString('en', { useGrouping: true })}</strong>
                                </div>
                                <div className="analytics-block-item">
                                    <i style={bgStyle(greenColor)}></i>
                                    <span>Net volume </span>
                                    <strong>${net.toLocaleString('en', { useGrouping: true })}</strong>
                                </div>
                                <div className="analytics-block-item">
                                    <i style={bgStyle(orangeColor)}></i>
                                    <span>Refunded </span>
                                    <strong>${refunded.toLocaleString('en', { useGrouping: true })}</strong>
                                </div>
                                <div className="analytics-block-item">
                                    <i style={bgStyle(lightBlueColor)}></i>
                                    <span>Alert</span>
                                    <strong>${alert.toLocaleString('en', { useGrouping: true })}</strong>
                                </div>
                                <div className="analytics-block-item">
                                    <i style={bgStyle(redColor)}></i>
                                    <span>Fraud </span>
                                    <strong>${fraud.toLocaleString('en', { useGrouping: true })}</strong>
                                </div>
                                <div className="analytics-block-item">
                                    <i style={bgStyle(yellowColor)}></i>
                                    <span>Dispute </span>
                                    <strong>${dispute.toLocaleString('en', { useGrouping: true })}</strong>
                                </div>
                            </div>
                            <div className="analytics-block-line" >
                                <Line width="100%" data={data} options={options} />
                            </div>
                            <div className="analytics-block-visibility">
                                <span>Graphics visibility:</span>
                                {checkboxData}
                            </div>
                        </div>
                        <h3>Gross volume by months</h3>



                        {/* {allData.map((monthData, index) => (
                            <div>1</div>
                        ))} */}

                        <div className="analytics-wrapper">
                            {xLabels.map((monthData, index) => (
                                <MonthSection
                                    indexMonth={index}
                                    month={monthData}
                                    data={dataLabels}
                                    colors={datasetColors}
                                    datasetLabels={datasetLabels}
                                    dataCounts={dataCounts}
                                />
                            ))}
                        </div>


                        {/* <div className="analytics-month">
                            <h4>October, 23</h4>
                            <div className="analytics-month-wrapper">
                                <div className="analytics-month-item">
                                    <strong>$21,505</strong>
                                    <span>Gross volume</span>
                                </div>
                                <div className="analytics-month-item">
                                    <strong>$17,367</strong>
                                    <span><i style={bgStyle(greenColor)}></i> Net volume (+3,24%)</span>
                                </div>
                                <div className="analytics-month-item">
                                    <strong>$872</strong>
                                    <span><i style={bgStyle(orangeColor)}></i> Refunded (-0,54%)</span>
                                </div>
                                <div className="analytics-month-item">
                                    <strong>$2,712</strong>
                                    <span><i style={bgStyle(lightBlueColor)}></i> Alert (+0,12%)</span>
                                </div>
                                <div className="analytics-month-item">
                                    <strong>$1,525</strong>
                                    <span><i style={bgStyle(redColor)}></i> Fraud (+0,34%)</span>
                                </div>
                                <div className="analytics-month-item">
                                    <strong>$363</strong>
                                    <span><i style={bgStyle(yellowColor)}></i> Dispute (-0,24%) </span>
                                </div>
                            </div>
                            <div className="analytics-month-line">
                                <div className="analytics-month-line-item" style={lineStyle(greenColor, 60)}>
                                    <div className="analytics-month-line-item-info">
                                        <span><i style={bgStyle(greenColor)}></i> Net volume (+3,24%)</span>
                                        <ul>
                                            <li>
                                                Total: <strong>$872</strong>
                                            </li>
                                            <li>Ratio:
                                                <strong>77.24%</strong></li>
                                            <li>Quantity:
                                                <strong>19</strong></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="analytics-month-line-item" style={lineStyle(orangeColor, 10)}>
                                    <div className="analytics-month-line-item-info">
                                        <span><i style={bgStyle(orangeColor)}></i> Refunded (-0,54%)</span>
                                        <ul>
                                            <li>
                                                Total: <strong>$872</strong>
                                            </li>
                                            <li>Ratio:
                                                <strong>77.24%</strong></li>
                                            <li>Quantity:
                                                <strong>19</strong></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="analytics-month-line-item" style={lineStyle(lightBlueColor, 15)}>
                                    <div className="analytics-month-line-item-info">
                                        <span><i style={bgStyle(lightBlueColor)}></i> Alert (+0,12%)</span>
                                        <ul>
                                            <li>
                                                Total: <strong>$872</strong>
                                            </li>
                                            <li>Ratio:
                                                <strong>77.24%</strong></li>
                                            <li>Quantity:
                                                <strong>19</strong></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="analytics-month-line-item" style={lineStyle(redColor, 10)}>
                                    <div className="analytics-month-line-item-info">
                                        <span><i style={bgStyle(redColor)}></i> Fraud (+0,34%)</span>
                                        <ul>
                                            <li>
                                                Total: <strong>$872</strong>
                                            </li>
                                            <li>Ratio:
                                                <strong>77.24%</strong></li>
                                            <li>Quantity:
                                                <strong>19</strong></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="analytics-month-line-item" style={lineStyle(yellowColor, 5)}>
                                    <div className="analytics-month-line-item-info">
                                        <span><i style={bgStyle(yellowColor)}></i> Dispute (-0,24%) </span>
                                        <ul>
                                            <li>
                                                Total: <strong>$872</strong>
                                            </li>
                                            <li>Ratio:
                                                <strong>77.24%</strong></li>
                                            <li>Quantity:
                                                <strong>19</strong></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="analytics-month">
                            <h4>September, 23</h4>
                            <div className="analytics-month-wrapper">
                                <div className="analytics-month-item">
                                    <strong>$21,505</strong>
                                    <span>Gross volume</span>
                                </div>
                                <div className="analytics-month-item">
                                    <strong>$17,367</strong>
                                    <span><i style={bgStyle(greenColor)}></i> Net volume (+3,24%)</span>
                                </div>
                                <div className="analytics-month-item">
                                    <strong>$872</strong>
                                    <span><i style={bgStyle(orangeColor)}></i> Refunded (-0,54%)</span>
                                </div>
                                <div className="analytics-month-item">
                                    <strong>$2,712</strong>
                                    <span><i style={bgStyle(lightBlueColor)}></i> Alert (+0,12%)</span>
                                </div>
                                <div className="analytics-month-item">
                                    <strong>$1,525</strong>
                                    <span><i style={bgStyle(redColor)}></i> Fraud (+0,34%)</span>
                                </div>
                                <div className="analytics-month-item">
                                    <strong>$363</strong>
                                    <span><i style={bgStyle(yellowColor)}></i> Dispute (-0,24%) </span>
                                </div>
                            </div>
                            <div className="analytics-month-line">
                                <div className="analytics-month-line-item" style={lineStyle(greenColor, 65)}>
                                    <div className="analytics-month-line-item-info">
                                        <span><i style={bgStyle(greenColor)}></i> Net volume (+3,24%)</span>
                                        <ul>
                                            <li>
                                                Total: <strong>$872</strong>
                                            </li>
                                            <li>Ratio:
                                                <strong>77.24%</strong></li>
                                            <li>Quantity:
                                                <strong>19</strong></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="analytics-month-line-item" style={lineStyle(orangeColor, 8)}>
                                    <div className="analytics-month-line-item-info">
                                        <span><i style={bgStyle(orangeColor)}></i> Refunded (-0,54%)</span>
                                        <ul>
                                            <li>
                                                Total: <strong>$872</strong>
                                            </li>
                                            <li>Ratio:
                                                <strong>77.24%</strong></li>
                                            <li>Quantity:
                                                <strong>19</strong></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="analytics-month-line-item" style={lineStyle(lightBlueColor, 12)}>
                                    <div className="analytics-month-line-item-info">
                                        <span><i style={bgStyle(lightBlueColor)}></i> Alert (+0,12%)</span>
                                        <ul>
                                            <li>
                                                Total: <strong>$872</strong>
                                            </li>
                                            <li>Ratio:
                                                <strong>77.24%</strong></li>
                                            <li>Quantity:
                                                <strong>19</strong></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="analytics-month-line-item" style={lineStyle(redColor, 10)}>
                                    <div className="analytics-month-line-item-info">
                                        <span><i style={bgStyle(redColor)}></i> Fraud (+0,34%)</span>
                                        <ul>
                                            <li>
                                                Total: <strong>$872</strong>
                                            </li>
                                            <li>Ratio:
                                                <strong>77.24%</strong></li>
                                            <li>Quantity:
                                                <strong>19</strong></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="analytics-month-line-item" style={lineStyle(yellowColor, 5)}>
                                    <div className="analytics-month-line-item-info">
                                        <span><i style={bgStyle(yellowColor)}></i> Dispute (-0,24%) </span>
                                        <ul>
                                            <li>
                                                Total: <strong>$872</strong>
                                            </li>
                                            <li>Ratio:
                                                <strong>77.24%</strong></li>
                                            <li>Quantity:
                                                <strong>19</strong></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="analytics-month">
                            <h4>June, 21</h4>
                            <div className="analytics-month-wrapper">
                                <div className="analytics-month-item">
                                    <strong>$21,505</strong>
                                    <span>Gross volume</span>
                                </div>
                                <div className="analytics-month-item">
                                    <strong>$17,367</strong>
                                    <span><i style={bgStyle(greenColor)}></i> Net volume (+3,24%)</span>
                                </div>
                                <div className="analytics-month-item">
                                    <strong>$872</strong>
                                    <span><i style={bgStyle(orangeColor)}></i> Refunded (-0,54%)</span>
                                </div>
                                <div className="analytics-month-item">
                                    <strong>$2,712</strong>
                                    <span><i style={bgStyle(lightBlueColor)}></i> Alert (+0,12%)</span>
                                </div>
                                <div className="analytics-month-item">
                                    <strong>$1,525</strong>
                                    <span><i style={bgStyle(redColor)}></i> Fraud (+0,34%)</span>
                                </div>
                                <div className="analytics-month-item">
                                    <strong>$363</strong>
                                    <span><i style={bgStyle(yellowColor)}></i> Dispute (-0,24%) </span>
                                </div>
                            </div>
                            <div className="analytics-month-line">
                                <div className="analytics-month-line-item" style={lineStyle(greenColor, 100)}>
                                    <div className="analytics-month-line-item-info">
                                        <span><i style={bgStyle(greenColor)}></i> Net volume (+3,24%)</span>
                                        <ul>
                                            <li>
                                                Total: <strong>$872</strong>
                                            </li>
                                            <li>Ratio:
                                                <strong>77.24%</strong></li>
                                            <li>Quantity:
                                                <strong>19</strong></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Platforms;
