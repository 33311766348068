import React from 'react'
import './style.scss'
import Fancybox from '../../../promotionsNew/components/modal-Creatives/fancybox'

function ProductImages({ data }) {
    return (
        <Fancybox>


            <div className="products-images">
                <div className="products-images__title">
                    Images
                </div>
                <div className="products-images-wrapper">
                    {data?.photos?.map((photo) => (
                        <a href={photo.url} data-fancybox="gallery"  className="products-images__img" key={photo.id}>
                            <img src={photo.url} alt="" />
                        </a>
                    ))}
                </div>
            </div> </Fancybox>
    )
}

export default ProductImages