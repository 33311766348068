import React from 'react'
import './style.scss'

export default function VideosInfo({ data }) {
    const items = {
        "Service": 0,
        "Physical Product": 0,
        "Digital Product": 0
    };

    data?.data?.countByType.forEach(item => {
        items[item.type] = item.count;
    });
    if (!data) {
        return
    }
    return (
        <div className="videos-block-wrapper">
            <div className="videos-block">
            <div className="videos-block-wrap">
                <div className="videos-block-item">
                    <strong >12</strong>
                    <span>Total items</span>
                </div>

            </div>
            <div className="videos-block-wrap">
                <div className="videos-block-item">
                    <strong >11</strong>
                    <span>Finished</span>
                </div>
                <div className="videos-block-item">
                    <strong >1</strong>
                    <span>Rendering</span>
                </div>
            </div>

        </div>
            <div className="videos-block">
                <div className="videos-block-wrap">
                    <div className="videos-block-item">
                        <strong >12</strong>
                        <span>Available Credits</span>
                    </div>
                    <div className="videos-block-item">
                        <strong >18</strong>
                        <span>Used Credits</span>
                    </div>
                </div>
                <div className="videos-block-wrap">

                    <div className="videos-block-item" style={{width: '131rem'}}>
                        <strong >09/09/2024</strong>
                        <span>Expire date</span>
                    </div>
                </div>

            </div>
        </div>

    )
}
