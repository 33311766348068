import { data } from "jquery";

function createDataPromotions(promationImg, promationCreatives, promationPrimaryText, promationStatus, promationWebsite, promationDate, promationDuration, promationSpent, promationBudget) {
    return { promationImg, promationCreatives, promationPrimaryText, promationStatus, promationWebsite, promationDate, promationDuration, promationSpent, promationBudget };
}

const formatDate = (dateString) => {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('ru-RU', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
    }).format(date).replace(',', ' at');
};


export const processPromotionData = (promotionData) => {
    const groupedAds = Object.values(
        promotionData.items.reduce((result, ad) => {
            const { facebookCampaignId, ...rest } = ad;
            if (!result[facebookCampaignId]) {
                result[facebookCampaignId] = [];
            }
            result[facebookCampaignId].push(rest);
            return result;
        }, {})
    ).filter(group =>
        !((group[0].facebookCampaignId && group[0].facebookCampaignId.startsWith('MODERATION_')) ||
            (group[0].facebookAdSetId && group[0].facebookAdSetId.startsWith('MODERATION_')) ||
            (group[0].facebookAdId && group[0].facebookAdId.startsWith('MODERATION_')))
    );

    const dataRow = groupedAds.map((group, index) => {
        const dateStart = new Date(group[0].createdAt);
        const dateStop = new Date(group[0].endDate);
        const differenceMs = Math.abs(dateStop - dateStart);
        const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24)) - 1;
        const status = group[0].effectiveStatus.toLowerCase();
        const formattedStatus = status.charAt(0).toUpperCase() + status.slice(1);
        const totalSpend = group.reduce((accumulator, current) => accumulator + current.spend, 0).toFixed(2);
        
        return createDataPromotions(
            group[0].imageUrl,
            group.length,
            group[0].primaryText,
            formattedStatus,
            group[0].websiteUrl,
            `from ${formatDate(group[0].createdAt)} <br> to ${formatDate(group[0].endDate)}`,
            `${differenceDays} days`,
            `$${totalSpend}`,
            `$${group[0].budget}`
        );
    });

    return { groupedAds, dataRow };
};
