import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import './sidebar.scss';
import UserInfo from '../../pages/UserInfo/UserInfo';

import Promotions from '../../pages/promotions/Promotions';
import Creatives from '../../pages/creatives/Creatives';
import Notification from '../../pages/notification/Notification';
import SendHistory from '../../pages/sendHistory/SendHistory';
import Audience from '../../pages/audience/Audience';


function CustomTabPanel(props) {

  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const SideBarNew = ({ onSetSidebarOpen, snackbarOpen, setSnackbarOpen, value}) => {







  return (
    <Box sx={{ width: '100%' }}>
      <div className="sidebar-wrapper">
        <div className="sidebar-nav">
          <div className="sidebar-close" onClick={() => onSetSidebarOpen(false)}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6 6L18 18" stroke="black" stroke-width="1.6" stroke-linecap="round" />
              <path d="M18 6L6 18" stroke="black" stroke-width="1.6" stroke-linecap="round" />
            </svg>
          </div>
        </div>
        <div className="sidebar-right">
              <CustomTabPanel value={value} index={0}>
                <Notification onSetSidebarOpen={onSetSidebarOpen}/>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Audience />
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <SendHistory />
              </CustomTabPanel>
          
        </div>
      </div>
    </Box>


  );
}

export default SideBarNew;
