import 'react-datepicker/dist/react-datepicker.css';
import './analytics.scss';
import Header from '../../components/header/Header';
import { useRef, useState, useEffect } from "react";
import DatePicker from 'react-datepicker';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip
} from 'chart.js';

import { useNavigate, NavLink } from 'react-router-dom';
import SelectCustomChart from '../../components/selectCustom/SelectCustomChart';
import config from '../../config';

ChartJS.register(
    LineElement,
    CategoryScale,
    LinearScale,
    PointElement,
    Tooltip
)
const Analytics = () => {
    const navigate = useNavigate();
    const [startDate, setStartDate] = useState(new Date("2023-01-1"));
    const [endDate, setEndDate] = useState(new Date("2023-10-31"));
    const [endDateRequest, setEndDateRequest] = useState(new Date('2023-10-31'));

    const startDateOptions = { year: '2-digit', month: 'short' };
    const startDateFormattedDate = startDate.toLocaleDateString('en', startDateOptions);

    const endDateOptions = { year: '2-digit', month: 'short' };

    const [isBlockVisible, setIsBlockVisible] = useState(false);

    const dateShow = () => {
        setIsBlockVisible(true);
    };

    const dateHide = () => {
        setIsBlockVisible(false);
        getPayments();
    };

    const dataPickerLogoutRef = useRef(null);

    // useEffect(() => {
    //     getPayments();
    // }, []);
    const lineStyle = (color, width) => {
        const customWidth = width + '%';

        return {
            background: color,
            width: customWidth
        };
    };



    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [startDate, endDate]);


    const handleChange = ([newStartDate, newEndDate]) => {
        // setStartDate(newStartDate);
        // setEndDate(newEndDate);
        // if (newEndDate) {
        //     setEndDateRequest(newEndDate)
        // }

    };

    const handleClickOutside = (event) => {
        if (dataPickerLogoutRef.current && !dataPickerLogoutRef.current.contains(event.target)) {
            setIsBlockVisible(false);
            getPayments();
        }
    };
    const bgStyle = (color) => ({
        background: color,
    });

    const blueColor = '#586BD8';
    const greenColor = '#17CC37';
    const orangeColor = '#FFA215';
    const lightBlueColor = '#6CAFFB';
    const redColor = '#CC2C2C';
    const yellowColor = '#EBD729';
    const grayColor = '#E1E3E5';

    const yearStart = startDate.getFullYear();
    const monthStart = (startDate.getMonth() + 1).toString().padStart(2, '0');
    const dayStart = startDate.getDate().toString().padStart(2, '0');

    const yearEnd = endDateRequest.getFullYear();
    const monthEnd = (endDateRequest.getMonth() + 1).toString().padStart(2, '0');
    const dayEnd = endDateRequest.getDate().toString().padStart(2, '0');



    // const [xLabels, setXLabels] = useState([[]]);
    // const [dataLabels, setDataLabels] = useState([]);
    const [gross, setGross] = useState('');
    const [net, setNet] = useState('');
    const [refunded, setRefunded] = useState('');
    const [alert, setAlert] = useState('');
    const [fraud, setFraud] = useState('');
    const [dispute, setDispute] = useState('');
    const [dataCounts, setDataCounts] = useState([]);

    function getPayments() {
        const url = `${config.apiBaseUrl}admins/payments?dateStart=${yearStart}-${monthStart}-${dayStart}&dateEnd=${yearEnd}-${monthEnd}-${dayEnd}&byPaymentDate=false`;
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${Cookies.get('authToken')}`,
        };
        axios.get(url, { headers })
            .then(response => {
                const dates = Object.keys(response.data).map(dateKey => {
                    const [year, month] = dateKey.split('-');
                    const dateStr = new Date(Number(year), Number(month) - 1, 1).toLocaleString('en-US', { month: 'short', year: 'numeric' });
                    return dateStr;
                });
                // setXLabels(dates);

                const grossValues = Object.values(response.data).map(item => item.net + item.refunded + item.alert + item.fraud + item.dispute);
                const netValues = Object.values(response.data).map(item => item.net);
                const refundedValues = Object.values(response.data).map(item => item.refunded);
                const alertValues = Object.values(response.data).map(item => item.alert);
                const fraudValues = Object.values(response.data).map(item => item.fraud);
                const disputeValues = Object.values(response.data).map(item => item.dispute);

                function reduceAndSanitize(values) {
                    return values.reduce((total, currentValue) => {
                        if (typeof currentValue === 'number' && !isNaN(currentValue)) {
                            const truncatedValue = parseInt(currentValue.toString().slice(0, -2));
                            if (!isNaN(truncatedValue)) {
                                return total + truncatedValue;
                            }
                        }
                        return total;
                    }, 0);
                }

                setGross(reduceAndSanitize(grossValues));
                setNet(reduceAndSanitize(netValues));
                setRefunded(reduceAndSanitize(refundedValues));
                setAlert(reduceAndSanitize(alertValues));
                setFraud(reduceAndSanitize(fraudValues));
                setDispute(reduceAndSanitize(disputeValues));

                const dataLabels = [grossValues, netValues, refundedValues, alertValues, fraudValues, disputeValues];
                const truncatedData = dataLabels.map(row => row.map(value => {
                    if (typeof value === 'number') {
                        const stringValue = value.toString();
                        const truncatedValue = parseInt(stringValue.slice(0, -2));

                        if (!isNaN(truncatedValue)) {
                            return truncatedValue;
                        } else {
                            return 0;
                        }
                    }
                }));
                // setDataLabels(truncatedData);

                const grossCount = Object.values(response.data).map(item => item.countAll);
                const netCount = Object.values(response.data).map(item => item.netCount);
                const refundedCount = Object.values(response.data).map(item => item.refundCount);
                const alertCount = Object.values(response.data).map(item => item.alertCount);
                const fraudCount = Object.values(response.data).map(item => item.fraudCount);
                const disputeCount = Object.values(response.data).map(item => item.disputeCount);
                setDataCounts([grossCount, netCount, refundedCount, alertCount, fraudCount, disputeCount])
            })
            .catch(error => {
                console.error('Error:', error);
                const axiosConfig = {
                    method: 'post',
                    url:`${config.apiBaseUrl}admins/auth/refresh`,
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    data: {
                        refreshToken: Cookies.get('refreshToken'),
                    },
                };
                axios(axiosConfig)
                    .then((response) => {
                        const token = response.data.accessToken
                        const refreshToken = response.data.refreshToken
                        Cookies.set('authToken', token, { expires: 7 });
                        Cookies.set('refreshToken', refreshToken, { expires: 7 });
                        getPayments();
                    })
                    .catch((error) => {
                        Cookies.remove('authToken');
                        Cookies.remove('refreshToken');
                        navigate("/");
                    });
            });
    }

    const datasetColors = [
        [
            blueColor,
            grayColor
        ],
        [
            greenColor,
            grayColor
        ],
        [
            orangeColor,
            grayColor
        ],
        [
            yellowColor,
            grayColor
        ],
        [
            lightBlueColor,
            grayColor
        ],
    ];

    const datasetLabels = [
        [
            "Active",
            "Previous",
        ],
        [
            "Active",
            "Previous",
        ],
        [
            "Active",
            "Previous",
        ],
        [
            "Active",
            "Previous",
        ],
        [
            "Active",
            "Previous",
        ],
    ];
    const xLabels = [
        ['Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Today'],
        ['Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Today'],
        ['Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Today'],
        ['Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Today'],
        ['Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Oct 2023', 'Today']
    ];
    const dataLabels = [
        [
            [5678, 1234, 8910, 4321, 9919, 4444, 2222, 8888, 7777, 3333, 7777],
            [2468, 5555, 7777, 8888, 1111, 6666, 9919, 1234, 9876, 5555, 9919],
        ],
        [
            [4444, 2222, 8888, 7777, 3333, 9876, 5555, 4321, 4444, 5678, 5555],
            [6666, 9999, 1234, 9876, 5555, 1111, 7777, 9876, 5555, 6666, 4321],
        ],
        [
            [9876, 5555, 4321, 4444, 5678, 2468, 1234, 5555, 7777, 9999, 7777],
            [1111, 7777, 9876, 5555, 6666, 8910, 1234, 5555, 9876, 7777, 4321],
        ],
        [
            [2468, 1234, 5555, 7777, 9999, 2468, 7777, 5555, 1234, 9876, 7777],
            [8910, 1234, 5555, 9876, 7777, 6666, 4444, 9999, 7777, 5555, 8888],
        ],
        [
            [2468, 7777, 5555, 1234, 7000, 4444, 2222, 8889, 7777, 3333, 5555],
            [6666, 4444, 7989, 7777, 5555, 6666, 8000, 1234, 7895, 5555, 4321],
        ]
    ]


    const datasets = datasetLabels.map((label, index) =>
        label.map((subLabel, i) => {
            let pointRadius = []; // Default value

            if (i === 1) {
                pointRadius = 0;
            } else if (i === 0) {
                pointRadius = dataLabels[index][i].map((label, dataIndex) =>
                    (dataIndex === 0 || dataIndex === dataLabels[index][i].length - 1) ? 7 : 0
                );

            } else {

            }

            return {
                label: subLabel,
                data: dataLabels[index][i],
                borderColor: datasetColors[index][i],
                pointBackgroundColor: datasetColors[index][i],
                borderWidth: 2,
                pointRadius: pointRadius,
                pointHoverRadius: pointRadius,
                pointBorderColor: '#FFF',
            }
        })
    );

    const data = datasetLabels.map((label, index) => ({
        labels: xLabels[index],
        datasets: datasets[index],
    }))

    function roundToNearest(value, nearest) {
        return Math.ceil(value / nearest) * nearest;
    }

    function findMaxValue(data) {
        let max = data[0][0]; // Initialize max with the first value

        for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < data[i].length; j++) {
                if (data[i][j] > max) {
                    max = data[i][j];
                }
            }
        }

        return max;
    }

    const options = datasetLabels.map((label, index) => ({

        maintainAspectRatio: false,
        maxLines: 4,
        plugins: {
            tooltip: {
                enabled: false,
            },
            annotation: {
                annotations: [
                  {
                    type: 'line',
                    mode: 'vertical',
                    scaleID: 'x',
                    value: datasetLabels[datasetLabels.length - 1],
                    borderColor: 'red', // Цвет линии
                    borderWidth: 2, // Ширина линии
                  },
                ],
              },
        },
        scales: {
            y: {
                ticks: {
                
                    stepSize: findMaxValue(dataLabels[index]) / 3,
                    color: "#A7A8AA",
                    font: {
                        family: 'Italian Plate No2 Expanded',
                        size: 14,
                        weight: '500'
                    },
                    callback: function (value, i, array) {
                        const rounded = roundToNearest(value, 100);
                        const formattedValue = rounded < 1000000 ? rounded / 1000 + 'K' : rounded / 1000000 + 'M';
                        if (value === 0 || value === findMaxValue(dataLabels[index])) {
                            return '$' + formattedValue;
                        }
                        return '';
                    },
                },
                grid: {
                    display: true,
                    color: '#F2F4F6',

                },

            },
            x: {
                ticks: {
                  
                    color: "#A7A8AA",
                    suggestedMin: 0,
                    
                    maxTicksLimit: 1.1,
                    font: {
                        family: 'Italian Plate No2 Expanded',
                        size: 14,
                        color: "#A7A8AA",
                        weight: '500'
                    },
                },
                border: {
                    dash: [10, 10],
                },
                grid: {
                    display: true,
                    color: '#F2F4F6',
                },
            }
        }
    }))


    return (
        <div className='main'>
            <Header />
            <div className="content">
                <div className="container">
                    <div className="analytics">
                        <div className="analytics__title">
                            <NavLink to="/analytics" activeClassName="active">
                                Overview
                            </NavLink>
                            <NavLink to="/analytics/cohorts" activeClassName="active">
                                Cohorts
                            </NavLink>
                            <NavLink to="/analytics/products" activeClassName="active">
                                Products
                            </NavLink>
                            <NavLink to="/analytics/platforms" activeClassName="active">
                                Platforms
                            </NavLink>
                        </div>
                        <div className="analytics-top overview-top">
                            <span>Choose month:</span>
                            <div className="analytics-top-date-wrap">
                                <div className="analytics-top-date" onClick={dateShow}>
                                    <div className="analytics-top-date-icon">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7979 4.93255H3.19792C2.60881 4.93255 2.13125 5.41012 2.13125 5.99922V12.9326C2.13125 13.5217 2.60881 13.9992 3.19792 13.9992H12.7979C13.387 13.9992 13.8646 13.5217 13.8646 12.9326V5.99922C13.8646 5.41012 13.387 4.93255 12.7979 4.93255ZM3.19792 1.19922C1.72516 1.19922 0.53125 2.39313 0.53125 3.86589V12.9326C0.53125 14.4053 1.72516 15.5992 3.19792 15.5992H12.7979C14.2707 15.5992 15.4646 14.4053 15.4646 12.9326V3.86589C15.4646 2.39313 14.2707 1.19922 12.7979 1.19922H3.19792Z" fill="black" />
                                            <rect x="3.19824" y="0.402344" width="1.6" height="2.13333" rx="0.8" fill="black" />
                                            <rect x="11.1982" y="0.402344" width="1.6" height="2.13333" rx="0.8" fill="black" />
                                        </svg>
                                    </div>
                                    <div className="analytics-top-date-text">
                                        {startDateFormattedDate}
    
                                    </div>
                                    <div className="analytics-top-date-arrow">
                                        <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M11 1L6 6L1 1" stroke="#A7A8AA" stroke-width="1.9" stroke-linecap="round" />
                                        </svg>
                                    </div>
                                </div>
                                {isBlockVisible && (
                                    <div className="analytics-top-date-block" ref={dataPickerLogoutRef} >
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            // selectsRange
                                            // startDate={startDate}
                                            // endDate={endDate}
                                            maxDate={new Date("2023-10-1")}
                                            showMonthYearPicker
                                            inline
                                        />
                                        <div className="analytics-top-date__btn" onClick={dateHide}>
                                            Select
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className="overview-top-select">
                                <SelectCustomChart />
                            </div>
                        </div>
                        <div className="overview-block">
                            <div className="overview-block-item" >
                                <div className="overview-block-top">
                                    <span>
                                        <p>Gross volume</p>
                                        <i>+31.98%</i>
                                    </span>
                                    <strong>
                                        <i>$75,629</i>
                                        <p>$57,785 previous period</p>
                                    </strong>
                                </div>
                                <div className="overview-block-item-chart">
                                    <Line width="100%" data={data[0]} options={options[0]} />
                                </div>

                            </div>
                            <div className="overview-block-item" >
                                <div className="overview-block-top">
                                    <span>
                                        <p>Gross volume</p>
                                        <i>+31.98%</i>
                                    </span>
                                    <strong>
                                        <i>$75,629</i>
                                        <p>$57,785 previous period</p>
                                    </strong>
                                </div>
                                <div className="overview-block-item-chart">
                                    <Line width="100%" data={data[1]} options={options[1]} />
                                </div>

                            </div>
                            <div className="overview-block-item" >
                                <div className="overview-block-top">
                                    <span>
                                        <p>Gross volume</p>
                                        <i>+31.98%</i>
                                    </span>
                                    <strong>
                                        <i>$75,629</i>
                                        <p>$57,785 previous period</p>
                                    </strong>
                                </div>
                                <div className="overview-block-item-chart">
                                    <Line width="100%" data={data[2]} options={options[2]} />
                                </div>
                            </div>
                            <div className="overview-block-item" >
                                <div className="overview-block-top">
                                    <span>
                                        <p>Gross volume</p>
                                        <i>+31.98%</i>
                                    </span>
                                    <strong>
                                        <i>$75,629</i>
                                        <p>$57,785 previous period</p>
                                    </strong>
                                </div>
                                <div className="overview-block-item-chart">
                                    <Line width="100%" data={data[3]} options={options[3]} />
                                </div>
                            </div>
                            <div className="overview-block-item" >
                                <div className="overview-block-top">
                                    <span>
                                        <p>Gross volume</p>
                                        <i>+31.98%</i>
                                    </span>
                                    <strong>
                                        <i>$75,629</i>
                                        <p>$57,785 previous period</p>
                                    </strong>
                                </div>
                                <div className="overview-block-item-chart">
                                    <Line width="100%" data={data[4]} options={options[4]} />
                                </div>
                            </div>
                            <div className="overview-block-item">
                                <h5>Net volume from sales</h5>
                                <div class="analytics-month-line">
                                    <div className="analytics-month-line-item" style={lineStyle(greenColor, 70)}>

                                    </div>
                                    <div className="analytics-month-line-item" style={lineStyle(orangeColor, 5)}>

                                    </div>
                                    <div className="analytics-month-line-item" style={lineStyle(lightBlueColor, 10)}>

                                    </div>
                                    <div className="analytics-month-line-item" style={lineStyle(redColor, 7)}>

                                    </div>
                                    <div className="analytics-month-line-item" style={lineStyle(yellowColor, 8)}>

                                    </div>
                                </div>
                                <ul>
                                    <li>
                                        <span>
                                            <i style={bgStyle(greenColor)}></i>
                                            Succeeded
                                        </span>
                                        <strong>$61,284</strong>
                                    </li>
                                    <li>
                                        <span>
                                            <i style={bgStyle(orangeColor)}></i>
                                            Refunded
                                        </span>
                                        <strong>$10,697</strong>
                                    </li>
                                    <li>
                                        <span>
                                            <i style={bgStyle(lightBlueColor)}></i>
                                            Uncaptured
                                        </span>
                                        <strong>$0</strong>
                                    </li>
                                    <li>
                                        <span>
                                            <i style={bgStyle(redColor)}></i>
                                            Failed
                                        </span>
                                        <strong>$34,891</strong>
                                    </li>
                                    <li>
                                        <span>
                                            <i style={bgStyle(yellowColor)}></i>
                                            Disput
                                        </span>
                                        <strong>$1,224</strong>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Analytics;
