import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import './profile.scss';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
    const navigate = useNavigate();

    const [isLogoutVisible, setIsLogoutVisible] = useState(false);
    const profileLogoutRef = useRef(null);

    const handleProfileWrapClick = () => {
        setIsLogoutVisible(!isLogoutVisible);
    };

    const handleClickOutside = (event) => {
        if (profileLogoutRef.current && !profileLogoutRef.current.contains(event.target)) {
            setIsLogoutVisible(false);
        }
    };
    function handleLogout() {
        Cookies.remove('authToken');
        Cookies.remove('email');
        Cookies.remove('refreshToken');
        Cookies.remove('role');
        navigate("/");
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const email = Cookies.get('email');
    function getFirstLetterUpperCase(str) {
        if (!str) return '';
        return str.charAt(0).toUpperCase();
    }

    return (
        <div className="profile">
            <div className="profile-wrap" onClick={handleProfileWrapClick}>
                <div className="profile-name">
                    Hi, {email}
                </div>
                <div className="profile-ava">
                    {getFirstLetterUpperCase(email)}
                    {/* <img src={process.env.PUBLIC_URL + '/img/avatar.png'} alt="" /> */}
                </div>
            </div>
            {isLogoutVisible && (
                <div className="profile-logout" onClick={handleLogout} ref={profileLogoutRef}>
                    Log out
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.65931 1.92773L8.35826 1.92773C8.7133 1.92773 9.00112 2.21555 9.00112 2.57059C9.00112 2.92563 8.7133 3.21345 8.35826 3.21345H6.68683C5.95616 3.21345 5.44681 3.21395 5.05027 3.24635C4.66123 3.27813 4.43772 3.33739 4.26842 3.42365C3.90554 3.60855 3.6105 3.90358 3.4256 4.26647C3.33934 4.43577 3.28009 4.65928 3.2483 5.04832C3.2159 5.44486 3.2154 5.9542 3.2154 6.68488V11.3134C3.2154 12.0441 3.2159 12.5535 3.2483 12.95C3.28009 13.339 3.33934 13.5626 3.4256 13.7319C3.6105 14.0947 3.90554 14.3898 4.26842 14.5747C4.43772 14.6609 4.66123 14.7202 5.05027 14.752C5.44681 14.7844 5.95616 14.7849 6.68683 14.7849H8.35826C8.7133 14.7849 9.00112 15.0727 9.00112 15.4277C9.00112 15.7828 8.7133 16.0706 8.35826 16.0706H6.6593C5.96258 16.0706 5.40063 16.0706 4.94557 16.0334C4.47703 15.9951 4.06548 15.9143 3.68472 15.7203C3.07991 15.4121 2.58819 14.9204 2.28002 14.3156C2.08602 13.9348 2.00514 13.5233 1.96686 13.0547C1.92968 12.5996 1.92968 12.0377 1.92969 11.341V6.65736C1.92968 5.96064 1.92968 5.39868 1.96686 4.94362C2.00514 4.47507 2.08602 4.06352 2.28002 3.68277C2.58819 3.07796 3.07991 2.58624 3.68472 2.27807C4.06548 2.08406 4.47703 2.00319 4.94557 1.9649C5.40064 1.92772 5.96259 1.92773 6.65931 1.92773ZM12.4037 5.97317C12.6547 5.72212 13.0618 5.72212 13.3128 5.97317L15.8843 8.54459C16.0048 8.66515 16.0725 8.82867 16.0725 8.99916C16.0725 9.16966 16.0048 9.33317 15.8843 9.45373L13.3128 12.0252C13.0618 12.2762 12.6547 12.2762 12.4037 12.0252C12.1526 11.7741 12.1526 11.3671 12.4037 11.116L13.8777 9.64202H7.7154C7.36036 9.64202 7.07255 9.3542 7.07255 8.99916C7.07255 8.64412 7.36036 8.35631 7.7154 8.35631H13.8777L12.4037 6.8823C12.1526 6.63125 12.1526 6.22422 12.4037 5.97317Z" fill="#F43535" />
                    </svg>
                </div>
            )}
        </div>
    );
}

export default Profile;
