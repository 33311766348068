import React from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import './style.scss'
import useFormattedDate from '../../../../hooks/useFormattedDate';

function ProductAds({data}) {

    const loading = false

    return (
        <div className="products-ads">

            <div className="table" style={{ marginBottom: '40rem' }}>

                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>General</TableCell>
                                <TableCell></TableCell>
                                <TableCell>Start date & end date</TableCell>

                                <TableCell>Total spent</TableCell>
                                <TableCell>Budget </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data?.facebookAds?.map((row, index) => (
                                <TableRow
                                    className='table-row'
                                // onClick={() => handleOpenBlock(index)}
                                >
                                    <TableCell>
                                        {loading ? (
                                            <Skeleton variant="rounded" width={"64rem"} height={"64rem"} style={{ marginBottom: '0' }} />
                                        ) : (
                                            <div className="promotionsNew-AllPromotions-img" >
                                                {/* <img src={row.promationImg} alt="" />
                                                <img src={process.env.PUBLIC_URL + `/img/pimg.png`} alt="" />
                                                <span>{row.promationCreatives}</span> */}
                                            </div>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <div className="promotionsNew-AllPromotions-info">
                                            <p>{row.headline}</p>
                                            <div className="promotionsNew-AllPromotions-info-bottom">
                                                <div className={`promotionsNew-AllPromotions-status ${row.status === 'ACTIVE' ? 'promotionsNew-AllPromotions-status--Active' : ''} ${row.status === 'PENDING_REVIEW' ? 'promotionsNew-AllPromotions-status--Active' : ''}  ${row.status === 'REJECTED' ? 'promotionsNew-AllPromotions-status--Rejected ' : ''}  ${row.status === 'PAUSED' ? 'promotionsNew-AllPromotions-status--Paused ' : ''}`}>
                                                    <span></span>
                                                    {row.status}
                                                </div>
                                                <div className="promotionsNew-AllPromotions-website">
                                                    {row.websiteUrl}
                                                </div>
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell >
                                        <div className="promotionsNew-AllPromotions-date" dangerouslySetInnerHTML={{ __html: `from ${useFormattedDate(row.createdAt, true)} <br> to ${useFormattedDate(row.endDate, true)}` }}>
                                        </div>
                                    </TableCell >
                                    <TableCell>
                                        {/* <span >{row.promationSpent}</span> */}
                                    </TableCell>
                                    <TableCell>
                                        <span>${row.budget?.toFixed(2)}</span>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>
        </div>
    )
}

export default ProductAds