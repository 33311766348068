import React, { useState } from 'react';
import "./login.scss"
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import config from '../../config';

const Login = () => {

    const navigate = useNavigate();
    const [isActive, setIsActive] = useState(false);

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });

    const [errors, setErrors] = useState({
        email: '',
        password: '',
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const axiosConfig = {
        method: 'post',
        url: `${config.apiBaseUrl}admins/auth/login`,
        headers: {
            'Content-Type': 'application/json',
        },
        data: formData,
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        const { email, password } = formData;
        const newErrors = {};

        // Simple validation for email and password
        if (!email || !email.includes('@')) {
            newErrors.email = 'Please enter a valid email address.';
        }

        if (!password || password.length < 6) {
            newErrors.password = 'Password must be at least 6 characters long.';
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            setIsActive(true)
            axios(axiosConfig)
                .then((response) => {
                    const token = response.data.accessToken
                    const refreshToken = response.data.refreshToken
                    Cookies.set('authToken', token, { expires: 1/48});
                    Cookies.set('refreshToken', refreshToken, { expires: 1  });
                    const role = response.data.role
                    Cookies.set('role', role, { expires: 10});
                    Cookies.set('email', email, { expires: 10});

                    if(role === 'ai_prompt_engineer') {
                        
                        navigate('/ai/ai-prompts');  
                    } else {
                        navigate('/users');  
                    }
                })
                .catch((error) => {
                    setIsActive(false)
                });

        }
    };



    return (
        <div className="login" style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/login-bg.webp)` }}>
            <div className="login-container">
                <div className="login-logo">
                    <svg width="243" height="69" viewBox="0 0 243 69" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1273_47896)">
                            <path d="M140.953 34.6498C140.877 28.9576 138.977 24.2521 135.179 20.5332C131.381 16.8902 126.595 15.0687 120.746 15.0687C114.896 15.0687 110.035 16.8902 106.236 20.5332C102.362 24.2521 100.463 28.8818 100.463 34.574C100.463 40.2662 102.362 44.8958 106.236 48.5388C110.035 52.1818 114.896 54.0033 120.746 54.0033C125.38 54.0033 129.558 52.7131 133.204 50.0567C136.85 47.4004 139.205 44.0609 140.269 39.9626H130.317C128.874 43.3779 125.152 45.9584 120.822 45.9584C115.656 45.9584 111.554 42.543 110.338 37.6098H140.649C140.877 36.7749 140.953 35.7883 140.953 34.6498ZM120.822 23.1137C125.608 23.1137 129.786 25.9218 131.305 30.0961H110.718C112.314 25.8459 116.188 23.1137 120.822 23.1137Z" fill="white" />
                            <path d="M184.001 34.6498C183.925 28.9576 182.026 24.2521 178.227 20.5332C174.429 16.8902 169.643 15.0687 163.794 15.0687C157.945 15.0687 153.083 16.8902 149.284 20.5332C145.41 24.2521 143.511 28.8818 143.511 34.574C143.511 40.2662 145.41 44.8958 149.284 48.5388C153.083 52.1818 157.945 54.0033 163.794 54.0033C168.428 54.0033 172.606 52.7131 176.252 50.0567C179.899 47.4004 182.254 44.0609 183.317 39.9626H173.366C171.922 43.3779 168.2 45.9584 163.87 45.9584C158.704 45.9584 154.602 42.543 153.387 37.6098H183.697C183.925 36.7749 184.001 35.7883 184.001 34.6498ZM163.87 23.1137C168.656 23.1137 172.834 25.9218 174.353 30.0961H153.766C155.362 25.8459 159.236 23.1137 163.87 23.1137Z" fill="white" />
                            <path d="M188.61 53.3203H198.486V0.648438H188.61V53.3203Z" fill="white" />
                            <path d="M222.343 39.4313L211.859 15.8276H201.3L217.709 49.2978L208.061 68.3477H218.392L242.929 15.8276H232.598L222.343 39.4313Z" fill="white" />
                            <path d="M88.7059 15.8242H99.993L74.1279 53.318H62.8408L88.7059 15.8242Z" fill="white" />
                            <path d="M63.8584 15.8242H91.5621V24.2787H63.8584V15.8242Z" fill="white" />
                            <path d="M71.499 44.8633H98.1372V53.3178H71.499V44.8633Z" fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M48.8002 40.2678C48.6505 40.8919 48.4771 41.5068 48.281 42.1115H1.22895C1.03285 41.5068 0.85946 40.8919 0.709786 40.2678H48.8002ZM40.3548 53.6558H9.15518C8.44423 53.0776 7.76607 52.4606 7.12392 51.8082H42.386C41.7439 52.4606 41.0657 53.0776 40.3548 53.6558ZM2.76946 45.8035C3.18198 46.6021 3.63682 47.3753 4.13101 48.1201H45.3789C45.8731 47.3753 46.328 46.6021 46.7405 45.8035H2.76946ZM24.755 59.1915C21.4308 59.1915 18.2599 58.5362 15.3645 57.3478H34.1454C31.2501 58.5362 28.0792 59.1915 24.755 59.1915ZM0.117504 36.5833C0.0661218 35.971 0.0370977 35.3524 0.03125 34.7282H49.4787C49.4729 35.3524 49.4438 35.971 49.3925 36.5833H0.117504ZM0.269726 31.0401C0.379569 30.2555 0.526311 29.4827 0.708144 28.7235H48.8018C48.9836 29.4827 49.1304 30.2555 49.2402 31.0401H0.269726ZM1.90883 25.0315C2.17087 24.4007 2.45834 23.7832 2.76994 23.18H46.74C47.0516 23.7832 47.3391 24.4007 47.6011 25.0315H1.90883ZM5.11349 19.488C5.60521 18.8467 6.12769 18.2301 6.67874 17.6404H42.8312C43.3823 18.2301 43.9048 18.8467 44.3965 19.488H5.11349ZM24.755 9.79297C29.8355 9.79297 34.5581 11.3237 38.4858 13.9487H11.0242C14.9519 11.3237 19.6745 9.79297 24.755 9.79297Z" fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.2408 19.4924C22.2736 18.1453 23.442 16.9075 24.7258 15.7994H8.57181C7.28806 16.9075 6.11963 18.1453 5.08693 19.4924H21.2408ZM19.9539 21.339H3.80008C3.0624 22.5076 2.42008 23.7422 1.88403 25.032H18.0378C18.5738 23.7422 19.2162 22.5076 19.9539 21.339ZM0.245065 31.0328C0.445579 29.6053 0.768237 28.217 1.20214 26.8786H17.3558C16.9219 28.2169 16.5992 29.6053 16.3987 31.0328H0.245065ZM0.0560269 32.8794C0.0214584 33.4139 0.00390625 33.953 0.00390625 34.4962C0.00390625 35.1954 0.0329924 35.8878 0.0900221 36.5724H16.2435C16.1865 35.888 16.1575 35.1957 16.1575 34.4966C16.1575 33.9533 16.175 33.414 16.2096 32.8794H0.0560269ZM1.20159 42.112C0.814648 40.9182 0.516217 39.6847 0.31403 38.419H16.4675C16.6697 39.6847 16.9681 40.9182 17.355 42.112H1.20159ZM1.88332 43.9586C2.4903 45.4194 3.23358 46.8094 4.09731 48.1128H20.2506C19.3869 46.8094 18.6436 45.4194 18.0367 43.9586H1.88332ZM9.12468 53.6563C7.77312 52.5565 6.54014 51.3168 5.44784 49.9594H21.6011C22.6933 51.3168 23.9262 52.5565 25.2777 53.6563H9.12468ZM11.7177 55.5029C15.497 57.8439 19.9549 59.1954 24.7287 59.1954C27.5568 59.1954 30.274 58.7211 32.8049 57.8478C31.0638 57.2468 29.4108 56.4571 27.8705 55.5029H11.7177ZM32.8061 11.145C30.7906 11.8405 28.8931 12.7891 27.1519 13.9528H10.9977C14.9255 11.3277 19.6482 9.79688 24.7287 9.79688C27.5573 9.79688 30.2749 10.2714 32.8061 11.145Z" fill="white" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1273_47896">
                                <rect width="242.916" height="68.32" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>

                </div>
                <form className="login-form" onSubmit={handleSubmit}>
                    <div className="login-form__title">Sign in to your account</div>
                    <label>
                        <p>Email</p>
                        <input
                            type="email"
                            name="email"
                            placeholder="Enter email"
                            value={formData.email}
                            onChange={handleChange}
                        />
                        {errors.email && <span className="error-message">{errors.email}</span>}
                    </label>
                    <label>
                        <p>Password</p>
                        <input
                            type="password"
                            name="password"
                            placeholder="Enter password"
                            value={formData.password}
                            onChange={handleChange}
                        />
                        {errors.password && <span className="error-message">{errors.password}</span>}
                    </label>
                    <button className={`login-form__btn ${isActive ? 'active' : ''}`} type="submit">
                        <span>Sign in</span>
                        <div class="spin-loader ios-loader">
                            <div class="bar bar1"></div>
                            <div class="bar bar2"></div>
                            <div class="bar bar3"></div>
                            <div class="bar bar4"></div>
                            <div class="bar bar5"></div>
                            <div class="bar bar6"></div>
                            <div class="bar bar7"></div>
                            <div class="bar bar8"></div>
                        </div>

                    </button>
                </form>

            </div>
        </div>
    );
}

export default Login;
