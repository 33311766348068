import React, { useState } from "react";
import Header from "../../components/header/Header";
// import AiNavbar from "../../components/AiGenerationNavbar/AiNavbar";
import axios from 'axios';
import Cookies from "js-cookie";
import AiNavbar from "../../components/ai-navbar/AiNavbar";
import config from "../../config";

export const AiAdsGeneration = () => {
    const [userId, setUserId] = useState('8e9dbc87-8f7c-4703-b212-b051e2f20078');
    const [part, setPart] = useState('');
    const [generationResult, setResult] = useState('');

    const handleUserIdChange = (e) => {
        const newValue = e.target.value;
        setUserId(newValue);
    };
    const handlePartChange = (e) => {
        const newValue = e.target.value;
        setPart(newValue);
    };

    const generateAd = async () => {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('authToken')}`,
            }

            const response = await axios.post(`${config.apiBaseUrl}admins/ai/ads`, {
                userId,
                part,
            }, { headers });

            const { data } = response.data;

            setResult(data);
        } catch (e) {
            console.log(e);
        }
    }

    return (
        <div>
            <Header />
            {/* <AiNavbar /> */}
            <div className="content">
                <div className="container">

                    <AiNavbar />
                    <div className="aiPrompts">
                        <h3>AI Ads Generation</h3>

                        <div className="input" style={{ marginBottom: '10rem' }}>
                            <span>User id</span>
                            <input
                                type="text"
                                value={userId}
                                width="320px"
                                onChange={handleUserIdChange}
                                placeholder='User id' />
                        </div>
                        <div className="input" style={{ marginBottom: '10rem' }}>
                            <span>Part</span>
                            <select name="Part" onChange={handlePartChange} >

                                <option value='ad_primary_text'>Primary text</option>
                                <option value='ad_headline'>Headline</option>
                                <option value='ad_call_to_action'>Call to action</option>
                            </select>
                        </div>

                        <div className="input" style={{ marginBottom: '32rem' }}>
                            <span>Result</span>
                            <textarea placeholder="Result" name="aiResult" rows="5" cols="40" value={generationResult}>
                                {generationResult}
                            </textarea>
                        </div>


                        <button className={'btn'} onClick={generateAd}>Generate</button>
                    </div>

                </div>
            </div>

        </div>
    );
}
