import React from 'react';

const ArrowNext = () => {
    return (
        <svg style={{width: '16rem', height: '16rem'}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path d="M6 13L11 8L6 3" stroke="#A7A8AA" stroke-width="1.9" stroke-linecap="round" />
        </svg>
    );
}
export default ArrowNext;
