import React, { useState, useEffect } from 'react';
import './activity.scss';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useQuery } from 'react-query'
import config from '../../config';

const fetchUserData = async (activeUserId) => {
    if (!activeUserId) {
        return null;
    }
    const url = `${config.apiBaseUrl}admins/users/${activeUserId}/achievements`;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('authToken')}`,
    };
    const response = await axios.get(url, { headers });
    return response.data;
};

const Activity = ({ activeUserId }) => {
    const { isLoading: isLoading, error: error, data: activityData } = useQuery(['activity', activeUserId], () => fetchUserData(activeUserId));
    const [data, setData] = useState([]);
    const [percentage, setPercentage] = useState('');

    useEffect(() => {
        if (!isLoading && !error && activityData && activityData.data.items) {
            setData(activityData.data.items)
            const achievedItems = activityData.data.items.filter(item => item.isAchieved).length;
            const totalItems = activityData.data.total;
            const percentageAchieved = (achievedItems / totalItems) * 100;
            setPercentage(percentageAchieved)
        }

    }, [isLoading, error, activityData]);
    return (
        <div className="activity">
            <div className="activity__title">User activity</div>
            <div className="about-block">
                <div className="about-block-top">
                    <div className="about-block__title">Setup Guide</div>
                    <div className="about-block__complete">{Math.round(percentage)}% completed</div>
                    <div className="about-block__progress">
                        <span style={{ width: `${percentage}%` }}></span>
                    </div>
                </div>
                <div className="activityNew-block-wrapper">

                    {data.map(item => (
                        <div className="activityNew-block-item">
                            {item.isAchieved ? (
                                <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g clip-path="url(#clip0_3184_40427)">
                                        <circle cx="11" cy="11" r="11" fill="black" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M6.5 9.5L5 11L9.5 15.5L17 8L15.5 6.5L9.5 12.5L6.5 9.5Z" fill="white" />
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_3184_40427">
                                            <rect width="22" height="22" fill="white" />
                                        </clipPath>
                                    </defs>
                                </svg>
                            ) : (
                                <div className="activityNew-block-item__circle">

                                </div>
                            )}
                            <div className="activityNew-block-item-info">
                                <span>{item.title}</span>
                                <p>{item.description}</p>
                            </div>

                        </div>
                    ))}

                </div>
            </div>

            {/* <div className="activity-block">
                <div className="activity-block-item">
                    <div className="activity-block-top">
                        <div className="activity-block-progress">

                            <div className="activity-block-progress-main">
                                <Stack spacing={2} direction="row">
                                    <CircularProgress
                                        variant="determinate"
                                        value={40}
                                        size={120}
                                        thickness={4}
                                        style={{ color: '#17CC37' }}
                                    />
                                </Stack>
                            </div>
                            <div className="activity-block-progress-bg">
                                <Stack spacing={2} direction="row">
                                    <CircularProgress
                                        variant="determinate"
                                        value={100}
                                        size={120}
                                        thickness={4}
                                        style={{ color: '#F2F4F6' }}
                                    />
                                </Stack>
                            </div>
                            <div className="activity-block-progress__count">8/20</div>
                        </div>
                        <div className="activity-block-top-info">
                            <div className="activity-block-top__title">Packaging</div>
                            <p>The number of points in which was </p>
                        </div>
                    </div>
                    <ul>
                        <li className='active'>
                            <div className="activity-block__tick activity-block__tick--green">
                                <ActiveTick />
                            </div>
                            <p>Second ads launched</p>
                        </li>
                        <li className='active'>
                            <div className="activity-block__tick activity-block__tick--green">
                                <ActiveTick />
                            </div>
                            <p>Creative created with AI</p>
                        </li>

                        <li className='active'>
                            <div className="activity-block__tick activity-block__tick--green">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li className='active'>
                            <div className="activity-block__tick activity-block__tick--green">
                                <ActiveTick />
                            </div>
                            <p>Second ads launched</p>
                        </li>
                        <li className='active'>
                            <div className="activity-block__tick activity-block__tick--green">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li >
                            <div className="activity-block__tick activity-block__tick--green">
                                <ActiveTick />
                            </div>
                            <p>Creative created with AI</p>
                        </li>
                        <li className='active'>
                            <div className="activity-block__tick activity-block__tick--green">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li className='active'>
                            <div className="activity-block__tick activity-block__tick--green">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li className='active'>
                            <div className="activity-block__tick activity-block__tick--green">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li >
                            <div className="activity-block__tick activity-block__tick--green">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li >
                            <div className="activity-block__tick activity-block__tick--green">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li >
                            <div className="activity-block__tick activity-block__tick--green">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li >
                            <div className="activity-block__tick activity-block__tick--green">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li >
                            <div className="activity-block__tick activity-block__tick--green">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                    </ul>
                </div>
                <div className="activity-block-item">
                    <div className="activity-block-top">
                        <div className="activity-block-progress">

                            <div className="activity-block-progress-main">
                                <Stack spacing={2} direction="row">
                                    <CircularProgress
                                        variant="determinate"
                                        value={66}
                                        size={120}
                                        thickness={4}
                                        style={{ color: '#1A9FFF' }}
                                    />
                                </Stack>
                            </div>
                            <div className="activity-block-progress-bg">
                                <Stack spacing={2} direction="row">
                                    <CircularProgress
                                        variant="determinate"
                                        value={100}
                                        size={120}
                                        thickness={4}
                                        style={{ color: '#F2F4F6' }}
                                    />
                                </Stack>
                            </div>
                            <div className="activity-block-progress__count">8/12</div>
                        </div>
                        <div className="activity-block-top-info">
                            <div className="activity-block-top__title">Product</div>
                            <p>The number of points in which was</p>
                        </div>
                    </div>
                    <ul>
                        <li className='active'>
                            <div className="activity-block__tick activity-block__tick--blue">
                                <ActiveTick />
                            </div>
                            <p>Second ads launched</p>
                        </li>
                        <li className='active'>
                            <div className="activity-block__tick activity-block__tick--blue">
                                <ActiveTick />
                            </div>
                            <p>Creative created with AI</p>
                        </li>
                        <li >
                            <div className="activity-block__tick activity-block__tick--blue">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li className='active'>
                            <div className="activity-block__tick activity-block__tick--blue">
                                <ActiveTick />
                            </div>
                            <p>Second ads launched</p>
                        </li>
                        <li className='active'>
                            <div className="activity-block__tick activity-block__tick--blue">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li className='active'>
                            <div className="activity-block__tick activity-block__tick--blue">
                                <ActiveTick />
                            </div>
                            <p>Creative created with AI</p>
                        </li>
                        <li >
                            <div className="activity-block__tick activity-block__tick--blue">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li className='active'>
                            <div className="activity-block__tick activity-block__tick--blue">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li className='active'>
                            <div className="activity-block__tick activity-block__tick--blue">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li className='active'>
                            <div className="activity-block__tick activity-block__tick--blue">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li >
                            <div className="activity-block__tick activity-block__tick--blue">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li >
                            <div className="activity-block__tick activity-block__tick--blue">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>

                    </ul>
                </div>
                <div className="activity-block-item">
                    <div className="activity-block-top">
                        <div className="activity-block-progress">

                        <div className="activity-block-progress-main">
                                <Stack spacing={2} direction="row">
                                    <CircularProgress
                                        variant="determinate"
                                        value={30}
                                        size={120}
                                        thickness={4}
                                        style={{ color: '#FFB628' }}
                                    />
                                </Stack>
                            </div>
                            <div className="activity-block-progress-bg">
                                <Stack spacing={2} direction="row">
                                    <CircularProgress
                                        variant="determinate"
                                        value={100}
                                        size={120}
                                        thickness={4}
                                        style={{ color: '#F2F4F6' }}
                                    />
                                </Stack>
                            </div>
                            <div className="activity-block-progress__count">3/10</div>
                        </div>
                        <div className="activity-block-top-info">
                            <div className="activity-block-top__title">Promotion</div>
                            <p>The number of points in which was </p>
                        </div>
                    </div>
                    <ul>
                        <li className='active'>
                            <div className="activity-block__tick activity-block__tick--orange">
                                <ActiveTick />
                            </div>
                            <p>Second ads launched</p>
                        </li>
                        <li className='active'>
                            <div className="activity-block__tick activity-block__tick--orange">
                                <ActiveTick />
                            </div>
                            <p>Creative created with AI</p>
                        </li>
                        <li className='active'>
                            <div className="activity-block__tick activity-block__tick--orange">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li >
                            <div className="activity-block__tick activity-block__tick--orange">
                                <ActiveTick />
                            </div>
                            <p>Second ads launched</p>
                        </li>
                        <li>
                            <div className="activity-block__tick activity-block__tick--orange">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li>
                            <div className="activity-block__tick activity-block__tick--orange">
                                <ActiveTick />
                            </div>
                            <p>Creative created with AI</p>
                        </li>
                        <li>
                            <div className="activity-block__tick activity-block__tick--orange">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li>
                            <div className="activity-block__tick activity-block__tick--orange">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li>
                            <div className="activity-block__tick activity-block__tick--orange">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                        <li>
                            <div className="activity-block__tick activity-block__tick--orange">
                                <ActiveTick />
                            </div>
                            <p>Uploaded photo of products</p>
                        </li>
                    </ul>
                </div>
            </div> */}
        </div>
    );
}

export default Activity;
