import React, { useState, useEffect } from 'react';
import './promotions.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import SelectCustom from '../../components/selectCustom/SelectCustom';
import Cookies from 'js-cookie';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from 'react-query'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import PromotionsPageBlock from '../promotionsPage/PromotionsPageBlock';
import OnModeration from './components/on-moderation/OnModeration';
import AllPromotions from './components/all-promotions/AllPromotions';
import OnHistory from './components/on-moderation/OnHistory';
import PromotionStat from './components/promotions-stat/PromotionStat';
import config from '../../config';



const fetchStatData = async (activeUserId) => {
    if (!activeUserId) {
        return null;
    }
    const url = `${config.apiBaseUrl}admins/users/${activeUserId}/facebook-ads-statistic`;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('authToken')}`,
    };
    const response = await axios.get(url, { headers });
    return response.data;
};


const PromotionsNew = ({ activeUserId }) => {

    const [total, setTotal] = useState('0');


    const [loading, setLoading] = useState(true);
    const [open, setOpen] = React.useState(false);
    const [imgModal, setImgModal] = React.useState('');
    const handleOpen = (img) => {
        setImgModal(img);
        setOpen(true);
    };
    const handleClose = () => setOpen(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "475rem",
    };

    const [rows, setRows] = useState([]);

    function createData(on, img, name, color, status, reach, impressions, orders) {
        return { on, img, name, color, status, reach, impressions, orders };
    }

    const sorts = [
        { value: 'createdAt', label: 'Created Date' },
    ];

    const defaultValue = { value: 'createdAt', label: 'Created Date' };


    const handleSelectChange = (selectedOption) => {
        if (selectedOption.value == 'price' || selectedOption.value == 'oldPrice' || selectedOption.value == 'title') {
            setSelectedSort({ [selectedOption.value]: "asc" })
        } else {
            setSelectedSort({ [selectedOption.value]: "desc" })
        }
    };


    const [avgCpo, setAvgCpo] = useState('');
    const [impressions, setImpressions] = useState('');
    const [reach, setReach] = useState('');
    const [orders, setOrders] = useState('');



    const { isLoading: statLoading, error: statError, data: statData } = useQuery(['promotionStat', activeUserId], () => fetchStatData(activeUserId));



    useEffect(() => {
        if (!statLoading && !statError && statData) {
            if (statData.avgCpo) {
                setAvgCpo(statData.avgCpo)
            } else {
                setAvgCpo(0)
            }
            setImpressions(statData.impressions)
            setReach(statData.reach)
            setOrders(statData.leads)
            setLoading(false);
        }
    }, [statLoading, statError, statData]);


    function createDataModeration(email, promationImg, promationPrimaryText, promationHeadlineText, promationSite, promationBudget, promationDuration, promationLocation, promationDate, promationStatus, promationId, promationAction, index, promationRejectTitle, promationRejectDescription, promationRejectedAt, adminEmail, userId) {
        return { email, promationImg, promationPrimaryText, promationHeadlineText, promationSite, promationBudget, promationDuration, promationLocation, promationDate, promationStatus, promationId, promationAction, index, promationRejectTitle, promationRejectDescription, promationRejectedAt, adminEmail, userId };
    }


    const defaultValueModeration = { value: 'createdAt', label: 'Created Date' };

 


    useEffect(() => {
        // setRowsModeration(rowsLoadingModeration)
    }, [])



    const [openBlock, setOpenBlock] = useState(false);

    const handleCloseBlock = () => {
        setOpenBlock(false);
    };

    const [blockIndex, setblockIndex] = useState(0);



    return (
        <div className="promotions promotionsNew">
            <PromotionStat activeUserId={activeUserId} />
            {/* <div className="promotions-block">
                <div className="promotions-block-item">
                    <span>Total reach</span>
                    {statLoading ? (
                        <Skeleton variant="rounded" width={"50rem"} height={"26rem"} />
                    ) : (
                        <strong>{reach}</strong>
                    )}

                </div>
                <div className="promotions-block-item">
                    <span>Total impressions</span>
                    {statLoading ? (
                        <Skeleton variant="rounded" width={"50rem"} height={"26rem"} />
                    ) : (
                        <strong>{impressions}</strong>
                    )}

                </div>
                <div className="promotions-block-item">
                    <span>Total orders</span>
                    {statLoading ? (
                        <Skeleton variant="rounded" width={"50rem"} height={"26rem"} />
                    ) : (
                        <strong>{orders}</strong>
                    )}

                </div>
                <div className="promotions-block-item">
                    <span>Avg. CPO</span>
                    {statLoading ? (
                        <Skeleton variant="rounded" width={"50rem"} height={"26rem"} />
                    ) : (
                        <strong>
                            {avgCpo && `$${avgCpo}`}
                        </strong>
                    )}

                </div>
            </div> */}

            <OnModeration activeUserId={activeUserId} invalidStatuses = {['PENDING', 'SKIPED', 'ERROR', 'PROCESSING']}/>
            <AllPromotions activeUserId={activeUserId}/>

            <OnHistory activeUserId={activeUserId} invalidStatuses = {['REJECTED', 'APPROVED']}/>



            {/* <Modal
                keepMounted
                open={open}
                onClose={handleClose}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
                className="modal"
            >
                <Box sx={style} className="modal-container">
                    <div className="creatives-modal-close" onClick={handleClose}>
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 10L26 26" stroke="white" stroke-width="2" stroke-linecap="round" />
                            <path d="M26 10L10 26" stroke="white" stroke-width="2" stroke-linecap="round" />
                        </svg>

                    </div>
                    <div className="creatives-modal__img">
                        <img src={`${imgModal}`} alt="" />
                    </div>
                </Box>
            </Modal> */}

            {/* <Modal
                keepMounted
                open={openBlock}
                onClose={handleCloseBlock}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
                className="modal"
            >
                <Box sx={styleBlock} className="modal-container">
                    <div className="creatives-modal-close" onClick={handleCloseBlock}>
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 10L26 26" stroke="white" stroke-width="2" stroke-linecap="round" />
                            <path d="M26 10L10 26" stroke="white" stroke-width="2" stroke-linecap="round" />
                        </svg>

                    </div>
                    {rows.length && (
                        <PromotionsPageBlock
                            blockStatus={rows[blockIndex]?.promationStatus || ''}
                            email={rows[blockIndex]?.email || ''}
                            promationImg={rows[blockIndex]?.promationImg || ''}
                            promationSite={rows[blockIndex]?.promationSite || ''}
                            promationDate={rows[blockIndex]?.promationDate || ''}
                            promationBudget={rows[blockIndex]?.promationBudget || ''}
                            promationDuration={rows[blockIndex]?.promationDuration || ''}
                            promationAction={rows[blockIndex]?.promationAction || ''}
                            promationLocation={rows[blockIndex]?.promationLocation || ''}
                            promationPrimaryText={rows[blockIndex]?.promationPrimaryText || ''}
                            promationHeadlineText={rows[blockIndex]?.promationHeadlineText || ''}
                            promationId={rows[blockIndex]?.promationId || ''}
                            promationRejectTitle={rows[blockIndex]?.promationRejectTitle || ''}
                            promationRejectDescription={rows[blockIndex]?.promationRejectDescription || ''}
                            promationRejectedAt={rows[blockIndex]?.promationRejectedAt || ''}
                            adminEmail={rows[blockIndex]?.adminEmail || ''}
                            activeUserId={rows[blockIndex]?.userId || ''}
                        />
                    )}

                </Box>
            </Modal> */}
        </div>
    );
}

export default PromotionsNew;
