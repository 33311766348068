import React, { useState } from 'react';
import Header from "../../components/header/Header";

// import AiNavbar from "../../components/AiGenerationNavbar/AiNavbar";
import Cookies from "js-cookie";
import axios from "axios";

import './style.scss'
import AiNavbar from '../../components/ai-navbar/AiNavbar';
import config from '../../config';
//temperature
// top_p:
// n:
//  jsonRes:

const AiPrompts = (onPromptChange) => {
    const [model, setModel] = useState('gpt-4o');
    const [maxTokens, setMaxTokens] = useState(20);
    const [temperature, setTemperature] = useState(0.7);
    const [topP, setTopP] = useState(0.9);
    const [n, setN] = useState(1);
    const [jsonRes, setJsonRes] = useState(false);
    const [promptValue, setPromptValue] = useState('');
    const [generationResult, setResult] = useState('');

    const handleModelChange = (e) => {
        const newValue = e.target.value;
        setModel(newValue);
    };

    const handlePromptChange = (e) => {
        const newValue = e.target.value;
        setPromptValue(newValue);
    };

    const handleMaxTokensChange = (e) => {
        const newValue = e.target.value;
        setMaxTokens(newValue);
    }

    const handleTemperatureChange = (e) => {
        const newValue = e.target.value;
        setTemperature(newValue);
    }

    const handleTopPChange = (e) => {
        const newValue = e.target.value;
        setTopP(newValue);
    }

    const handleNChange = (e) => {
        const newValue = e.target.value;
        setN(newValue);
    }

    const handleJsonResChange = (e) => {
        const newValue = e.target.value;
        setJsonRes(newValue);
    }

    const generateFromPrompt = async () => {
        try {
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${Cookies.get('authToken')}`,
            }

            const response = await axios.post(`${config.apiBaseUrl}admins/ai/prompts`, {
                model,
                max_tokens: maxTokens,
                temperature,
                top_p: topP,
                n,
                jsonRes,
                prompt: promptValue,
            }, { headers });

            const { data } = response.data;

            setResult(data);
        } catch (e) {
            console.log(e);
        }
    }


    return (
        <>
            <Header />


            <div className="content">
                <div className="container">

                    <AiNavbar />
                    <div className="aiPrompts">

                        <div className="aiPrompts-inputs">
                            <div className='input'>
                                <span>Ai model</span>
                                <input
                                    type="text"
                                    value={model}
                                    width="320px"
                                    onChange={handleModelChange}
                                    placeholder='Ai model' />
                            </div>
                            <div className='input'>
                                <span>Max tokens</span>
                                <input
                                    type="text"
                                    value={maxTokens}
                                    onChange={handleMaxTokensChange}
                                    placeholder='Max tokens' />
                            </div>
                            <div className='input'>
                                <span>Temperature</span>
                                <input
                                    type="text"
                                    value={temperature}
                                    onChange={handleTemperatureChange}
                                    placeholder='Temperature' />
                            </div>
                            <div className='input'>
                                <span>Top P</span>
                                <input
                                    type="text"
                                    value={topP}
                                    onChange={handleTopPChange}
                                    placeholder='Top P' />
                            </div>
                            <div className='input'>
                                <span>N</span>
                                <input
                                    type="text"
                                    value={n}
                                    onChange={handleNChange}
                                    placeholder='N' />
                            </div>
                            <div className='input'>
                                <span>Json response</span>
                                <input
                                    type="text"
                                    value={jsonRes}
                                    onChange={handleJsonResChange}
                                    placeholder='Json response' />
                            </div>

                        </div>

                        <div className='input' style={{ marginBottom: '10rem' }}>
                            <span>Prompt</span>
                            <textarea placeholder="Prompt" name="prompt" rows="5" cols="40" value={promptValue} onChange={handlePromptChange}>
                                {promptValue}
                            </textarea>
                        </div>
                        <div className='input' style={{ marginBottom: '32rem' }}>
                            <span>Result</span>
                            <textarea placeholder="Result" name="aiResult" rows="15" cols="50" value={generationResult}>
                                {generationResult}
                            </textarea>
                        </div>
                        <button className="btn" onClick={generateFromPrompt}>Generate</button>
                    </div>

                </div>
            </div>


        </>
    );
}

export default AiPrompts;