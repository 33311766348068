import React, { useState, useEffect } from 'react';
import Filter from '../../components/filter/Filter';
import Header from '../../components/header/Header';
import Search from '../../components/search/Search';
import SelectCustom from '../../components/selectCustom/SelectCustom';
import './promotions.scss';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBack from '../../svg/ArrowBack';
import ArrowNext from '../../svg/ArrowNext';
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { NavLink } from 'react-router-dom';
import PromotionsPageBlock from './PromotionsPageBlock';
import { gsap } from 'gsap';
import Cookies from 'js-cookie';
import PromotionsPageBlockNew from './PromotionsPageBlockNew';
import FilterPromotions from '../../components/filter/FilterPromotions';
import config from '../../config';

const fetchPromationData = async (activePage, selectedSort, filter) => {

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('authToken')}`,
        // 'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkremVCJ9.eyJpZCI6IjA5YjIwNzc3LTVlODEtNDM1NC1hMzk1LWNjNDllMmEwNDhhMSIsImVtYWlsIjoiYWRtaW5AemVlbHkuYXBwIiwicm9sZSI6ImFkbWluIiwiaWF0IjoxNzExMzQyNjAzLCJleHAiOjE3MTE0MjkwMDN9.GW3yB8N8VJcXojgDedxnKp1qNfUAedFA44eQnmnjvSw`,
    };

    const skip = activePage * 1 - 1;
    const url = `${config.apiBaseUrl}admins/facebook-ads`;
    const params = {
        skip: skip,
        limit: 100,
        // filter:  JSON.stringify({"createdAt": {"from": "12-17-2023", "to": "12-18-2023"}}),
        // filter:  JSON.stringify({"pushToken":true}),
        // filter: JSON.stringify({createdAt: '21.10.23'}),
        // filter: JSON.stringify({"status": "PENDING"}),
        filter: JSON.stringify(filter),
        sort: JSON.stringify(selectedSort)
    }
    const response = await axios.get(url, {
        headers,
        params
    });
    return response.data.data;
};




const PromotionsPage = ({ setPromationsPageTotal }) => {

    const queryClient = useQueryClient();

    const buttonAnimation = (element) => {
        const tl = gsap.timeline();
        tl.to(element, {
            duration: 0.085,
            scale: 0.80,
            opacity: 0.85,
            ease: "power4.out",
        });
        tl.to(element, {
            duration: 0.55,
            scale: 1,
            opacity: 1,
            ease: "elastic.out(1,0.6)",
        });
    }

    const [sidebarOpen, setSidebarOpen] = React.useState(false);

    const [total, setTotal] = useState('0');
    const pages = Math.ceil(total / 100)
    const [activePage, setActivePage] = useState(1);

    const handlePageChange = (event, page) => {
        buttonAnimation(event.target)
        setActivePage(page);
    };




    const sorts = [
        { value: 'createdAt', label: 'Newest' },
        { value: 'createdAt1', label: 'Oldest' },
    ];

    const defaultValue = { value: 'createdAt', label: 'Newest' };
    const [selectedSort, setSelectedSort] = useState({ [defaultValue.value]: "desc" });
    const handleSelectChange = (selectedOption) => {
        if (selectedOption.value == 'createdAt1') {
            setSelectedSort({ ["createdAt"]: "asc" })
        } else {
            setSelectedSort({ ["createdAt"]: "desc" })
        }
    };

    const [byActive, setByActive] = useState(true);

    const [activeFilters, setActiveFilters] = useState({});
    const [additionalValues, setAdditionalValues] = useState([]);

    const getActivePlatform = (filters) => {
        const activePlatform = Object.keys(filters).find((key) => filters[key] === true && (key === 'android' || key === 'iOS'));
        return activePlatform || null;
    };


    const activePlatform = getActivePlatform(activeFilters);

    const [searchTerm, setSearchTerm] = useState('');

    const filter = {
        // pushToken: activeFilters.pushTokenYes,
        // firstAppLogin: byActive,
        "status": "PENDING"
    };


    const handleSearchChange = (value) => {
        setSearchTerm(value);
    };

    const selectedValues = additionalValues.filter(value => activeFilters[value]);


    // if (selectedValues !== "") {
    //     filter.subscription = selectedValues.map(pricingPlan => ({
    //         pricingPlan,
    //         status: "ACTIVE",
    //     }))
    // }



    if (searchTerm !== "") {
        filter.email = searchTerm;
    }


    if (activePlatform !== null) {
        filter.os = activePlatform;
    }

    const [fromData, setFormData] = useState('');
    const [toData, setToData] = useState('');

    if (activeFilters.createdDate == true) {
        filter.createdAt = { "from": fromData, "to": toData };
    }
    const { isLoading: isPromationLoading, error: promationError, data: promationData, refetch } = useQuery(['promationData', activePage, selectedSort, filter], () => fetchPromationData(activePage, selectedSort, filter));

    const [loading, setLoading] = useState(true);

    const [promationTotal, setPromationTotal] = useState('');
    const [promationImg, setPromationImg] = useState([]);
    const [promationSite, setPromationSite] = useState('');
    const [promationDate, setPromationDate] = useState('');
    const [promationBudget, setPromationBudget] = useState(1);
    const [promationDailyBudget, setPromationDailyBudget] = useState(1);
    const [promationDuration, setPromationDuration] = useState('');
    const [promationAction, setPromationAction] = useState('');
    const [promationLocation, setPromationLocation] = useState('');
    const [promationPrimaryText, setPromationPrimaryText] = useState('');
    const [promationHeadlineText, setPromationHeadlineText] = useState('');
    const [promationAds, setPromationAds] = useState([]);
    const [promationId, setPromationId] = useState('');
    const [activeUserId, setActiveUserId] = useState('');
    const [email, setEmail] = useState('');
    const [leadFormUrl, setLeadFormUrl] = useState('');
    const [promationTime, setPromationTime] = useState("");
    const [promationDataEdit, setPromationDataEdit] = useState({});

    const refetchClick = () => {
        queryClient.refetchQueries(['promationData', activePage, selectedSort, filter]);
    }

    // useEffect(() => {
    //     setPromationsPageTotal(promationTotal)
    // }, [promationTotal]);

    useEffect(() => {
        if (isPromationLoading) {
            setLoading(true);
        }
        if (!isPromationLoading && !promationError && promationData) {
            setPromationTotal(promationData.total)
            if (promationData.items.length > 0) {
                setEmail(promationData.items[0].user.email)
                setActiveUserId(promationData.items[0].userId)

                // setPromationsPageTotal(promationData.total)
                setPromationImg(promationData.items[0].images)
                setPromationSite(promationData.items[0].body.websiteUrl)
                const inputDateString = promationData.items[0].createdAt;
                const date = new Date(inputDateString);
                const day = date.getUTCDate();
                const month = date.getUTCMonth() + 1;
                const year = date.getUTCFullYear();
                const formattedDate = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`;

                var hours = date.getHours();
                var minutes = date.getMinutes();

                hours = (hours < 10 ? '0' : '') + hours;
                minutes = (minutes < 10 ? '0' : '') + minutes;

                var formattedTime = hours + ':' + minutes;

                setPromationDate(formattedDate)
                setPromationBudget(promationData.items[0].body.budget)
                setPromationDailyBudget(promationData.items[0].body.dailyBudget)
                setPromationDuration(promationData.items[0].body.budget / promationData.items[0].body.dailyBudget)
                setPromationAction(promationData.items[0].body.actionButton)
                const targetingLocations = promationData.items[0].body.targetingLocations;
                setPromationLocation(targetingLocations.map(location => location.name).join(', '));
                setPromationPrimaryText(promationData.items[0].body.primaryText)
                setPromationHeadlineText(promationData.items[0].body.headline)
                setPromationId(promationData.items[0].id)
                setLoading(false);
                setLeadFormUrl(promationData.items[0].body.leadFormUrl)
                setPromationTime(formattedTime)
                if (promationData.items[0].body.ads) {
                    setPromationAds(promationData.items[0].body.ads)
                } else {
                    setPromationAds([])
                }
                setPromationDataEdit(promationData.items[0])
            }

        }
    }, [isPromationLoading, promationError, promationData]);




    return (

        <div className='main'>
            <Header />
            <div className="content">
                <div className="container">
                    <div className="users-search">
                        <Search onSearchChange={handleSearchChange} />
                        <FilterPromotions setActiveFilters={setActiveFilters} additionalValues={additionalValues} setFormData={setFormData} setToData={setToData} zIndex />
                    </div>


                    <div className="table" style={{ overflow: 'visible' }}>
                        <div className="table-top" style={{ zIndex: '3' }}>
                            <div className="promotionsPage-nav">
                                <NavLink to="/promotions" activeClassName="active">
                                    On moderation {promationTotal}
                                </NavLink>
                                <NavLink to="/promotions/deffered" activeClassName="active">
                                    Deffered
                                </NavLink>

                                <NavLink to="/promotions/rejected" activeClassName="active">
                                    Rejected
                                </NavLink>
                                <NavLink to="/promotions/approved" activeClassName="active">
                                    Approved
                                </NavLink>
                                <NavLink to="/promotions/all" activeClassName="active">
                                    All
                                </NavLink>
                            </div>

                            <div className="pagination">
                                <Stack spacing={2}>
                                    <Pagination
                                        count={promationTotal}
                                        siblingCount={1}
                                        page={activePage}
                                        onChange={handlePageChange}
                                        renderItem={(item) => (
                                            <PaginationItem
                                                slots={{ previous: ArrowBack, next: ArrowNext }}
                                                {...item}
                                            />
                                        )}
                                    />
                                </Stack>
                                <div className="pagination__page">100/Page</div>
                            </div>
                            <SelectCustom sorts={sorts} onSelectChange={handleSelectChange} defaultValue={defaultValue} />
                        </div>{promationTotal === 0 ? (<div className="promotionsPage-empty">Currently, there are no advertisements awaiting moderation</div>

                        ) : (
                            <>
                                <PromotionsPageBlockNew
                                    blockStatus="PENDING"
                                    loading={loading}
                                    email={email}
                                    promationImg={promationImg}
                                    promationSite={promationSite}
                                    promationDate={promationDate}
                                    promationBudget={promationBudget}
                                    promationDailyBudget={promationDailyBudget}
                                    promationDuration={promationDuration}
                                    promationAction={promationAction}
                                    promationLocation={promationLocation}
                                    promationPrimaryText={promationPrimaryText}
                                    promationHeadlineText={promationHeadlineText}
                                    promationId={promationId}
                                    setActivePage={setActivePage}
                                    activePage={activePage}
                                    promationTotal={promationTotal}
                                    refetch={refetchClick}
                                    activeUserId={activeUserId}
                                    leadFormUrl={leadFormUrl}
                                    promationTime={promationTime}
                                    promationAds={promationAds}
                                    promationData={promationDataEdit}
                                />

                            </>


                        )}
                    </div>
                </div>
            </div>
        </div>


    );
}

export default PromotionsPage;
