import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import './sidebar.scss';
import UserInfo from '../../pages/UserInfo/UserInfo';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/scrollbar';
import { FreeMode, Scrollbar, Mousewheel } from 'swiper/modules';
import Promotions from '../../pages/promotions/Promotions';
import Creatives from '../../pages/creatives/Creatives';
import Activity from '../../pages/activity/Activity';
import Orders from '../../pages/orders/Orders';
import Website from '../../pages/website/Website';
import Products from '../../pages/products/Products';
import Ai from '../../pages/ai/Ai';
import History from '../../pages/history/History';
import About from '../../pages/about/About';
import { gsap } from 'gsap';
import PromotionsNew from '../../pages/promotionsNew/PromotionsNew';
import Videos from '../../pages/videos/Videos';

function CustomTabPanel(props) {

  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const SideBar = ({ onSetSidebarOpen, snackbarOpen, setSnackbarOpen, setSnackbarText, activeUserId, setSnackbarCount }) => {


  const handleOpenSnackbar = () => {
    setSnackbarOpen(true);
    setSnackbarText('0db31bf4-9b05-4e37-9430-a39079076440 <span>successfully deleted</span>')
    setSnackbarCount(10);
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = (e) => {
    buttonAnimation(e.target)
    setTimeout(() => {
      setOpen(true)
    }, 500);
  };
  const handleClose = (e) => {
    if (e.target.className.includes('MuiModal-backdrop')) {
      setOpenInvoice(false);
      return
    }
    buttonAnimation(e.target)
    setTimeout(() => {
      setOpen(false);
    }, 500);
  };


  const [openReset, setOpenReset] = React.useState(false);
  const handleOpenReset = (e) => {
    buttonAnimation(e.target)
    setTimeout(() => {
      setOpenReset(true)
    }, 500);

  };
  const handleCloseReset = (e) => {
    if (e.target.className.includes('MuiModal-backdrop')) {
      setOpenInvoice(false);
      return
    }
    buttonAnimation(e.target)
    setTimeout(() => {
      setOpenReset(false);
    }, 500);
  }


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "540rem",
    background: '#fff',
    borderRadius: "24rem",
    padding: '60rem 68rem 60rem',
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const buttonAnimation = (element) => {
    const tl = gsap.timeline();
    tl.to(element, {
      duration: 0.085,
      scale: 0.85,
      opacity: 0.85,
      ease: "power4.out",
    });
    tl.to(element, {
      duration: 0.55,
      scale: 1,
      opacity: 1,
      ease: "elastic.out(1,0.6)",
    });
  }


  const [userInfoSubscription, setUserInfoSubscription] = React.useState(true);
  return (
    <Box sx={{ width: '100%' }}>
      <div className="sidebar-wrapper">
        <div className="sidebar-nav">
          <div className="sidebar-close" onClick={() => onSetSidebarOpen(false, '')}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6 6L18 18" stroke="black" stroke-width="1.6" stroke-linecap="round" />
              <path d="M18 6L6 18" stroke="black" stroke-width="1.6" stroke-linecap="round" />
            </svg>
          </div>
          <Box>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
              <Tab icon={
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">

                  <path className="fill" fill-rule="evenodd" clip-rule="evenodd" d="M17.9991 17.3889C19.6253 17.3889 20.9436 16.0706 20.9436 14.4444C20.9436 12.8183 19.6253 11.5 17.9991 11.5C16.373 11.5 15.0547 12.8183 15.0547 14.4444C15.0547 16.0706 16.373 17.3889 17.9991 17.3889ZM17.9991 18.8889C20.4537 18.8889 22.4436 16.899 22.4436 14.4444C22.4436 11.9898 20.4537 10 17.9991 10C15.5445 10 13.5547 11.9898 13.5547 14.4444C13.5547 16.899 15.5445 18.8889 17.9991 18.8889Z" fill="black" />
                  <path className="fill" fill-rule="evenodd" clip-rule="evenodd" d="M23.6109 24.5027V22.9319C23.6109 21.8673 22.5618 20.8971 21.2893 21.0362C20.2741 21.1471 19.1428 21.2282 17.9998 21.2282C16.8567 21.2282 15.7255 21.1471 14.7102 21.0362C13.4378 20.8971 12.3887 21.8673 12.3887 22.9319V24.5027H23.6109ZM14.8731 19.545C12.8218 19.3209 10.8887 20.8684 10.8887 22.9319V24.8027C10.8887 25.4655 11.4259 26.0027 12.0887 26.0027H23.9109C24.5736 26.0027 25.1109 25.4655 25.1109 24.8027V22.9319C25.1109 20.8684 23.1777 19.3209 21.1264 19.545C20.1497 19.6517 19.0748 19.7282 17.9998 19.7282C16.9248 19.7282 15.8498 19.6517 14.8731 19.545Z" fill="black" />

                </svg>
              } {...a11yProps(0)} />
              <Tab icon={
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">

                  <path className="fill" fill-rule="evenodd" clip-rule="evenodd" d="M19.6589 12.7969H26.2352V20.2192H24.7352V14.2969H19.6589V12.7969Z" fill="white" />
                  <path className="fill" fill-rule="evenodd" clip-rule="evenodd" d="M25.5607 15L18.7656 21.7951L15.0597 18.501L10.5 23.0607L9.43933 22L14.9991 16.4402L18.705 19.7343L24.5 13.9393L25.5607 15Z" fill="white" />
                </svg>



              } {...a11yProps(1)} />
              {/* <Tab icon={
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">

                  <path className="fill" fill-rule="evenodd" clip-rule="evenodd" d="M18 25.5C22.1421 25.5 25.5 22.1421 25.5 18C25.5 13.8579 22.1421 10.5 18 10.5C13.8579 10.5 10.5 13.8579 10.5 18C10.5 22.1421 13.8579 25.5 18 25.5ZM18 27C22.9706 27 27 22.9706 27 18C27 13.0294 22.9706 9 18 9C13.0294 9 9 13.0294 9 18C9 22.9706 13.0294 27 18 27Z" fill="black" />
                  <path className="fill" d="M18.9794 17.2538V15.2367C19.534 15.3472 19.9716 15.596 20.3219 15.9551L20.6138 16.2591L21.752 15.209L21.4309 14.905C20.7889 14.2971 19.9427 13.9378 18.9503 13.7999V12.75H17.4621V13.8001C15.8279 13.9936 14.7189 14.933 14.7189 16.1489C14.7189 17.6962 16.2071 18.166 17.4621 18.4699V20.7909C16.5575 20.6252 15.9738 20.183 15.682 19.9066L15.3609 19.6027L14.252 20.6803L14.5439 20.9567C15.2443 21.6475 16.2656 22.1172 17.433 22.2279L17.4328 23.25H18.921V22.2277C20.9931 21.979 21.7226 20.8185 21.7226 19.7409C21.781 18.0829 20.1469 17.5303 18.9794 17.2539L18.9794 17.2538ZM18.9794 20.7907V18.8289C19.7965 19.0776 20.1175 19.354 20.1175 19.7961C20.1175 19.9895 20.03 20.5974 18.9794 20.7907ZM17.4912 15.209V16.8671C16.7325 16.646 16.4406 16.4249 16.4406 16.0934C16.4406 15.7063 16.8492 15.3471 17.4912 15.209V15.209Z" fill="black" />
                </svg>
              } {...a11yProps(2)} /> */}
              <Tab icon={
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">

                  <path className="fill" fill-rule="evenodd" clip-rule="evenodd" d="M18 25.5C22.1421 25.5 25.5 22.1421 25.5 18C25.5 13.8579 22.1421 10.5 18 10.5C13.8579 10.5 10.5 13.8579 10.5 18C10.5 22.1421 13.8579 25.5 18 25.5ZM18 27C22.9706 27 27 22.9706 27 18C27 13.0294 22.9706 9 18 9C13.0294 9 9 13.0294 9 18C9 22.9706 13.0294 27 18 27Z" fill="black" />
                  <path className="fill" d="M18.9794 17.2538V15.2367C19.534 15.3472 19.9716 15.596 20.3219 15.9551L20.6138 16.2591L21.752 15.209L21.4309 14.905C20.7889 14.2971 19.9427 13.9378 18.9503 13.7999V12.75H17.4621V13.8001C15.8279 13.9936 14.7189 14.933 14.7189 16.1489C14.7189 17.6962 16.2071 18.166 17.4621 18.4699V20.7909C16.5575 20.6252 15.9738 20.183 15.682 19.9066L15.3609 19.6027L14.252 20.6803L14.5439 20.9567C15.2443 21.6475 16.2656 22.1172 17.433 22.2279L17.4328 23.25H18.921V22.2277C20.9931 21.979 21.7226 20.8185 21.7226 19.7409C21.781 18.0829 20.1469 17.5303 18.9794 17.2539L18.9794 17.2538ZM18.9794 20.7907V18.8289C19.7965 19.0776 20.1175 19.354 20.1175 19.7961C20.1175 19.9895 20.03 20.5974 18.9794 20.7907ZM17.4912 15.209V16.8671C16.7325 16.646 16.4406 16.4249 16.4406 16.0934C16.4406 15.7063 16.8492 15.3471 17.4912 15.209V15.209Z" fill="black" />
                </svg>

              }{...a11yProps(2)} />
              <Tab icon={
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">

                  <path className="fill" fill-rule="evenodd" clip-rule="evenodd" d="M21.185 16.4691L24.581 19.8651L25.6416 18.8044L21.9995 15.1624C21.4897 14.6525 20.6478 14.7072 20.2082 15.2787L16.6553 19.8975L15.8179 18.8508C15.3431 18.2573 14.442 18.2523 13.9607 18.8406L10.3086 23.3043L11.4695 24.2541L14.8825 20.0827L16.0812 21.5811C16.2251 21.7609 16.4436 21.8647 16.674 21.8625C16.9043 21.8603 17.1209 21.7524 17.2613 21.5698L21.185 16.4691Z" fill="black" />
                  <path className="fill" fill-rule="evenodd" clip-rule="evenodd" d="M10.1387 13.3812C10.1387 12.0834 11.1908 11.0312 12.4887 11.0312H23.5109C24.8088 11.0312 25.8609 12.0834 25.8609 13.3812V22.6257C25.8609 23.9236 24.8088 24.9757 23.5109 24.9757H12.4887C11.1908 24.9757 10.1387 23.9236 10.1387 22.6257V13.3812ZM12.4887 12.5312C12.0192 12.5312 11.6387 12.9118 11.6387 13.3812V22.6257C11.6387 23.0951 12.0192 23.4757 12.4887 23.4757H23.5109C23.9803 23.4757 24.3609 23.0951 24.3609 22.6257V13.3812C24.3609 12.9118 23.9803 12.5312 23.5109 12.5312H12.4887Z" fill="black" />
                  <path className="fill" d="M16.6658 15.1102C16.6658 15.9694 15.9694 16.6658 15.1102 16.6658C14.2511 16.6658 13.5547 15.9694 13.5547 15.1102C13.5547 14.2511 14.2511 13.5547 15.1102 13.5547C15.9694 13.5547 16.6658 14.2511 16.6658 15.1102Z" fill="black" />

                </svg>

              }
                {...a11yProps(3)} />
              <Tab icon={
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">

                  <path className="fill" fill-rule="evenodd" clip-rule="evenodd" d="M16.0334 12.2507C16.352 12.2649 16.6267 12.479 16.7184 12.7845L19.1013 20.7274L20.3036 17.7215C20.4175 17.4367 20.6933 17.25 21 17.25H26C26.4142 17.25 26.75 17.5858 26.75 18C26.75 18.4142 26.4142 18.75 26 18.75H21.5078L19.6964 23.2785C19.5779 23.5746 19.2852 23.7635 18.9666 23.7493C18.648 23.7351 18.3733 23.521 18.2816 23.2155L15.8987 15.2726L14.6964 18.2785C14.5825 18.5633 14.3067 18.75 14 18.75H10C9.58579 18.75 9.25 18.4142 9.25 18C9.25 17.5858 9.58579 17.25 10 17.25H13.4922L15.3036 12.7215C15.4221 12.4254 15.7148 12.2366 16.0334 12.2507Z" fill="black" />
                  <path className="fill" fill-rule="evenodd" clip-rule="evenodd" d="M18 25.5C22.1421 25.5 25.5 22.1421 25.5 18C25.5 13.8579 22.1421 10.5 18 10.5C13.8579 10.5 10.5 13.8579 10.5 18C10.5 22.1421 13.8579 25.5 18 25.5ZM18 27C22.9706 27 27 22.9706 27 18C27 13.0294 22.9706 9 18 9C13.0294 9 9 13.0294 9 18C9 22.9706 13.0294 27 18 27Z" fill="black" />

                </svg>



              } {...a11yProps(4)} />
              <Tab icon={
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">

                  <path className="fill" fill-rule="evenodd" clip-rule="evenodd" d="M10.25 11.0772C10.25 10.0681 11.068 9.25 12.0772 9.25H23.9228C24.932 9.25 25.75 10.068 25.75 11.0772V26.2511C25.75 27.1748 24.7053 27.7115 23.9544 27.1735L22.4564 26.1001L20.9383 27.3692C20.517 27.7213 19.904 27.7213 19.4828 27.3692L18 26.1297L16.5172 27.3692C16.096 27.7213 15.483 27.7213 15.0617 27.3692L13.5436 26.1001L12.0456 27.1735C11.2947 27.7115 10.25 27.1748 10.25 26.2511V11.0772ZM12.0772 10.75C11.8965 10.75 11.75 10.8965 11.75 11.0772V25.54L12.8999 24.716C13.3205 24.4147 13.8916 24.436 14.2886 24.7678L15.7895 26.0225L17.2723 24.783C17.6935 24.4308 18.3065 24.4308 18.7277 24.783L20.2105 26.0225L21.7114 24.7678C22.1084 24.436 22.6795 24.4147 23.1001 24.716L24.25 25.54V11.0772C24.25 10.8965 24.1035 10.75 23.9228 10.75H12.0772Z" fill="black" />
                  <path className="fill" d="M13.209 13.9219H19.105V15.3269H13.209V13.9219Z" fill="black" />
                  <path className="fill" d="M13.209 16.7326H19.105V18.1376H13.209V16.7326Z" fill="black" />
                  <path className="fill" d="M13.209 19.5391H19.105V20.9441H13.209V19.5391Z" fill="black" />
                  <path className="fill" d="M20.5787 13.9219H22.7897V15.3269H20.5787V13.9219Z" fill="black" />
                  <path className="fill" d="M20.5787 16.7326H22.7897V18.1376H20.5787V16.7326Z" fill="black" />
                  <path className="fill" d="M20.5787 19.5391H22.7897V20.9441H20.5787V19.5391Z" fill="black" />

                </svg>

              } {...a11yProps(5)} />
              {/* <Tab icon={
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">

                  <path className="fill" fill-rule="evenodd" clip-rule="evenodd" d="M18 25.5C22.1421 25.5 25.5 22.1421 25.5 18C25.5 13.8579 22.1421 10.5 18 10.5C13.8579 10.5 10.5 13.8579 10.5 18C10.5 22.1421 13.8579 25.5 18 25.5ZM18 27C22.9706 27 27 22.9706 27 18C27 13.0294 22.9706 9 18 9C13.0294 9 9 13.0294 9 18C9 22.9706 13.0294 27 18 27Z" fill="black" />
                  <path className="fill" fill-rule="evenodd" clip-rule="evenodd" d="M19.8403 23.6931C20.5301 22.3136 21 20.3029 21 18C21 15.6971 20.5301 13.6864 19.8403 12.3069C19.1057 10.8376 18.3684 10.5 18 10.5C17.6316 10.5 16.8943 10.8376 16.1597 12.3069C15.4699 13.6864 15 15.6971 15 18C15 20.3029 15.4699 22.3136 16.1597 23.6931C16.8943 25.1624 17.6316 25.5 18 25.5C18.3684 25.5 19.1057 25.1624 19.8403 23.6931ZM18 27C20.4853 27 22.5 22.9706 22.5 18C22.5 13.0294 20.4853 9 18 9C15.5147 9 13.5 13.0294 13.5 18C13.5 22.9706 15.5147 27 18 27Z" fill="black" />
                  <path className="fill" fill-rule="evenodd" clip-rule="evenodd" d="M26 18.75H10V17.25H26V18.75Z" fill="black" />

                </svg>

              } {...a11yProps(6)} /> */}
              <Tab icon={
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">

                  <path className="fill" fill-rule="evenodd" clip-rule="evenodd" d="M12.0283 16.6459C12.2689 15.6439 13.165 14.9375 14.1955 14.9375H21.8022C22.8327 14.9375 23.7288 15.6439 23.9694 16.6459L25.647 23.6338C25.9835 25.0353 24.9212 26.3828 23.4799 26.3828H12.5178C11.0765 26.3828 10.0142 25.0353 10.3507 23.6338L12.0283 16.6459ZM14.1955 16.4375C13.8585 16.4375 13.5655 16.6685 13.4869 16.9961L11.8092 23.984C11.6992 24.4422 12.0466 24.8828 12.5178 24.8828H23.4799C23.9511 24.8828 24.2985 24.4422 24.1885 23.984L22.5108 16.9961C22.4322 16.6685 22.1392 16.4375 21.8022 16.4375H14.1955Z" fill="black" />
                  <path className="fill" fill-rule="evenodd" clip-rule="evenodd" d="M15.5608 15.2949C15.5697 15.5193 15.5778 15.7241 15.5778 15.9018H14.0778C14.0778 15.8017 14.0718 15.6612 14.0644 15.4897C14.0332 14.7611 13.9779 13.4716 14.2579 12.3305C14.4368 11.6015 14.767 10.843 15.3863 10.2648C16.0212 9.67189 16.8875 9.33594 17.9995 9.33594C19.1114 9.33594 19.9778 9.67189 20.6127 10.2648C21.2319 10.843 21.5622 11.6015 21.7411 12.3305C22.0211 13.4717 21.9658 14.7613 21.9345 15.4898C21.9272 15.6613 21.9212 15.8017 21.9212 15.9018L20.4212 15.9017C20.4212 15.7241 20.4293 15.5194 20.4382 15.2951C20.4677 14.5508 20.5058 13.5908 20.2843 12.6879C20.146 12.1245 19.9212 11.6713 19.5889 11.3611C19.2723 11.0654 18.7906 10.8359 17.9995 10.8359C17.2083 10.8359 16.7267 11.0654 16.41 11.3611C16.0778 11.6713 15.853 12.1245 15.7147 12.6879C15.4932 13.5908 15.5313 14.5507 15.5608 15.2949Z" fill="black" />

                </svg>

              } {...a11yProps(6)} />
              <Tab icon={


                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_9028_14950)">
                    <path className="stroke" d="M27 18C27 22.971 22.971 27 18 27C13.029 27 9 22.971 9 18C9 13.029 13.029 9 18 9C22.971 9 27 13.029 27 18Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path className="stroke" d="M16.9411 15.0578L20.8231 17.3538C21.3141 17.6438 21.3141 18.3548 20.8231 18.6448L16.9411 20.9408C16.4411 21.2368 15.8091 20.8758 15.8091 20.2948V15.7038C15.8091 15.1228 16.4411 14.7618 16.9411 15.0578Z" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                  </g>
                  <defs>
                    <clipPath id="clip0_9028_14950">
                      <rect width="24" className="fill" height="24" fill="white" transform="translate(6 6)" />
                    </clipPath>
                  </defs>
                </svg>



              } {...a11yProps(7)} />
              {/* <Tab icon={
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path className="fill" d="M24.4906 12.4375V23.9975H22.8926V12.4375H24.4906Z" fill="black" />
                  <path className="fill" d="M16.7233 12.4375L21.7213 23.9975H19.9873L18.8823 21.4135H13.0003L11.8953 23.9975H10.1953L15.2953 12.4375H16.7233ZM13.5443 20.1045H18.3383L15.9753 14.5285H15.9413L13.5443 20.1045Z" fill="black" />
                </svg>

              } {...a11yProps(7)} /> */}
              <Tab icon={
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path className="fill" fill-rule="evenodd" clip-rule="evenodd" d="M9.97852 13.6342C9.97852 12.4167 10.9655 11.4297 12.1831 11.4297H23.8194C25.037 11.4297 26.024 12.4167 26.024 13.6342V22.3615C26.024 23.579 25.037 24.5661 23.8194 24.5661H12.1831C10.9655 24.5661 9.97852 23.579 9.97852 22.3615V13.6342ZM12.1831 12.9297C11.794 12.9297 11.4785 13.2451 11.4785 13.6342V22.3615C11.4785 22.7506 11.794 23.0661 12.1831 23.0661H23.8194C24.2085 23.0661 24.524 22.7506 24.524 22.3615V13.6342C24.524 13.2451 24.2085 12.9297 23.8194 12.9297H12.1831Z" fill="black" />
                  <path className="fill" d="M10.7266 14.8529C10.7266 14.5803 10.9475 14.3594 11.2201 14.3594H24.7785C25.0511 14.3594 25.272 14.5803 25.272 14.8529V15.6436C25.272 15.9162 25.0511 16.1372 24.7785 16.1372H11.2201C10.9475 16.1372 10.7266 15.9162 10.7266 15.6436V14.8529Z" fill="black" />
                  <path className="fill" d="M12.1836 20.907C12.1836 20.5053 12.5092 20.1797 12.9109 20.1797H17.2745C17.6762 20.1797 18.0018 20.5053 18.0018 20.907V20.907C18.0018 21.3086 17.6762 21.6342 17.2745 21.6342H12.9109C12.5092 21.6342 12.1836 21.3086 12.1836 20.907V20.907Z" fill="black" />
                </svg>
              } {...a11yProps(8)} />
            </Tabs>
          </Box>
        </div>

        <div className="sidebar-right">
          <div className="sidebar-top">
            <div className="sidebar-top__id">
              User ID: {activeUserId}
            </div>
            {value === 0 && (
              <div className="sidebar-top-right">
                <ul>
                  <li onClick={(e) => handleOpenReset(e)}>
                    <svg style={{ pointerEvents: 'none' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M12.1028 9.5547V8.16487C12.1028 6.97143 11.1314 6 9.93794 6C8.74451 6 7.77307 6.97143 7.77307 8.16487V9.5547C7.32972 9.66438 7 10.0624 7 10.5399V12.978C7 13.5403 7.45583 13.9961 8.01807 13.9961H11.8585C12.4207 13.9961 12.8765 13.5403 12.8765 12.978V10.5399C12.8759 10.0624 12.5462 9.66438 12.1028 9.5547ZM9.93794 7.18293C10.4792 7.18293 10.9199 7.62365 10.9199 8.16487V9.52185H8.956V8.16487C8.956 7.62365 9.39673 7.18293 9.93794 7.18293Z" fill="black" />
                      <path d="M18.2444 9.24454C17.8271 9.24454 17.4889 9.58276 17.4889 10C17.4889 14.1294 14.1294 17.4889 10.0002 17.4889C5.87062 17.4889 2.5111 14.1294 2.5111 10C2.5111 5.87062 5.87062 2.51128 10 2.51128C11.5793 2.51128 13.098 3.0079 14.3571 3.90916H13.3597C12.9425 3.90916 12.6041 4.24738 12.6041 4.6648C12.6041 5.08204 12.9425 5.42026 13.3597 5.42026H16.2687C16.6859 5.42026 17.0241 5.08204 17.0241 4.6648V1.75564C17.0241 1.33822 16.6861 1 16.2687 1C15.8513 1 15.513 1.33822 15.513 1.75564V2.88694C13.9485 1.67338 12.0156 1 10 1C7.5961 1 5.33584 1.93618 3.6361 3.6361C1.936 5.33602 1 7.5961 1 10C1 12.4039 1.936 14.6642 3.6361 16.3639C5.33584 18.064 7.5961 19 10 19C12.4037 19 14.664 18.064 16.3637 16.3639C18.0638 14.6642 19 12.4039 19 10C19 9.58276 18.6616 9.24454 18.2444 9.24454Z" fill="black" />
                    </svg>
                    <span>Reset password</span>
                  </li>
                  {userInfoSubscription && (
                    <li>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M6 4L6 16" stroke="black" stroke-width="2.5" stroke-linecap="round" />
                        <path d="M14 4L14 16" stroke="black" stroke-width="2.5" stroke-linecap="round" />
                      </svg>
                      <span>Pause subscription</span>
                    </li>
                  )}

                  <li onClick={(e) => handleOpen(e)} className="delete">
                    <svg style={{ pointerEvents: 'none' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                      <path d="M9.99944 1.07227C11.6968 1.07227 12.8031 1.95339 13.1578 3.57193L17.4994 3.57227C17.8939 3.57227 18.2137 3.89206 18.2137 4.28655C18.2137 4.68104 17.8939 5.00084 17.4994 5.00084H16.7102L15.5585 15.9394C15.4437 17.03 14.5241 17.858 13.4275 17.858H6.57143C5.47481 17.858 4.55515 17.03 4.44035 15.9394L3.28873 5.00084H2.49944C2.10495 5.00084 1.78516 4.68104 1.78516 4.28655C1.78516 3.89206 2.10495 3.57227 2.49944 3.57227L6.84105 3.57193C7.19574 1.95339 8.30205 1.07227 9.99944 1.07227ZM15.273 5.00084H4.72516L5.86107 15.7899C5.89934 16.1534 6.20589 16.4294 6.57143 16.4294H13.4275C13.793 16.4294 14.0995 16.1534 14.1378 15.7899L15.273 5.00084ZM9.99944 6.42941C10.3939 6.42941 10.7137 6.7492 10.7137 7.14369V14.2866C10.7137 14.681 10.3939 15.0008 9.99944 15.0008C9.60495 15.0008 9.28516 14.681 9.28516 14.2866V7.14369C9.28516 6.7492 9.60495 6.42941 9.99944 6.42941ZM12.8923 6.4303C13.2863 6.45 13.5897 6.78537 13.57 7.17936L13.2128 14.3222C13.1931 14.7162 12.8578 15.0196 12.4638 14.9999C12.0698 14.9802 11.7663 14.6449 11.786 14.2509L12.1432 7.10802C12.1629 6.71403 12.4983 6.4106 12.8923 6.4303ZM7.10663 6.4303C7.50063 6.4106 7.83599 6.71403 7.85569 7.10802L8.21284 14.2509C8.23254 14.6449 7.92911 14.9802 7.53511 14.9999C7.14111 15.0196 6.80575 14.7162 6.78605 14.3222L6.4289 7.17936C6.4092 6.78537 6.71263 6.45 7.10663 6.4303ZM9.99944 2.50084C9.09465 2.50084 8.5711 2.8252 8.31932 3.57195H11.6796C11.4278 2.8252 10.9042 2.50084 9.99944 2.50084Z" fill="white" />
                    </svg>
                    <span>Delete account</span>
                  </li>
                </ul>
              </div>
            )}
          </div>

          {/* <Swiper
            direction={'vertical'}
            slidesPerView={'auto'}
            freeMode={true}
            scrollbar={true}
            mousewheel={true}
            modules={[FreeMode, Scrollbar, Mousewheel]}
            speed={3000}
            freeModeMomentumBounce={true}
            allowTouchMove={false}
            simulateTouch={false}
          >
            <SwiperSlide> */}
          <CustomTabPanel value={value} index={0}>
            <UserInfo activeUserId={activeUserId} setUserInfoSubscription={setUserInfoSubscription} setSnackbarOpen={setSnackbarOpen} setSnackbarText={setSnackbarText} setSnackbarCount={setSnackbarCount} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <About activeUserId={activeUserId} />
          </CustomTabPanel>
          {/* <CustomTabPanel value={value} index={2}>
            <Promotions activeUserId={activeUserId} />
          </CustomTabPanel> */}
          <CustomTabPanel value={value} index={2}>
            <PromotionsNew activeUserId={activeUserId} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={3}>
            <Creatives activeUserId={activeUserId} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={4}>
            <Activity activeUserId={activeUserId} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={5}>
            <Orders activeUserId={activeUserId} />
          </CustomTabPanel>
          {/* <CustomTabPanel value={value} index={6}>
                <Website onSetSidebarOpen={onSetSidebarOpen} snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} setSnackbarText={setSnackbarText} setSnackbarCount={setSnackbarCount} />
              </CustomTabPanel> */}
          <CustomTabPanel value={value} index={6}>
            <Products activeUserId={activeUserId} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={7}>
            <Videos activeUserId={activeUserId} />
          </CustomTabPanel>
          {/* <CustomTabPanel value={value} index={7}>
            <Ai activeUserId={activeUserId} />
          </CustomTabPanel> */}
          <CustomTabPanel value={value} index={8}>
            <History activeUserId={activeUserId} setSnackbarOpen={setSnackbarOpen} setSnackbarText={setSnackbarText} setSnackbarCount={setSnackbarCount} />
          </CustomTabPanel>
          {/* </SwiperSlide> */}
          {/* </Swiper> */}
        </div>
      </div>

      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className="modal"
      >
        <Box sx={style} className="modal-container">
          <div className="modal-close" onClick={handleClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6 6L18 18" stroke="black" stroke-width="1.6" stroke-linecap="round" />
              <path d="M18 6L6 18" stroke="black" stroke-width="1.6" stroke-linecap="round" />
            </svg>
          </div>
          <div className="modal-icon">
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="40" cy="40" r="40" fill="#F2F4F6" />
              <path d="M40.0006 23.9297C43.0559 23.9297 45.0472 25.5157 45.6857 28.4291L53.5006 28.4297C54.2106 28.4297 54.7863 29.0053 54.7863 29.7154C54.7863 30.4255 54.2106 31.0011 53.5006 31.0011H52.0798L50.0069 50.6906C49.8003 52.6537 48.1449 54.144 46.171 54.144H33.8301C31.8562 54.144 30.2008 52.6537 29.9942 50.6906L27.9213 31.0011H26.5006C25.7905 31.0011 25.2148 30.4255 25.2148 29.7154C25.2148 29.0053 25.7905 28.4297 26.5006 28.4297L34.3155 28.4291C34.9539 25.5157 36.9452 23.9297 40.0006 23.9297ZM49.493 31.0011H30.5068L32.5515 50.4214C32.6204 51.0758 33.1722 51.5725 33.8301 51.5725H46.171C46.829 51.5725 47.3807 51.0758 47.4496 50.4214L49.493 31.0011ZM40.0006 33.5725C40.7106 33.5725 41.2863 34.1482 41.2863 34.8583V47.7154C41.2863 48.4255 40.7106 49.0011 40.0006 49.0011C39.2905 49.0011 38.7148 48.4255 38.7148 47.7154V34.8583C38.7148 34.1482 39.2905 33.5725 40.0006 33.5725ZM45.2076 33.5741C45.9168 33.6096 46.463 34.2133 46.4275 34.9225L45.7847 47.7796C45.7492 48.4888 45.1455 49.035 44.4364 48.9995C43.7272 48.9641 43.181 48.3604 43.2164 47.6512L43.8593 34.7941C43.8948 34.0849 44.4984 33.5387 45.2076 33.5741ZM34.7935 33.5741C35.5027 33.5387 36.1064 34.0849 36.1418 34.7941L36.7847 47.6512C36.8201 48.3604 36.274 48.9641 35.5648 48.9995C34.8556 49.035 34.2519 48.4888 34.2164 47.7796L33.5736 34.9225C33.5381 34.2133 34.0843 33.6096 34.7935 33.5741ZM40.0006 26.5011C38.3719 26.5011 37.4295 27.085 36.9763 28.4291H43.0248C42.5716 27.085 41.6292 26.5011 40.0006 26.5011Z" fill="black" />
            </svg>
          </div>
          <div className="modal-title">Do you really want to <br />delete this account?</div>
          <div className="modal-text">This action is irreversible</div>
          <div className="modal-action">
            <div className="modal__cancel" onClick={handleClose} >Cancel</div>
            <div className="modal__delete" onClick={(e) => {


              buttonAnimation(e.target)
              handleClose(e);

              setTimeout(() => {
                onSetSidebarOpen(false, '');
                handleOpenSnackbar();
              }, 500);


            }} >Delete</div>
          </div>
        </Box>
      </Modal>

      <Modal
        keepMounted
        open={openReset}
        onClose={handleCloseReset}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description"
        className="modal"
      >
        <Box sx={style} className="modal-container">
          <div className="modal-close" onClick={handleCloseReset}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <path d="M6 6L18 18" stroke="black" stroke-width="1.6" stroke-linecap="round" />
              <path d="M18 6L6 18" stroke="black" stroke-width="1.6" stroke-linecap="round" />
            </svg>
          </div>
          <div className="modal-icon">
            <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="40" cy="40" r="40" fill="#F2F4F6" />
              <path d="M43.9707 39.1597V36.5345C43.9707 34.2802 42.1358 32.4453 39.8815 32.4453C37.6272 32.4453 35.7923 34.2802 35.7923 36.5345V39.1597C34.9548 39.3669 34.332 40.1188 34.332 41.0207V45.626C34.332 46.688 35.193 47.549 36.255 47.549H43.5092C44.5712 47.549 45.4322 46.688 45.4322 45.626V41.0207C45.4309 40.1188 44.8081 39.3669 43.9707 39.1597ZM39.8815 34.6797C40.9038 34.6797 41.7363 35.5122 41.7363 36.5345V39.0977H38.0267V36.5345C38.0267 35.5122 38.8592 34.6797 39.8815 34.6797Z" fill="black" />
              <path d="M55.5727 38.573C54.7846 38.573 54.1457 39.2119 54.1457 40C54.1457 47.7999 47.7999 54.1457 40.0003 54.1457C32.2001 54.1457 25.8543 47.7999 25.8543 40C25.8543 32.2001 32.2001 25.8546 40 25.8546C42.9832 25.8546 45.8517 26.7927 48.23 28.4951H46.3461C45.558 28.4951 44.9188 29.1339 44.9188 29.9224C44.9188 30.7105 45.558 31.3494 46.3461 31.3494H51.8408C52.629 31.3494 53.2678 30.7105 53.2678 29.9224V24.4273C53.2678 23.6389 52.6293 23 51.8408 23C51.0524 23 50.4135 23.6389 50.4135 24.4273V26.5642C47.4582 24.2719 43.8073 23 40 23C35.4593 23 31.1899 24.7683 27.9793 27.9793C24.768 31.1903 23 35.4593 23 40C23 44.5407 24.768 48.8101 27.9793 52.0207C31.1899 55.232 35.4593 57 40 57C44.5404 57 48.8097 55.232 52.0204 52.0207C55.2317 48.8101 57 44.5407 57 40C57 39.2119 56.3608 38.573 55.5727 38.573Z" fill="black" />
            </svg>

          </div>

          <div className="modal-title">Do you really want to<br />reset password this users?</div>
          <div className="modal-text">The user will receive a password recovery link to his email</div>
          <div className="modal-action">
            <div className="modal__cancel" onClick={handleCloseReset}>Cancel</div>
            <div className="modal__send" onClick={(e) => {


              handleCloseReset(e);

            }} >Send code</div>
          </div>

        </Box>
      </Modal>
    </Box>


  );
}

export default SideBar;
