import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Skeleton from '@mui/material/Skeleton';
import SelectCustom from '../../../../components/selectCustom/SelectCustom';
import ModalPromotion from '../modal-promotion/ModalPromotion';
import { useQuery } from 'react-query'
import Cookies from 'js-cookie';
import axios from 'axios';
import config from '../../../../config';

const fetchUserData = async (activeUserId, selectedSort) => {
    if (!activeUserId) {
        return null;
    }
    const url = `${config.apiBaseUrl}admins/facebook-ads`;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('authToken')}`,
    };
    const skip = 1 * 20 - 20;
    const params = {
        skip: skip,
        limit: 250,
        filter: JSON.stringify({ userId: activeUserId }),
        sort: JSON.stringify(selectedSort)

    }
    const response = await axios.get(url, {
        headers,
        params
    });
    return response.data;
};


export default function OnHistory({ activeUserId, invalidStatuses }) {
    const [loading, setLoading] = useState(false);

    function createData(email, promationImg, promationPrimaryText, promationHeadlineText, promationSite, promationBudget, promationDailyBudget, promationDuration, promationLocation, promationDate, promationStatus, promationId, promationAction, index, promationRejectTitle, promationRejectDescription, promationRejectedAt, adminEmail, userId, leadFormUrl, promationTime, promationAds, promationData) {
        return { email, promationImg, promationPrimaryText, promationHeadlineText, promationSite, promationBudget, promationDailyBudget, promationDuration, promationLocation, promationDate, promationStatus, promationId, promationAction, index, promationRejectTitle, promationRejectDescription, promationRejectedAt, adminEmail, userId, leadFormUrl, promationTime, promationAds, promationData };
    }


    const [rowsModeration, setRowsModeration] = useState([
        createData(
            'jamesmaddison@gmail.com',
            'creative3.jpg',
            "Elegant Gemstone Bracelet - Your Path to True Beauty!",
            "Discover your unique style with our gemstone bracelets. A gift of sophistication. Fast delivery, guaranteed satisfaction!",
            "https://test2212.zee.am/",
            1,
            1,
            "5 days",
            "New York",
            "12.02.2024",
            "APPROVED",
            "ssadsa",
            "sadsa",
            0,
            "sadsa"
        ),
    ]);

    const sorts = [
        { value: 'createdAt', label: 'Newest' },
        { value: 'createdAt1', label: 'Oldest' },
    ];

    const defaultValue = { value: 'createdAt', label: 'Newest' };
    const [selectedSort, setSelectedSort] = useState({ [defaultValue.value]: "desc" });
    const handleSelectChange = (selectedOption) => {
        if (selectedOption.value == 'createdAt1') {
            setSelectedSort({ ["createdAt"]: "asc" })
        } else {
            setSelectedSort({ ["createdAt"]: "desc" })
        }
    };

    const [openBlock, setOpenBlock] = useState(false);

    const [blockIndex, setBlockIndex] = useState(0);

    const handleOpenBlock = (index) => {
        setOpenBlock(true);
        setBlockIndex(index)
    };

    const { isLoading: isLoading, error: error, data: promotionData, refetch } = useQuery(['promotionOnHistory', activeUserId, selectedSort], () => fetchUserData(activeUserId, selectedSort));


    useEffect(() => {

        if (isLoading) {
            // setRows(rowsLoading);
            // setShowBlock(true);
        }

        if (!isLoading && !error && promotionData && promotionData.data.items) {


            const data = promotionData.data.items
                .filter(ads => invalidStatuses.includes(ads.status))
                .map((ads, index) => {
                    const inputDateString = ads.createdAt;
                    const date = new Date(inputDateString);
                    const day = date.getUTCDate();
                    const month = date.getUTCMonth() + 1;
                    const year = date.getUTCFullYear();
                    const formattedDate = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`;
                    let hours = date.getHours();
                    let minutes = date.getMinutes();

                    hours = (hours < 10 ? '0' : '') + hours;
                    minutes = (minutes < 10 ? '0' : '') + minutes;

                    const formattedTime = hours + ':' + minutes;

                    const targetingLocations = ads.body.targetingLocations || [];
                    const adminEmail = ads.admin ? ads.admin.email : "";
                    const promationAds = ads.body.ads || [];

                    return createData(
                        ads.user.email,
                        ads.images,
                        ads.body.primaryText,
                        ads.body.headline,
                        ads.body.websiteUrl,
                        ads.body.budget,
                        ads.body.dailyBudget,
                        ads.body.budget / ads.body.dailyBudget,
                        targetingLocations.map(location => location.name),
                        formattedDate,
                        ads.status,
                        ads.id,
                        ads.body.actionButton,
                        index,
                        ads.rejectTitle,
                        ads.rejectDescription,
                        formatDate(ads.moderatedAt),
                        adminEmail,
                        ads.userId,
                        ads.body.leadFormUrl,
                        formattedTime,
                        promationAds,
                        ads
                    );
                });

            // setRows(data);
            console.log(data)

            setRowsModeration(data)

        }
    }, [isLoading, error, promotionData]);

    const formatDateString = (inputDateString) => {
        const date = new Date(inputDateString);
        const day = date.getUTCDate();
        const month = date.getUTCMonth() + 1;
        const year = date.getUTCFullYear();
        const formattedDate = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year}`;
        return formattedDate;
    };
    const formatDate = (timestamp) => {
        const date = new Date(timestamp);
        const day = date.getUTCDate();
        const month = date.getUTCMonth() + 1;
        const year = date.getUTCFullYear();
        const hours = date.getUTCHours();
        const minutes = date.getUTCMinutes();

        const formattedDate = `${day < 10 ? '0' : ''}${day}.${month < 10 ? '0' : ''}${month}.${year} at ${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}`;

        return formattedDate;
    };

    return (
        <div className="table" style={{ marginBottom: '40rem' }}>
            <div className="table-top">
                <div className="sidebars-tab__title">History</div>
                <div className="table-top-right">
                    <SelectCustom sorts={sorts} onSelectChange={handleSelectChange} defaultValue={defaultValue} />
                </div>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Status</TableCell>
                            <TableCell>Moderator</TableCell>
                            <TableCell>Date & time</TableCell>
                            <TableCell>Creatives</TableCell>
                            <TableCell>Website link</TableCell>
                            <TableCell>Headline text</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowsModeration.map((row, index) => (
                            row && (
                                <TableRow
                                    className='table-row'
                                    onClick={() => handleOpenBlock(index)}
                                >
                                    <TableCell>
                                        {loading ? (
                                            <Skeleton variant="rounded" width={80} height={17.6} style={{ marginBottom: '0' }} />
                                        ) : (
                                            <>
                                                {row.promationStatus == 'APPROVED' && (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className="icon-green"></div><span>Approved</span>
                                                    </div>
                                                )}

                                                {row.promationStatus == 'REJECTED' && (
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className="icon-red"></div><span>Rejected</span>
                                                    </div>
                                                )}

                                            </>

                                        )}

                                    </TableCell>
                                    <TableCell >
                                        {loading ? (
                                            <Skeleton variant="rounded" width={160} height={17.6} style={{ marginBottom: '0' }} />
                                        ) : (
                                            <span style={{ width: '160rem', display: 'block', overflow: 'hidden', textOverflow: "ellipsis" }}>{row.adminEmail}</span>
                                        )}

                                    </TableCell>
                                    <TableCell >
                                        {loading ? (
                                            <Skeleton variant="rounded" width={160} height={17.6} style={{ marginBottom: '0' }} />
                                        ) : (
                                            <span style={{ width: '160rem', display: 'block', overflow: 'hidden', textOverflow: "ellipsis" }}>{row.promationDate} at {row.promationTime}</span>
                                        )}

                                    </TableCell>
                                    <TableCell onClick={() => setOpen(true)}>
                                        {loading ? (
                                            <Skeleton variant="rounded" width={64} height={64} style={{ marginBottom: '0' }} />
                                        ) : (
                                            <div className="table-product__singleImg" style={{ width: '64rem' }}>

                                                {row.promationAds && (
                                                    row.promationAds.length > 0 ? (
                                                        <>


                                                            {row.promationAds[0] && row.promationAds[0].images && row.promationAds[0].images.length > 0 && (
                                                                <img style={{ width: '64rem', height: '64rem', minWidth: '64rem' }} src={`${row.promationAds[0].images[0].url}`} alt="" />
                                                            )}
                                                            {row.promationAds[0] && row.promationAds[0].videos && row.promationAds[0].videos.length > 0 && (
                                                                <img style={{ width: '64rem', height: '64rem', minWidth: '64rem' }} src={`${row.promationAds[0].videos[0].coverUrl}`} alt="" />
                                                            )}
                                                        </>


                                                    ) : (
                                                        <img style={{ width: '64rem', height: '64rem', minWidth: '64rem' }} src={`${row.promationImg[0]}`} alt="" />
                                                    )
                                                )}
                                            </div>
                                        )}

                                    </TableCell>
                                    <TableCell >
                                        {loading ? (
                                            <Skeleton variant="rounded" width={160} height={17.6} style={{ marginBottom: '0' }} />
                                        ) : (
                                            <span style={{ width: '180rem', display: 'block', overflow: 'hidden', textOverflow: "ellipsis" }}>{row.promationSite}</span>
                                        )}

                                    </TableCell>
                                    <TableCell >
                                        {loading ? (
                                            <Skeleton variant="rounded" width={160} height={17.6} style={{ marginBottom: '0' }} />
                                        ) : (
                                            row.promationAds && (
                                                row.promationAds.length > 0 ? (
                                                    <span style={{ width: '160rem', display: 'block', overflow: 'hidden', textOverflow: "ellipsis" }}>{row.promationAds[0].headline}</span>
                                                ) : (
                                                    <span style={{ width: '160rem', display: 'block', overflow: 'hidden', textOverflow: "ellipsis" }}>{row.promationHeadlineText}</span>
                                                )
                                            )

                                        )}

                                    </TableCell >


                                </TableRow>
                            )

                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ModalPromotion refetch={refetch} openBlock={openBlock} setOpenBlock={setOpenBlock} blockIndex={blockIndex} rowsModeration={rowsModeration} />
        </div>
    )
}
