import React from 'react'
import Skeleton from '@mui/material/Skeleton';
import './style.scss'
import useFormattedDate from '../../../../hooks/useFormattedDate';
import FormattedDate from '../../../../components/formatted-date/FormattedDate';
import Fancybox from '../../../promotionsNew/components/modal-Creatives/fancybox';

function ProductVideos({ data }) {
    const isLoading = false;
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 475,
    };
    return (
        <Fancybox>

      
        <div className="products-videos">
            {data?.adVideos?.map((video, index) => (
                <div className="creatives-item" key={index}>
                    <a data-fancybox="video" href={video.url} className="creatives-item__img">
                        {isLoading ? (
                            <Skeleton variant="rounded" style={{ ...style, width: "100%", padding: 0 }} />
                        ) : (
                            <><img style={{ ...style, width: "100%", padding: 0 }} src={video.coverUrl} />
                                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect x="0.5" y="0.5" width="33" height="33" rx="9.5" fill="white" />
                                    <rect x="0.5" y="0.5" width="33" height="33" rx="9.5" stroke="#F2F4F6" />
                                    <path d="M22.3561 16.1187C23.0594 16.4957 23.0594 17.5043 22.3561 17.8813L14.9725 21.8397C14.3063 22.1969 13.5 21.7143 13.5 20.9584L13.5 13.0416C13.5 12.2857 14.3063 11.8031 14.9725 12.1603L22.3561 16.1187Z" fill="black" />
                                </svg>
                            </>


                        )}

                        <div className="creatives-item__ai creatives-item__ai--finished">
                            <span>
                                <i></i>
                                Finished</span>

                        </div>
                    </a>

                    <div
                    >
                        <div className="creatives-item-info">
                            <div className="creatives-item-top">
                                {isLoading ? (
                                    <Skeleton variant="rounded" width={"100rem"} height={"15rem"} />
                                ) : (
                                    <div className="creatives-item__social">
                                        {video.avatar.name}
                                    </div>
                                )}
                                {isLoading ? (
                                    <Skeleton variant="rounded" width={"80rem"} height={"15rem"} />
                                ) : (
                                    <div className="creatives-item__size">
                                        {video.template.name}
                                    </div>
                                )}
                            </div>
                            {isLoading ? (
                                <Skeleton variant="rounded" style={{ marginBottom: '24rem' }} width="100%" height={15} />
                            ) : (
                                <div className="creatives-item__date">
                                    Creation date: <FormattedDate date={video.createdAt} />
                                </div>
                            )}
                            {isLoading ? (
                                <Skeleton variant="rounded" style={{ marginBottom: '16rem' }} width="100%" height={"42rem"} />
                            ) : (
                                <div className="creatives-item__title">
                                    {video.name}
                                </div>
                            )}

                            {isLoading ? (
                                <Skeleton variant="rounded" style={{ marginBottom: '16rem' }} width="100%" height={"42rem"} />
                            ) : (
                                <div className="creatives-item__duration">
                                    30 sec  ·  9:16
                                </div>
                            )}
                        </div>
                    </div>

                </div>

            ))}
            <div className="creatives-item" >
                <div className="creatives-item__img">
                    {isLoading ? (
                        <Skeleton variant="rounded" style={{ ...style, width: "100%", padding: 0 }} />
                    ) : (
                        <div className="creatives-item__img-failed">
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.5364 5.17398L27.1102 21.929C28.2227 23.8765 26.8164 26.3002 24.5739 26.3002H5.42644C3.18269 26.3002 1.77644 23.8765 2.89019 21.929L12.4639 5.17398C13.5852 3.21023 16.4152 3.21023 17.5364 5.17398Z" fill="white" stroke="#EF2C4F" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M15 16.3996V11.7246M15 20.7809C14.8275 20.7809 14.6875 20.6409 14.6875 20.4684C14.6863 20.2959 14.8263 20.1559 14.9988 20.1559L15.5 20.4995" stroke="#EF2C4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                            Rendering failed
                        </div>
                    )}

                    <div className="creatives-item__ai creatives-item__ai--failed">
                        <span>
                            <i></i>
                            Failed</span>

                    </div>
                </div>

                <div
                >
                    <div className="creatives-item-info">
                        <div className="creatives-item-top">
                            {isLoading ? (
                                <Skeleton variant="rounded" width={"100rem"} height={"15rem"} />
                            ) : (
                                <div className="creatives-item__social">
                                    Samantha
                                </div>
                            )}
                            {isLoading ? (
                                <Skeleton variant="rounded" width={"80rem"} height={"15rem"} />
                            ) : (
                                <div className="creatives-item__size">
                                    Miamy
                                </div>
                            )}
                        </div>
                        {isLoading ? (
                            <Skeleton variant="rounded" style={{ marginBottom: '24rem' }} width="100%" height={15} />
                        ) : (
                            <div className="creatives-item__date">
                                Creation date: 12.10.2023
                            </div>
                        )}
                        {isLoading ? (
                            <Skeleton variant="rounded" style={{ marginBottom: '16rem' }} width="100%" height={"42rem"} />
                        ) : (
                            <div className="creatives-item__title">
                                Lightweight Crepe Skirt
                            </div>
                        )}

                        {isLoading ? (
                            <Skeleton variant="rounded" style={{ marginBottom: '16rem' }} width="100%" height={"42rem"} />
                        ) : (
                            <div className="creatives-item__duration">
                                30 sec  ·  9:16
                            </div>
                        )}
                    </div>
                </div>

            </div>

        </div>
        </Fancybox>
    )
}

export default ProductVideos