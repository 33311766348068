const CloseIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <rect width="24" height="24" rx="12" fill="#F2F4F6" />
            <path d="M7.5 7.5L16.5 16.5" stroke="#A7A8AA" stroke-width="1.5" stroke-linecap="round" />
            <path d="M16.5 7.5L7.5 16.5" stroke="#A7A8AA" stroke-width="1.5" stroke-linecap="round" />
        </svg>
    );
}

export default CloseIcon;
