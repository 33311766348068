import React, { useState, useRef, useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";


import './style.scss'
import PromotionItem from './PromotionItem';
import Snackbar from '../../../../components/snackbar/Snackbar';
gsap.registerPlugin(ScrollTrigger);

const PromotionsPageCreatives = ({ groupPromotions, blockIndex }) => {
    const stickyRef = useRef(null);
    const containerRef = useRef(null);

    const [formattedStatus, setFormattedStatus] = useState('');
    const [totalSpend, setTotalSpend] = useState('');
    const [differenceDays, setDifferenceDays] = useState('');
    const [img, setImg] = useState('');
    const [creatives, setCreatives] = useState('');
    const [primaryText, setPrimaryText] = useState('');
    const [website, setWebsite] = useState('');
    const [startDate, setStartDate] = useState('');
    const [stopDate, setStopDate] = useState('');
    const [budget, setBudget] = useState('');
    const [dailyBudget, setDailyBudget] = useState('');
    const [object, setObject] = useState('');
    const [reach, setReach] = useState('');
    const [impressions, setImpressions] = useState('');
    const [visitors, setVisitors] = useState('');
    const [cpc, setCpc] = useState('');
    const [ctr, setCtr] = useState('');
    const [cpm, setCpm] = useState('');
    const [promotions, setPromotions] = useState([]);
    const [location, setLocation] = useState('');

    useEffect(() => {
        if (groupPromotions && groupPromotions.length) {
            const currentPromotion = groupPromotions[blockIndex];
            const firstPromotion = currentPromotion[0];

            const {
                imageUrl,
                primaryText,
                websiteUrl,
                createdAt,
                endDate,
                effectiveStatus,
                budget,
                objective,

            } = firstPromotion;

            const collectUniqueNames = (data) => {
                const uniqueNames = new Set();
              
                data.forEach(item => {
                  const { geo_locations } = item.targeting;
              
                  if (geo_locations.countries) {
                    geo_locations.countries.forEach(country => uniqueNames.add(country));
                  }
              
                  if (geo_locations.regions) {
                    geo_locations.regions.forEach(region => uniqueNames.add(region.name));
                  }
              
                  if (geo_locations.cities) {
                    geo_locations.cities.forEach(city => uniqueNames.add(city.name));
                  }
                });
              
                return Array.from(uniqueNames).join(', ');
              };

            const uniqueNames = collectUniqueNames(currentPromotion);
            setLocation(uniqueNames)
            const status = effectiveStatus?.toLowerCase() || '';
            const formattedStatus = status.charAt(0).toUpperCase() + status.slice(1);
            const totalSpend = currentPromotion.reduce((acc, cur) => acc + cur.spend, 0).toFixed(2);
            const startDate = new Date(createdAt);
            const stopDate = new Date(endDate);
            const differenceMs = Math.abs(stopDate - startDate);
            const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24)) - 1;
            const dailyBudget = differenceDays !== 0 ? (budget / differenceDays).toFixed(2) : budget;
            const formattedObjective = objective.charAt(0).toUpperCase() + objective.slice(1).replace('_', ' ');

            setFormattedStatus(formattedStatus);
            setTotalSpend(totalSpend);
            setDifferenceDays(differenceDays.toString());
            setImg(imageUrl);
            setCreatives(currentPromotion.length.toString());
            setPrimaryText(primaryText);
            setWebsite(websiteUrl);
            setStartDate(formatDate(createdAt));
            setStopDate(formatDate(endDate));
            setBudget(budget);
            setDailyBudget(dailyBudget);
            setObject(formattedObjective);
            setReach(currentPromotion.reduce((acc, cur) => acc + cur.reach, 0).toString());
            setImpressions(currentPromotion.reduce((acc, cur) => acc + cur.impressions, 0).toString());
            setVisitors(currentPromotion.reduce((acc, cur) => acc + cur.visitors, 0).toString());
            setCpc(currentPromotion.reduce((acc, cur) => acc + cur.cpc, 0).toFixed(2));
            setCtr((currentPromotion.reduce((acc, cur) => acc + cur.ctr, 0).toFixed(2)) / currentPromotion.length);
            setCpm(currentPromotion.reduce((acc, cur) => acc + cur.cpm, 0).toFixed(2));
            setPromotions(currentPromotion);

        }
    }, [blockIndex, groupPromotions]);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('ru-RU', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        }).format(date).replace(',', ' at');
    };
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarText, setSnackbarText] = useState('Advertisement successfully published');
    const [snackbarCount, setSnackbarCount] = useState(3);

    const handleCopyClick = (value, text) => {
        navigator.clipboard.writeText(Object.values(value)[0])
            .then(() => {
                setSnackbarText(`${text} was copied`);
                setSnackbarCount(3)
                setSnackbarOpen(true);
            })
            .catch((error) => {
                console.error('Copy failed: ', error);
            });
    };


    return (
        <>
            <div className="promotionsPage promotionsPageNew" ref={containerRef}>
                <div className="promotionsPageNew-top" ref={stickyRef}>
                    <div className="userInfo-block-item" style={{ width: '60rem' }}>
                        <span>General</span>

                        <div className="promotionsNew-AllPromotions-img" style={{ width: "60rem", height: '60rem' }}>
                            <img src={img} alt="" />
                            <img src={process.env.PUBLIC_URL + `/img/pimg.png`} alt="" />
                            <span>{creatives}</span>
                        </div>
                    </div>
                    <div className="userInfo-block-item" style={{ width: '250rem' }}>
                        <span>  ㅤ </span>
                        <div className="promotionsNew-AllPromotions-info" >
                            <p>{primaryText}</p>

                            <div className="promotionsNew-AllPromotions-info-bottom">
                                <div className={`promotionsNew-AllPromotions-status promotionsNew-AllPromotions-status--Active`}>
                                    <span></span>{formattedStatus}
                                </div>
                                <div className="promotionsNew-AllPromotions-website">
                                    {website}
                                </div>
                                <div className="table-tooltip-info">

                                    {website}
                                    <div className="table-tooltip-info-open table-tooltip-info__btn"
                                        onClick={() => handleCopyClick({ website }, 'Website link')}
                                    >
                                        Copy
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="userInfo-block-item" style={{ width: '205rem' }}>
                        <span>Start date & end date</span>
                        <div style={{ height: '60rem', display: "flex", alignItems: 'center' }}>
                            <div className="promotionsNew-AllPromotions-date" >
                                from {startDate} <br />to {stopDate}
                            </div>
                        </div>
                    </div>
                    <div className="userInfo-block-item" style={{ width: '80rem' }}>
                        <span>Duration</span>
                        <div style={{ height: '60rem', display: "flex", alignItems: 'center' }}>
                            <p>{differenceDays} days</p>
                        </div>
                    </div>
                    <div className="userInfo-block-item" >
                        <span>Total spent</span>
                        <div style={{ height: '60rem', display: "flex", alignItems: 'center' }}>
                            <p>${totalSpend}</p>
                        </div>
                    </div>
                    <div className="userInfo-block-item" >
                        <span>Budget </span>
                        <div style={{ height: '60rem', display: "flex", alignItems: 'center' }}>
                            <p>${budget} (${dailyBudget}/day)</p>
                        </div>
                    </div>
                    <div className="userInfo-block-item" style={{ width: '110rem' }}>
                        <span>Object</span>
                        <div style={{ height: '60rem', display: "flex", alignItems: 'center' }}>
                            <p>{object}</p>
                        </div>
                    </div>
                    <div className="userInfo-block-item userInfo-block-item--location" style={{ width: '220rem' }}>
                        <span>User email</span>
                        <div style={{ height: '60rem', display: "flex", alignItems: 'center' }}>
                            {/* <p>Cnikolas12@gmail.com</p> */}
                        </div>
                    </div>
                    <div className="userInfo-block-item userInfo-block-item--location" style={{ width: '222rem' }}>
                        <span>Locations</span>
                        <div style={{ height: '60rem', display: "flex", alignItems: 'center' }}>
                            <p>{location}</p>
                            <div className="table-tooltip-info">
                                   
                                   <span>{location}</span>
                                   

                                   <div className="table-tooltip-info-copy table-tooltip-info__btn"
                                       onClick={() => handleCopyClick({location}, 'Locations')}
                                   >
                                       Copy
                                   </div>
                               </div>
                        </div>
                    </div>
                </div>

                <div className="promotionsNew-AllPromotions-content">
                    <div className="promotionsNew-AllPromotions-content-info">
                        <div className="promotionsNew-AllPromotions-content-item">
                            <span>Reach</span>
                            <strong>{reach.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong>
                            <p>Unique viewers</p>
                        </div>
                        <div className="promotionsNew-AllPromotions-content-item">
                            <span>Impressions</span>
                            <strong>{impressions.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong>
                            <p>Total views</p>
                        </div>
                        <div className="promotionsNew-AllPromotions-content-item">
                            <span>Unique clicks</span>
                            <strong>{visitors.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</strong>
                            <p>Link clicks</p>
                        </div>
                        <div className="promotionsNew-AllPromotions-content-item">
                            <span>Cost per click</span>
                            <strong>${cpc}</strong>
                            <p>Per impression</p>
                        </div>
                        <div className="promotionsNew-AllPromotions-content-item">
                            <span>CTR</span>
                            <strong>{parseFloat(ctr).toFixed(2)}%</strong>
                            <p>Click-through rate</p>
                        </div>
                        <div className="promotionsNew-AllPromotions-content-item">
                            <span>Cost per mile</span>
                            <strong>${cpm}</strong>
                            <p>Per 1k impressions</p>
                        </div>
                    </div>
                    <div className="promotionsNew-AllPromotions-creatives-title">Creatives</div>


                    <div className="promotionsNew-AllPromotions-creatives">

                        {promotions.map((promotion, index) => (
                            <PromotionItem key={index} promotion={promotion} />
                        ))}
                    </div>
                </div>
            </div >
            {snackbarOpen && <Snackbar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarText={snackbarText} snackbarCount={snackbarCount} setSnackbarCount={setSnackbarCount} />}
        </>
    );
}

export default PromotionsPageCreatives;
