import React, { useEffect, useState } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import Login from "./pages/login/Login";
import Logs from "./pages/logs/Logs";
import Newsletters from "./pages/newsletters/Newsletters";
import Users from "./pages/users/Users";
import './styles/app.scss';
import Analytics from "./pages/analytics/Analytics";
import Cookies from 'js-cookie';
import Cohorts from "./pages/analytics/Cohorts";
import ProductsAnalytics from "./pages/analytics/ProductsAnalytics";
import Platforms from "./pages/analytics/Platforms";
import { QueryClient, QueryClientProvider } from 'react-query'
import PromotionsPage from "./pages/promotionsPage/PromotionsPage";
import PromotionsPageAll from "./pages/promotionsPage/PromotionsPageAll";
import Admins from './pages/admins/Admins';
import AiVideoScriptGeneration from './pages/aiPrompts/AiVideoScriptGeneration';
import { AiAdsGeneration } from './pages/aiPrompts/AiAdsGeneration';
import AiPrompts from './pages/aiPrompts/AiPrompts';
import { AiCreativeGeneration } from './pages/aiPrompts/AiCreativeGeneration';
import axios from 'axios';
import config from './config';
import useCookie from './hooks/useCookie.hooks';
import PromotionsPageAllNew from './pages/promotionsPageNew/PromotionsPageAll';


const client = new QueryClient();

function App() {
  function ProtectedRoute({ element, path }) {
    const authToken = Cookies.get('authToken');
    const refreshToken = Cookies.get('refreshToken');
    const role = Cookies.get('role');



    if (role === 'support') {
      if (path !== 'users') {
        return <Navigate to="/" replace />;
      }
    }

    if (role === 'ad_manager') {
      const validPaths = [
        'users',
        'promotions',
        'promotions/moderation',
        'promotions/deffered',
        'promotions/rejected',
        'promotions/approved',
        'promotions/all'
      ];
      if (!validPaths.includes(path)) {
        return <Navigate to="/" replace />;
      }
    }

    if (role === 'ai_prompt_engineer') {
      const validPaths = [
        'ai/ai-prompts',
        'ai/ads',
        'ai/creatives',
        'ai/video-script'
      ];
      if (!validPaths.includes(path)) {
        return <Navigate to="/" replace />;
      }
    }


    if (!authToken) {
      if (refreshToken) {
        getRefreshToken()
      } else {
        return <Navigate to="/" replace />;
      }
    }

    return element;
  }


  const [promationsPageTotal, setPromationsPageTotal] = useState();
  const role = Cookies.get('role');


  const authToken = useCookie('authToken');

  useEffect(() => {
    const refreshToken = Cookies.get('refreshToken');
    if (authToken) {
      if (!authToken) {
        if (refreshToken) {
          getRefreshToken()
        } else {
          return <Navigate to="/" replace />;
        }
      }
    }
  }, [authToken]);
  

  const getRefreshToken = () => {
    const refreshToken = Cookies.get('refreshToken');
    const axiosConfig = {
      method: 'post',
      url: `${config.apiBaseUrl}admins/auth/refresh`,
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        refreshToken: refreshToken
      }
    };
    axios(axiosConfig)
      .then(response => {
        const token = response.data.accessToken
        const refreshToken = response.data.refreshToken
        Cookies.set('authToken', token, { expires: 1/48});
        Cookies.set('refreshToken', refreshToken, { expires: 1 });
      })
      .catch(error => {
        console.error('Error:', error);
      });

  }


  return (
    <div className="App">
      <QueryClientProvider client={client}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="users" element={<ProtectedRoute element={<Users />} path='users' />} />
            <Route path="promotions" element={<ProtectedRoute path='promotions' element={<PromotionsPage setPromationsPageTotal={setPromationsPageTotal} />} />} />
            <Route path="promotions/moderation" element={<ProtectedRoute path='promotions/moderation' element={<PromotionsPage setPromationsPageTotal={setPromationsPageTotal} />} />} />
            <Route path="promotions/deffered" element={<ProtectedRoute path='promotions/deffered' element={<PromotionsPageAll promationsPageTotal={promationsPageTotal} status={"deffered"} />} />} />
            <Route path="promotions/rejected" element={<ProtectedRoute path='promotions/rejected' element={<PromotionsPageAll promationsPageTotal={promationsPageTotal} status={"rejected"} />} />} />
            <Route path="promotions/approved" element={<ProtectedRoute path='promotions/approved' element={<PromotionsPageAll promationsPageTotal={promationsPageTotal} status={"approved"} />} />} />
            <Route path="promotions/all" element={<ProtectedRoute path='promotions/all' element={<PromotionsPageAll promationsPageTotal={promationsPageTotal} status={"all"} />} />} />
            <Route path="promotions-history" element={<ProtectedRoute path='promotions-history' element={<PromotionsPageAllNew promationsPageTotal={promationsPageTotal} status={"all"} />} />} />
            <Route path="ai/ai-prompts" element={<ProtectedRoute path='ai/ai-prompts' element={<AiPrompts />} />} />
            <Route path="ai/ads" element={<ProtectedRoute path='ai/ads' element={<AiAdsGeneration />} />} />
            <Route path="ai/creatives" element={<ProtectedRoute path='ai/creatives' element={<AiCreativeGeneration />} />} />
            <Route path="ai/video-script" element={<ProtectedRoute path='ai/video-script' element={<AiVideoScriptGeneration />} />} />
            <Route path="admins" element={<ProtectedRoute path='admins' element={<Admins promationsPageTotal={promationsPageTotal} status={"all"} />} />} />
            <Route path="logs" element={<ProtectedRoute path='logs' element={<Logs />} />} />

            {/* <Route path="newsletters" element={<ProtectedRoute element={<Newsletters />} />} /> */}

            {/* <Route path="analytics" element={<ProtectedRoute element={<Analytics />} />} />
            <Route path="analytics/overview" element={<ProtectedRoute element={<Analytics />} />} />
            <Route path="analytics/cohorts" element={<ProtectedRoute element={<Cohorts />} />} />
            <Route path="analytics/products" element={<ProtectedRoute element={<ProductsAnalytics />} />} />
            <Route path="analytics/platforms" element={<ProtectedRoute element={<Platforms />} />} /> */}
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </div>
  );
}

export default App;
