import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';

const useCookie = (cookieName) => {
  const [cookieValue, setCookieValue] = useState(Cookies.get(cookieName));

  useEffect(() => {
    const handleCookieChange = () => {
      setCookieValue(Cookies.get(cookieName));
    };

    // Set up an interval to check for cookie changes
    const intervalId = setInterval(handleCookieChange, 1000);

    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, [cookieName]);

  return cookieValue;
};

export default useCookie;
