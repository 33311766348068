import React, { useState } from 'react'
import { Box, Modal } from '@mui/material';
import PromotionsPageBlockNew from '../../../promotionsPage/PromotionsPageBlockNew';

export default function ModalPromotion({ blockIndex, openBlock, setOpenBlock, rowsModeration, refetch }) {

    const styleBlock = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "1720rem",
        outline: 'none',
        maxHeight: '90vh',
        height: "90vh",
        overflow: 'hidden',
        borderRadius: '16rem'
    };


    const handleCloseBlock = () => {
        setOpenBlock(false);
    };

    // const rows = [{
    //     "email": "zach@zgncreative.com",
    //     "promationImg": [
    //         null
    //     ],
    //     "promationSite": "https://collectorcreative.com/products/air-jordan-1-sneaker-series-premium",
    //     "promationBudget": 75,
    //     "promationDailyBudget": 15,
    //     "promationDuration": 5,
    //     "promationLocation": [
    //         "Chicago",
    //         "California"
    //     ],
    //     "promationDate": "05.07.2024",
    //     "promationStatus": "PENDING",
    //     "promationId": "6c0c37ae-9fdb-4535-a1ad-194c796f3e10",
    //     "index": 1,
    //     "promationRejectTitle": "The advertisement was rejected because it featured a well-known brand.",
    //     "promationRejectDescription": "The advertisement was rejected because it featured a well-known brand.",
    //     "promationRejectedAt": "05.07.2024 at 07:30",
    //     "adminEmail": "emily@zeely.app",
    //     "userId": "e009cc28-1e5c-40f3-93ed-c6a278d5a635",
    //     "promationTime": "10:26",
    //     "promationAds": [
    //         {
    //             "primaryText": "Hurry, while supplies last - only 100 total copies ever made! Premium quality, Original Card Art!",
    //             "headline": "Sneaker Series: Air Jordan 1",
    //             "imageUrl": "https://user-creatives.s3.amazonaws.com/e009cc28-1e5c-40f3-93ed-c6a278d5a635/5bf825a3-0d33-4bfe-969b-64c15b114a86/6a17ee18-4f39-4af5-8416-f66711ab7679.png",
    //             "actionButton": "SHOP_NOW",
    //             "images": [
    //                 {
    //                     "url": "https://user-creatives.s3.amazonaws.com/e009cc28-1e5c-40f3-93ed-c6a278d5a635/5bf825a3-0d33-4bfe-969b-64c15b114a86/6a17ee18-4f39-4af5-8416-f66711ab7679.png",
    //                     "width": 1080,
    //                     "height": 1080
    //                 },
    //                 {
    //                     "url": "https://user-creatives.s3.amazonaws.com/e009cc28-1e5c-40f3-93ed-c6a278d5a635/5bf825a3-0d33-4bfe-969b-64c15b114a86/991a488e-3f1a-4368-93b2-f1b2def33dcb.png",
    //                     "width": 1080,
    //                     "height": 1350
    //                 },
    //                 {
    //                     "url": "https://user-creatives.s3.amazonaws.com/e009cc28-1e5c-40f3-93ed-c6a278d5a635/5bf825a3-0d33-4bfe-969b-64c15b114a86/254aa562-e817-4a02-8e65-7f51d407b3e1.png",
    //                     "width": 1080,
    //                     "height": 1920
    //                 }
    //             ],
    //             "leadFormUrl": "https://collectorcreative-2973.zee.am/cid4603"
    //         },
    //         {
    //             "primaryText": "JUST DROPPED - Sneaker Series: Air Jordan 1.  \n\n100 copies ever made. Original Card Art  trading card.\n\nDon’t miss your chance to own this limited edition collectible before they sell out!",
    //             "headline": "Premium Collectible",
    //             "imageUrl": "https://user-creatives.s3.amazonaws.com/e009cc28-1e5c-40f3-93ed-c6a278d5a635/5bf825a3-0d33-4bfe-969b-64c15b114a86/6a17ee18-4f39-4af5-8416-f66711ab7679.png",
    //             "actionButton": "ORDER_NOW",
    //             "images": [
    //                 {
    //                     "url": "https://user-creatives.s3.amazonaws.com/e009cc28-1e5c-40f3-93ed-c6a278d5a635/5bf825a3-0d33-4bfe-969b-64c15b114a86/6a17ee18-4f39-4af5-8416-f66711ab7679.png",
    //                     "width": 1080,
    //                     "height": 1080
    //                 },
    //                 {
    //                     "url": "https://user-creatives.s3.amazonaws.com/e009cc28-1e5c-40f3-93ed-c6a278d5a635/5bf825a3-0d33-4bfe-969b-64c15b114a86/991a488e-3f1a-4368-93b2-f1b2def33dcb.png",
    //                     "width": 1080,
    //                     "height": 1350
    //                 },
    //                 {
    //                     "url": "https://user-creatives.s3.amazonaws.com/e009cc28-1e5c-40f3-93ed-c6a278d5a635/5bf825a3-0d33-4bfe-969b-64c15b114a86/254aa562-e817-4a02-8e65-7f51d407b3e1.png",
    //                     "width": 1080,
    //                     "height": 1920
    //                 }
    //             ],
    //             "leadFormUrl": "https://collectorcreative-2973.zee.am/cid1586"
    //         },
    //         {
    //             "primaryText": "Own a piece of history with the first ever released Original Art Card.  Our Sneaker Series: Air Jordan 1  pays tribute to the most iconic sneakers of all time.  A must have for any MJ collector!\n\nGrab yours today, before they sell out!",
    //             "headline": "Start Collecting!",
    //             "imageUrl": "https://user-creatives.s3.amazonaws.com/e009cc28-1e5c-40f3-93ed-c6a278d5a635/00e28b3b-e798-4f48-aae9-79072191997d/54d9eb77-71ce-4415-be3d-636a42ac0ef9.png",
    //             "actionButton": "SHOP_NOW",
    //             "images": [
    //                 {
    //                     "url": "https://user-creatives.s3.amazonaws.com/e009cc28-1e5c-40f3-93ed-c6a278d5a635/00e28b3b-e798-4f48-aae9-79072191997d/54d9eb77-71ce-4415-be3d-636a42ac0ef9.png",
    //                     "width": 1080,
    //                     "height": 1080
    //                 },
    //                 {
    //                     "url": "https://user-creatives.s3.amazonaws.com/e009cc28-1e5c-40f3-93ed-c6a278d5a635/00e28b3b-e798-4f48-aae9-79072191997d/89f2a4ca-7977-47b1-97cb-69da3923f742.png",
    //                     "width": 1080,
    //                     "height": 1350
    //                 },
    //                 {
    //                     "url": "https://user-creatives.s3.amazonaws.com/e009cc28-1e5c-40f3-93ed-c6a278d5a635/00e28b3b-e798-4f48-aae9-79072191997d/1025c73f-cdda-4653-a171-6d63d339158c.png",
    //                     "width": 1080,
    //                     "height": 1920
    //                 }
    //             ],
    //             "leadFormUrl": "https://collectorcreative-2973.zee.am/cid8207"
    //         }
    //     ]
    // }]

    const rows = rowsModeration 

    return (
        <Modal
            keepMounted
            open={openBlock}
            onClose={handleCloseBlock}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            className="modal"
        >
            <Box sx={styleBlock} className="modal-container">
                <div className="creatives-modal-close" onClick={handleCloseBlock}>
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M10 10L26 26" stroke="white" stroke-width="2" stroke-linecap="round" />
                            <path d="M26 10L10 26" stroke="white" stroke-width="2" stroke-linecap="round" />
                        </svg>

                    </div>
                {rows.length && (
                    <PromotionsPageBlockNew
                        refetch={refetch}
                        blockStatus={rows[blockIndex]?.promationStatus || ''}
                        email={rows[blockIndex]?.email || ''}
                        promationImg={rows[blockIndex]?.promationImg || ''}
                        promationSite={rows[blockIndex]?.promationSite || ''}
                        promationDate={rows[blockIndex]?.promationDate || ''}
                        promationBudget={rows[blockIndex]?.promationBudget || ''}
                        promationDailyBudget={rows[blockIndex]?.promationDailyBudget || ''}
                        promationDuration={rows[blockIndex]?.promationDuration || ''}
                        promationAction={rows[blockIndex]?.promationAction || ''}
                        promationLocation={rows[blockIndex]?.promationLocation || ''}
                        promationPrimaryText={rows[blockIndex]?.promationPrimaryText || ''}
                        promationHeadlineText={rows[blockIndex]?.promationHeadlineText || ''}
                        promationId={rows[blockIndex]?.promationId || ''}
                        promationRejectTitle={rows[blockIndex]?.promationRejectTitle || ''}
                        promationRejectDescription={rows[blockIndex]?.promationRejectDescription || ''}
                        promationRejectedAt={rows[blockIndex]?.promationRejectedAt || ''}
                        adminEmail={rows[blockIndex]?.adminEmail || ''}
                        activeUserId={rows[blockIndex]?.userId || ''}
                        leadFormUrl={rows[blockIndex]?.leadFormUrl || ''}
                        promationTime={rows[blockIndex]?.promationTime || ''}
                        promationAds={rows[blockIndex]?.promationAds || ''}
                        promationData={rows[blockIndex]?.promationData || ''}
                        
                    />
                )}

            </Box>
        </Modal>
    )
}
