const Tick = () => {
    return (
        <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.66016 7.04723L1.29726 4.68433C1.12292 4.50999 0.840253 4.50999 0.665912 4.68433C0.491571 4.85867 0.491571 5.14133 0.665912 5.31567L3.34448 7.99424C3.51883 8.16859 3.80149 8.16859 3.97583 7.99424L9.33297 2.6371C9.50731 2.46276 9.50731 2.1801 9.33297 2.00576C9.15863 1.83141 8.87597 1.83141 8.70163 2.00576L3.66016 7.04723Z" fill="black" stroke="black" stroke-width="0.78125" />
        </svg>

    );
}

export default Tick;
