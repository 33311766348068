import React, { useState } from 'react'
import { Box, Modal } from '@mui/material';
import './style.scss'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBack from '../../../../svg/ArrowBack';
import ArrowNext from '../../../../svg/ArrowNext';
import VideoImages from '../video-images/VideoImages';
import Snackbar from '../../../../components/snackbar/Snackbar';
import useFormattedDate from '../../../../hooks/useFormattedDate';
import FormattedDate from '../../../../components/formatted-date/FormattedDate';

export default function ModalVideos({ openBlock, setOpenBlock, data }) {

    const styleBlock = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "95%",
        outline: 'none',
        maxHeight: '90vh',
        height: "95vh",
        // overflow: 'hidden',
        borderRadius: '16rem'
    };

    const handleCloseBlock = () => {
        setOpenBlock(false);
    };
    const [activeTab, setActiveTab] = useState('Images');

    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarText, setSnackbarText] = useState('Advertisement successfully published');
    const [snackbarCount, setSnackbarCount] = useState(3);

    const handleCopyClick = (value, text) => {
        navigator.clipboard.writeText(Object.values(value)[0])
            .then(() => {
                setSnackbarText(`${text} was copied`);
                setSnackbarCount(3)
                setSnackbarOpen(true);
            })
            .catch((error) => {
                console.error('Copy failed: ', error);
            });
    };

    return (
        <Modal
            keepMounted
            open={openBlock}
            onClose={handleCloseBlock}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
            className="modal"
        >
            <Box sx={styleBlock} className="modal-container">
                <div className="creatives-modal-close" onClick={handleCloseBlock}>
                    <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10 10L26 26" stroke="white" stroke-width="2" stroke-linecap="round" />
                        <path d="M26 10L10 26" stroke="white" stroke-width="2" stroke-linecap="round" />
                    </svg>

                </div>
                <div style={{ overflow: "hidden", width: '100%' }}>

                    <div className="videos-modal">
                        <div className="videos-modal-header">
                            <div className="videos-modal-header__title">Video sourses
                            </div>
                            <div className="videos-modal-header__title">Product  info</div>
                        </div>
                        <div className="videos-modal-body">

                            <div className="videos-modal-content">
                                <VideoImages data={data} />
                            </div>
                            <div className="videos-modal-info">
                                <div className="videos-modal-info-top">
                                    <div className="videos-modal-info-wrap videos-modal-info-wrap--3">
                                        <div className="videos-modal-info-item">
                                            <span>Name</span>
                                            <p>{data?.data?.productName}</p>
                                        </div>

                                        <div className="videos-modal-info-item">
                                            <span>Objective</span>
                                            <p>{data?.objective}</p>

                                        </div>
                                        <div className="videos-modal-info-item">
                                            <span>Create date</span>

                                            {data?.createdAt && (
                                                <p>  <FormattedDate date={data?.createdAt} /></p>
                                            )}
                                        </div>

                                    </div>

                                    <div className="videos-modal-info-item">
                                        <span>Description</span>
                                        <p>{data?.data?.productDescription}</p>
                                    </div>
                                    <div className="videos-modal-info-item">
                                        <span>Product link</span>
                                        <p>{data?.data?.productUrl}</p>
                                    </div>
                                    <div className="videos-modal-info-wrap videos-modal-info-wrap--5">
                                        <div className="videos-modal-info-item">
                                            <span>Video template</span>
                                            <p>New York</p>
                                        </div>

                                        <div className="videos-modal-info-item">
                                            <span>Ai Avatar </span>
                                            <p>Tim</p>

                                        </div>
                                        <div className="videos-modal-info-item">
                                            <span>Aspect ratio</span>
                                            <p>9:16</p>
                                        </div>
                                        <div className="videos-modal-info-item">
                                            <span>Duration</span>
                                            <p>{data?.avatarDuration?.toFixed()} sec</p>
                                        </div>
                                        <div className="videos-modal-info-item">
                                            <span>Music</span>
                                            <p>I get you want</p>
                                        </div>
                                    </div>
                                    <div className="videos-modal-info-item">
                                        <span>Videoscript</span>
                                        <p>{data?.data?.script}</p>
                                    </div>
                                </div>

                                <div className="videos-modal-info-bottom">
                                    <div className="videos-modal-info-link">
                                        <div>
                                            <h4>Video link</h4>
                                            <a href={data?.url}>
                                                {data?.url}
                                            </a>
                                        </div>
                                        <div className="btn"
                                            onClick={() => handleCopyClick(data?.url, 'Video link')}
                                        >Copy</div>

                                    </div>
                                    <div className="modal-action">
                                        <div className="modal__cancel"
                                        // onClick={
                                        //     handleCloseReset

                                        // }
                                        >Cancel</div>
                                        <div className="modal__send"
                                        // onClick={(e) => {

                                        //   resetPassword(e)
                                        // }} 
                                        >Save</div>
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>
                </div>
                {snackbarOpen && <Snackbar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarText={snackbarText} snackbarCount={snackbarCount} setSnackbarCount={setSnackbarCount} />}
            </Box>
        </Modal>
    )
}
