import "./navbar.scss";
import { NavLink } from "react-router-dom";
import Cookies from 'js-cookie';
const Navbar = () => {
    const role = Cookies.get('role');
    return (
        <div className="navbar">
            <ul>

                {role === 'admin' && (
                    <>

                        <li className="active">
                            <NavLink to="/users" activeClassName="active">
                                Users
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/promotions' activeClassName="active">
                                Promotions
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/promotions-history' activeClassName="active">
                            Promotions History
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/ai/ai-prompts' activeClassName="active">
                                AiPromps
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/admins' activeClassName="active">
                                Admins
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink to='/newsletters' activeClassName="active">
                                Newsletters
                            </NavLink>
                        </li> */}
                        <li>
                            <NavLink to='/logs' activeClassName="active">
                                Logs
                            </NavLink>
                        </li>
                        {/* <li>
                            <NavLink to='/analytics' activeClassName="active">
                                Analytics
                            </NavLink>
                        </li> */}
                    </>
                )}
                {role === 'support' && (
                    <>
                        <li className="active">
                            <NavLink to="/users" activeClassName="active">
                                Users
                            </NavLink>
                        </li>

                    </>
                )}
                {role === 'ad_manager' && (
                    <>
                        <li className="active">
                            <NavLink to="/users" activeClassName="active">
                                Users
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to='/promotions' activeClassName="active">
                                Promotions
                            </NavLink>
                        </li>
                    </>
                )}
                {role === 'ai_prompt_engineer' && (
                    <>
                        <li>
                            <NavLink to='/ai/ai-prompts' activeClassName="active">
                                AiPromps
                            </NavLink>
                        </li>
                    </>
                )}

            </ul>
        </div>
    );
}

export default Navbar;
