import React from 'react'
import { NavLink } from "react-router-dom";

export default function AiNavbar() {
    return (
        <div className="aiPrompts-nav">
            <NavLink to="/ai/ai-prompts" activeClassName="active">
                AI Prompts
            </NavLink>
            <NavLink to="/ai/ads" activeClassName="active">
                AI Ads Generation
            </NavLink>

            <NavLink to="/ai/creatives" activeClassName="active">
                AI Creative Generation
            </NavLink>
            <NavLink to="/ai/video-script" activeClassName="active">
                AiVideoScriptGeneration
            </NavLink>

        </div>
    )
}
