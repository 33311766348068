import React, { useState, useEffect } from 'react';
import './userInfo.scss';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import AwesomePhoneNumber from 'awesome-phonenumber';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from 'react-query'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { gsap } from 'gsap';
import config from '../../config';

const fetchUserData = async (activeUserId) => {
    if (!activeUserId) {
        return null;
    }

    const url = `${config.apiBaseUrl}admins/users/${activeUserId}`;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('authToken')}`,
    };

    const response = await axios.get(url, { headers });
    return response.data;
};

const fetchUserSite = async (activeUserId) => {
    if (!activeUserId) {
        return null;
    }

    const url = `${config.apiBaseUrl}admins/users/${activeUserId}/sites`;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('authToken')}`,
    };

    const response = await axios.get(url, { headers });
    return response.data;
};

const fetchUserSubscriptions = async (activeUserId) => {
    if (!activeUserId) {
        return null;
    }

    const url = `${config.apiBaseUrl}admins/subscriptions/${activeUserId}`;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('authToken')}`,
    };

    const response = await axios.get(url, { headers });
    return response.data;
};


const UserInfo = ({ activeUserId, setSnackbarOpen, setSnackbarText, setSnackbarCount, setUserInfoSubscription }) => {

    const [activeBlockId, setActiveBlockId] = useState(null);

    const handleEditClick = (event, id) => {
     
        buttonAnimation(event.target)
        setTimeout(() => {
            setActiveBlockId(id);
        }, 500);
    };


    const [value, setValue] = useState(16);

    const colors = [
        '#F43535',
        '#FFA500',
        '#FFD700',
        '#0073e6',
        '#17CC37',
    ];

    const getColor = (value) => {
        const index = Math.floor((value / 100) * (colors.length - 1));
        return colors[index];
    };

    const circularProgressStyle = {
        color: getColor(value),
    };

    const [dataSubscriptions, setData] = useState([
        {
            id: "2",
            name: 'Growth',
            price: '$79.99',
            payment: 'Apple Pay',
            next: '10.09.2023',
            last: '10.08.2023'
        },
        {
            id: "3",
            name: 'Trial',
            price: '$79.99',
            payment: 'Apple Pay',
            next: '10.09.2023',
            last: '10.08.2023'
        }
    ]);


    const [email, setEmail] = useState("");
    const [website, setWebsite] = useState("");
    const [connectedWebsite, setConnectedWebsite] = useState("");
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [country, setCountry] = useState("");
    const [createdAt, setCreatedAt] = useState("");
    const [os, setOs] = useState("");
    const [pushTokens, setPushTokens] = useState("");

    const { isLoading: isLoading, error: error, data: infoData } = useQuery(['userInfo', activeUserId], () => fetchUserData(activeUserId));

    const { isLoading: isLoadingSubscriptions, error: errorSubscriptions, data: infoSubscriptions } = useQuery(['userSubscriptions', activeUserId], () => fetchUserSubscriptions(activeUserId));



    useEffect(() => {

        if (!isLoading && !error && infoData) {

            if (infoData.email) {
                setEmail(infoData.email);
            } else {
                setEmail('—');
            }
            if (infoData.name) {
                setName(infoData.name);
            } else {
                setName('—');
            }

            // if (infoData.sites && infoData.sites.length > 0) {
            //     setWebsite(infoData.sites[0].url);
            // } else {
            //     setWebsite('—');
            // }

            if (infoData.phone && infoData.phone.length > 0) {
                setPhone(infoData.phone);
            } else {
                setPhone('—');
            }
            const phoneNumberString = infoData.phone;
            setCountry('');
            if (phoneNumberString !== null) {
                const phoneNumber = new AwesomePhoneNumber(phoneNumberString)
                setCountry(phoneNumber.g.regionCode.toLowerCase())
            }

            const dateString = infoData.createdAt;
            const dateObject = new Date(dateString);

            const formattedDate = dateObject.toLocaleString('en-US', {
                month: 'short',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit',
                hour12: false
            });
            setCreatedAt(formattedDate);

            if (infoData.os && infoData.os.length > 0) {
                setOs(infoData.os.toLowerCase());
            } else {
                setOs('safari');
            }

            if (infoData.pushTokens.length === 0) {
                setPushTokens('no');
            } else {
                setPushTokens('yes');
            }
        }
    }, [isLoading, error, infoData]);

    const { isLoading: isLoadingSite, error: errorSite, data: infoSiteData } = useQuery(['userInfoSite', activeUserId], () => fetchUserSite(activeUserId));

    useEffect(() => {
        if (!isLoadingSite && !errorSite && infoSiteData) {
                setWebsite('—');
            setConnectedWebsite('—')
            infoSiteData.items.forEach(item => {
                if (item.url.endsWith('.zee.am/')) {
                    setWebsite(item.url);
                } else {
                    setConnectedWebsite(item.url);
                }
            });
        
           
        }
    }, [isLoadingSite, errorSite, infoSiteData, infoData]);

    const [activeRowsLoading, setActiveRowsLoading] = useState([
        // createActiveRows('ended', 'Trial', "Ended", 'Billing weekly', '', false, false),
        // createActiveRows('green', 'Growth', "Active", 'Billing Monthly', 'Next invoice on Dec 20 for $119.95', false, false),
        // createActiveRows('pause-gray', 'Plus', "Paused", 'Billing Monthly', 'Next invoice on Dec 20 for $39.95', false, false),
        // createActiveRows('pause-yellow', 'Daily', "Paused for Jan 2, 2024", 'Billing daily', '', false, false),
    ]);

    useEffect(() => {
        if (!isLoadingSubscriptions && !errorSubscriptions && infoSubscriptions) {

            if (infoSubscriptions.data.subscription) {

                if (infoSubscriptions.data.subscription.pricingPlan === 'FREE') {
                    setActiveRowsLoading([
                        createActiveRows('ended', infoSubscriptions.data.subscription.pricingPlan, infoSubscriptions.data.subscription.status, 'Billing weekly', ' ', false, false)]
                    );
                } else {
                    setActiveRowsLoading([createActiveRows(
                        'green',
                        infoSubscriptions.data.subscription.pricingPlan,
                        infoSubscriptions.data.subscription.status,
                        'Billing Monthly',
                        `Next invoice on ${formatDate(infoSubscriptions.data.subscription.nextPaymentAt)} for $${(infoSubscriptions.data.subscription.amount / 100).toFixed(2)}`, // Assuming price is a property of subscription
                        false,
                        false
                    )]);
                }
                setUserInfoSubscription(true)
                
            } else {
                setUserInfoSubscription(false)
                setActiveRowsLoading([])
            }
        }
    }, [isLoadingSubscriptions, errorSubscriptions, infoSubscriptions]);

    function formatDate(dateString) {
        const date = new Date(dateString);
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        const monthIndex = date.getMonth();
        const day = date.getDate();

        return months[monthIndex] + ' ' + day;
    }

    const sendWeb = (website) => {
        window.open(website, '_blank');
    };
    const handleCopyClick = (value, text) => {
        
        navigator.clipboard.writeText(value)
            .then(() => {
                setSnackbarOpen(true);
                setSnackbarText(`${text} was copied`);
                setSnackbarCount(3)
            })
            .catch((error) => {
                console.error('Copy failed: ', error);
            });
    };

    // const [activeRows, setActiveRows] = useState([]);

    function createActiveRows(status, name, statusDescription, billing, NextBilling, isPlayVisible, isPauseVisible) {
        return { status, name, statusDescription, billing, NextBilling, isPlayVisible, isPauseVisible };
    }



    const togglePlayVisibility = (index) => {
        if (index >= 0 && index < activeRowsLoading.length) {
            const updatedRows = [...activeRowsLoading];
            updatedRows[index].isPlayVisible = true;
            setActiveRowsLoading(updatedRows);
        }
    };

    const closePlay = (index) => {
        if (index >= 0 && index < activeRowsLoading.length) {
            const updatedRows = [...activeRowsLoading];
            updatedRows[index].isPlayVisible = false;
            setActiveRowsLoading(updatedRows);
        }
    };


    const togglePauseVisibility = (index) => {
        if (index >= 0 && index < activeRowsLoading.length) {
            const updatedRows = [...activeRowsLoading];
            updatedRows[index].isPauseVisible = true;
            setActiveRowsLoading(updatedRows);
        }
    };

    const closePause = (index) => {
        if (index >= 0 && index < activeRowsLoading.length) {
            const updatedRows = [...activeRowsLoading];
            updatedRows[index].isPauseVisible = false;
            setActiveRowsLoading(updatedRows);
        }
    };

    const [paymentsRows, setPaymentsRows] = useState([]);


    function createPaymentsRows(amount, currency, status, description, date) {
        return { amount, currency, status, description, date };
    }
    const paymentsRowsLoading = [
        createPaymentsRows('$119.95', 'USD', "Refund", 'Subscription update', 'Dec 19, 11:36 AM'),
        createPaymentsRows('$1.00', 'USD', "Succeeded", 'Subscription update', 'Dec 19, 11:36 AM'),
        createPaymentsRows('$1.00', 'USD', "Succeeded", 'Subscription update', 'Dec 18, 15:36 AM'),
        createPaymentsRows('$1.00', 'USD', "Canceled", 'Subscription update', 'Dec 18, 11:36 AM'),
        createPaymentsRows('$1.00', 'USD', "Succeeded", 'Subscription update', 'Dec 18, 11:36 AM'),
    ];

    // setActiveRows(activeRowsLoading)


    const [methodRows, setMethodRows] = useState([]);


    function createMethodRows(card, number, defaultCard, date) {
        return { card, number, defaultCard, date };
    }
    const methodRowsLoading = [
        createMethodRows('Visa', '1202', true, 'Exspires May 2029'),
        createMethodRows('MasterCard', '4907', false, 'Exspires May 2029'),
        createMethodRows('Apple pay', '', false, 'Exspires May 2029'),
    ];

    // setActiveRows(activeRowsLoading)


    function createData(date, adminName, adminEmail, color, action, description) {
        return { date, adminName, adminEmail, color, action, description };
    }
    const rows = [
        createData('22.08. 2023, 06:17PM', 'Veronika', 'supzeely@app.com', 'red', 'Refund', '<b>$195.95</b>  from the Growth plan'),
        createData('22.08. 2023, 06:17PM', 'Veronika', 'supzeely@app.com', 'gray', 'Unsubscribe', 'from Plus tariff'),
        createData('22.08. 2023, 06:17PM', 'Veronika', 'supzeely@app.com', 'gray', 'Paused tariff', 'Paused daily plan for Jan 2, 2024'),
    ];


    const [openPlay, setOpenPlay] = useState(false);
    const handleOpenPlay = () => setOpenPlay(true);
    const handleClosePlay = () => setOpenPlay(false);

    const [openPause, setOpenPause] = useState(false);
    const handleOpenPause = () => setOpenPause(true);
    const handleClosePause = () => setOpenPause(false);

    const [openDelete, setOpenDelete] = useState(false);
    const handleOpenDelete = () => setOpenDelete(true);
    const handleCloseDelete = () => setOpenDelete(false);

    const [openRefund, setOpenRefund] = useState(false);
    const handleOpenRefund = () => setOpenRefund(true);
    const handleCloseRefund = () => setOpenRefund(false);

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "540rem",
        background: '#fff',
        borderRadius: "24rem",
        padding: '60rem 68rem 60rem',
        outline: 'none'
    };




    const buttonAnimation = (element) => {
        const tl = gsap.timeline();
        tl.to(element, {
            duration: 0.085,
            scale: 0.85,
            opacity: 0.85,
            ease: "power4.out",
        });
        tl.to(element, {
            duration: 0.55,
            scale: 1,
            opacity: 1,
            ease: "elastic.out(1,0.6)",
        });
    }

    return (
        <div className="userInfo">
            <div className="userInfo-info">
                <div className={`userInfo-block ${activeBlockId === 1 ? 'active' : ''}`}
                    id={1}>
                    <div className="userInfo-block-top">
                        <div className="userInfo-block__title">
                            User information
                        </div>
                        {activeBlockId !== 1 && (
                            <div className="userInfo-block__edit" onClick={(e) => handleEditClick(e, 1)}>
                                Edit
                            </div>
                        )}
                        {activeBlockId === 1 && (
                            <div className="userInfo-block-action">
                                <div className="userInfo-block__cancel" onClick={(e) => handleEditClick(e, 0)}>
                                    Cancel
                                </div>
                                <div className="userInfo-block__save" onClick={(e) => handleEditClick(e, 0)}>
                                    Save
                                </div>
                            </div>
                        )}

                    </div>

                    <div className="userInfo-block-wrapper">
                        <div className="userInfo-block-item" >
                            <span>Email</span>
                            {isLoading ? (
                                <Skeleton variant="rounded" width={'220rem'} height={"26rem"} />
                            ) : (
                                <div className="userInfo-block-item-wrapper" >
                                    <input type="text"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}

                                    />
                                    <i onClick={() => handleCopyClick(email, 'Email')}></i>
                                </div>
                            )}

                        </div>
                        <div className="userInfo-block-item">
                            <span>Name</span>
                            {isLoading ? (
                                <Skeleton variant="rounded" width={"220rem"} height={"26rem"} />
                            ) : (
                                <input type="text" value={name}
                                    onChange={e => setName(e.target.value)} />
                            )}
                        </div>
                        <div className="userInfo-block-item">
                            <span>Connected website</span>
                            {isLoading ? (
                                <Skeleton variant="rounded" width={"220rem"} height={"26rem"} />
                            ) : (
                                <div className="userInfo-block-item-bottom" style={{display: 'flex', alignItems: 'center'}}>
                                    <img src={process.env.PUBLIC_URL + `/img/shopify.svg`} alt=""  style={{marginBottom: '5rem'}}/>
                                    <div className="userInfo-block-item-wrapper" >
                                        <input type="text" value={connectedWebsite}
                                            onChange={e => setConnectedWebsite(e.target.value)}
                                        />
                                        <i  onClick={() => sendWeb(connectedWebsite)}></i>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="userInfo-block-item">
                            <span>Zeely website</span>
                            {isLoading ? (
                                <Skeleton variant="rounded" width={"220rem"} height={"26rem"} />
                            ) : (
                                <div className="userInfo-block-item-wrapper" >
                                    <input type="text" value={website}
                                        onChange={e => setWebsite(e.target.value)}
                                    />
                                    <i  onClick={() => sendWeb(website)}></i>
                                </div>
                            )}
                        </div>

                        <div className="userInfo-block-item">
                            <span>Phone</span>
                            {isLoading ? (
                                <Skeleton variant="rounded" width={"220rem"} height={"26rem"} />
                            ) : (
                                <label className="userInfo-block-item__phone">
                                    {country && (
                                        <img src={process.env.PUBLIC_URL + `/img/flags/4x3/${country}.svg`} alt="" />
                                    )}
                                    <input type="text"
                                        value={phone}
                                        onChange={e => setPhone(e.target.value)}
                                        style={{ paddingLeft: country ? '30rem' : '0' }}
                                    />
                                </label>
                            )}
                        </div>
                        <div className="userInfo-block-item">
                            <span>Os</span>
                            {isLoading ? (
                                <Skeleton variant="rounded" width={"220rem"} height={"26rem"} />
                            ) : (
                                <div className="userInfo-block-item-bottom">
                                    <img src={process.env.PUBLIC_URL + `/img/${os}.svg`} alt="" />
                                    <p>{os}</p>
                                </div>
                            )}
                        </div>
                        <div className="userInfo-block-item">
                            <span>Push-Token</span>
                            {isLoading ? (
                                <Skeleton variant="rounded" width={"220rem"} height={"26rem"} />
                            ) : (
                                <div className="userInfo-block-item-bottom">
                                    <img src={process.env.PUBLIC_URL + `/img/${pushTokens}.svg`} alt="" />
                                    <p>{pushTokens}</p>
                                </div>
                            )}

                        </div>
                        {/* <div className="userInfo-block-item">
                            <span>Activity</span>
                            {isLoading ? (
                                <Skeleton variant="rounded" width={220} height={26} />
                            ) : (
                                <div className="userInfo-block-item-bottom">
                                    <div className="progress">
                                        <div className="progress-main">
                                            <Stack spacing={2} direction="row">
                                                <CircularProgress
                                                    variant="determinate"
                                                    value={value}
                                                    size={20}
                                                    thickness={8}
                                                    style={circularProgressStyle}
                                                />
                                            </Stack>
                                        </div>
                                        <div className="progress-bg">
                                            <Stack spacing={2} direction="row">
                                                <CircularProgress
                                                    variant="determinate"
                                                    value={100}
                                                    size={20}
                                                    thickness={8}
                                                    style={{ color: '#F2F4F6' }}
                                                />
                                            </Stack>
                                        </div>
                                    </div>

                                    <p>7/42</p>
                                </div>
                            )}

                        </div> */}
                        <div className="userInfo-block-item">
                            <span>Created date</span>
                            {isLoading ? (
                                <Skeleton variant="rounded" width={"220rem"} height={"26rem"} />
                            ) : (
                                <div className="userInfo-block-item-bottom">

                                    <p>{createdAt}</p>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>


            {activeRowsLoading.length > 0 && (
  <div className="userInfo-active">

  <div className="userInfo__title">Active Subscriptions</div>
  <div className="table">

      <TableContainer component={Paper}>

          <Table sx={{ minWidth: 650 }} aria-label="simple table">


              <TableBody>

                  {activeRowsLoading.map((row, index) => (
                      <TableRow
                      >
                          <TableCell className="table-body-cell">


                              {isLoadingSubscriptions ? (
                                  <Skeleton variant="rounded" width={"38rem"} height={"38rem"} />
                              ) : (
                                  <>
                                      {row.status === 'ended' && (
                                          <>
                                              <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="38" height="38" rx="6" fill="#F2F4F6" />
                                                  <g clip-path="url(#clip0_2851_40490)">
                                                      <circle cx="19" cy="19" r="7.25" stroke="#A7A8AA" stroke-width="1.5" />
                                                      <path d="M19 14C20.0572 14 21.0871 14.3351 21.9419 14.9571C22.7967 15.5791 23.4324 16.456 23.7576 17.4619L19 19V14Z" fill="#A7A8AA" />
                                                  </g>
                                                  <defs>
                                                      <clipPath id="clip0_2851_40490">
                                                          <rect x="11" y="11" width="16" height="16" rx="8" fill="white" />
                                                      </clipPath>
                                                  </defs>
                                              </svg>

                                          </>
                                      )}

                                      {row.status === 'green' && (
                                          <>
                                              <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="38" height="38" rx="6" fill="#17CC37" />
                                                  <g clip-path="url(#clip0_2851_40574)">
                                                      <circle cx="19" cy="19" r="7.25" stroke="white" stroke-width="1.5" />
                                                      <path d="M19 14C20.0572 14 21.0871 14.3351 21.9419 14.9571C22.7967 15.5791 23.4324 16.456 23.7576 17.4619L19 19V14Z" fill="white" />
                                                  </g>
                                                  <defs>
                                                      <clipPath id="clip0_2851_40574">
                                                          <rect x="11" y="11" width="16" height="16" rx="8" fill="white" />
                                                      </clipPath>
                                                  </defs>
                                              </svg>

                                          </>
                                      )}

                                      {row.status === 'pause-gray' && (
                                          <>
                                              <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="38" height="38" rx="6" fill="#F2F4F6" />
                                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M15 12.25C15.4142 12.25 15.75 12.5858 15.75 13L15.75 25C15.75 25.4142 15.4142 25.75 15 25.75C14.5858 25.75 14.25 25.4142 14.25 25L14.25 13C14.25 12.5858 14.5858 12.25 15 12.25Z" fill="#A7A8AA" />
                                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M23 12.25C23.4142 12.25 23.75 12.5858 23.75 13L23.75 25C23.75 25.4142 23.4142 25.75 23 25.75C22.5858 25.75 22.25 25.4142 22.25 25L22.25 13C22.25 12.5858 22.5858 12.25 23 12.25Z" fill="#A7A8AA" />
                                              </svg>

                                          </>
                                      )}

                                      {row.status === 'pause-yellow' && (
                                          <>
                                              <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <rect width="38" height="38" rx="6" fill="#FFD948" />
                                                  <circle cx="19" cy="20" r="6.25" stroke="white" stroke-width="1.5" />
                                                  <path d="M18.25 13V13.75H19.75V13H18.25ZM19.75 12C19.75 11.5858 19.4142 11.25 19 11.25C18.5858 11.25 18.25 11.5858 18.25 12H19.75ZM19.75 13V12H18.25V13H19.75Z" fill="white" />
                                                  <path d="M19 17V20.5L21 22.5" stroke="white" stroke-width="1.5" stroke-linecap="round" />
                                              </svg>

                                          </>
                                      )}
                                  </>
                              )}


                          </TableCell>
                          <TableCell className="table-body-cell active-info">
                              {isLoadingSubscriptions ? (
                                  <Skeleton variant="rounded" width={"200rem"} height={"38rem"} />
                              ) : (
                                  <>  <div className="active-info-top">
                                      <strong>{row.name}</strong>
                                      <span className={row.status}>
                                          {row.statusDescription}
                                      </span>
                                  </div>
                                      <p>
                                          {/* {row.billing} <span></span>  */}
                                          {row.NextBilling}</p>
                                  </>
                              )}
                          </TableCell>
                          <TableCell className="table-body-cell">
                              {isLoadingSubscriptions ? (
                                  <Skeleton variant="rounded" width={"32.4rem"} height={"32.4rem"} />
                              ) : (
                                  <div className="active-action">

                                      {(row.status === 'pause-gray' || row.status === 'pause-yellow' || row.status === 'ended') && (
                                          <div className="active-action-btn active-action-btn--play" >
                                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <path d="M13.3561 7.11867C14.0594 7.49574 14.0594 8.50427 13.3561 8.88133L5.97249 12.8397C5.30632 13.1969 4.5 12.7143 4.5 11.9584L4.5 4.04159C4.5 3.28572 5.30632 2.80311 5.97249 3.16025L13.3561 7.11867Z" fill="black" />
                                              </svg>
                                              <i onClick={() => togglePlayVisibility(index)}>
                                                  <span>Renew subscription</span>
                                              </i>



                                              {row.isPlayVisible && (
                                                  <ul onClick={() => closePlay(index)}>
                                                      <li onClick={handleOpenPlay}>Change subscription</li>
                                                      <li onClick={handleOpenPlay}>Extend the trial</li>
                                                  </ul>
                                              )}
                                          </div>


                                      )}
                                      {row.status === 'green' && (
                                          <>
                                              <div className="active-action-btn active-action-btn--pause">
                                                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M4 1.25C4.41421 1.25 4.75 1.58579 4.75 2L4.75 14C4.75 14.4142 4.41421 14.75 4 14.75C3.58579 14.75 3.25 14.4142 3.25 14L3.25 2C3.25 1.58579 3.58579 1.25 4 1.25Z" fill="black" />
                                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 1.25C12.4142 1.25 12.75 1.58579 12.75 2L12.75 14C12.75 14.4142 12.4142 14.75 12 14.75C11.5858 14.75 11.25 14.4142 11.25 14L11.25 2C11.25 1.58579 11.5858 1.25 12 1.25Z" fill="black" />
                                                  </svg>
                                                  <i onClick={() => togglePauseVisibility(index)}>
                                                      <span>Pause subscription</span>
                                                  </i>
                                                  {row.isPauseVisible && (
                                                      <ul onClick={() => closePause(index)}>
                                                          <li onClick={handleOpenPause}>Pause subscription</li>
                                                          <li onClick={handleOpenPause}>Temporary pause</li>
                                                      </ul>
                                                  )}

                                              </div>
                                              <div className="active-action-btn active-action-btn--delete">
                                                  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.22711 3.22748C3.52 2.93459 3.99487 2.93459 4.28777 3.22748L12.773 11.7128C13.0659 12.0057 13.0659 12.4805 12.773 12.7734C12.4802 13.0663 12.0053 13.0663 11.7124 12.7734L3.22711 4.28814C2.93421 3.99525 2.93421 3.52038 3.22711 3.22748Z" fill="black" />
                                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.7731 3.22564C13.066 3.51854 13.066 3.99341 12.7731 4.2863L4.28785 12.7716C3.99495 13.0645 3.52008 13.0645 3.22719 12.7716C2.93429 12.4787 2.93429 12.0038 3.22719 11.7109L11.7125 3.22564C12.0054 2.93275 12.4802 2.93275 12.7731 3.22564Z" fill="black" />
                                                  </svg>
                                                  <i onClick={handleOpenDelete}>
                                                      <span >Cancel subscription</span>
                                                  </i>

                                              </div>
                                          </>
                                      )}
                                      {(row.status === 'pause-gray' || row.status === 'pause-yellow' || row.status === 'green') && (
                                          <div className="active-action-btn active-action-btn--setting">
                                              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                  <circle cx="3.5" cy="8" r="1.5" fill="black" />
                                                  <circle cx="8" cy="8" r="1.5" fill="black" />
                                                  <circle cx="12.5" cy="8" r="1.5" fill="black" />
                                              </svg>
                                              <i >
                                                  <span>More options</span>
                                              </i>
                                          </div>
                                      )}
                                  </div>
                              )}

                          </TableCell>
                      </TableRow>
                  ))}
              </TableBody>

          </Table>

      </TableContainer>


  </div>
</div>
            )}
          
                                                        
            {/* <div className="userInfo-active">

                <div className="userInfo__title">Subscription Payments</div>
                <div className="table">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className="table-head-cell">Amount</TableCell>
                                    <TableCell className="table-head-cell" style={{ paddingLeft: '12rem' }}>Status</TableCell>
                                    <TableCell className="table-head-cell" style={{ paddingLeft: '24rem' }}>Description</TableCell>
                                    <TableCell className="table-head-cell">Date</TableCell>
                                    <TableCell className="table-head-cell"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody style={{ paddingBottom: '20rem' }}>
                                {paymentsRowsLoading.map((row) => (
                                    <TableRow
                                    >
                                        <TableCell className="table-body-cell userInfo-payments-amount">
                                            {row.amount}
                                            <span>{row.currency}</span>
                                        </TableCell>
                                        <TableCell className="table-body-cell userInfo-payments-status">
                                            <span className={row.status}>
                                                {row.status}

                                                {row.status === 'Refund' && (
                                                    <>
                                                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M3 0.5L1 2L3 3.5" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
                                                            <path d="M3 7.00146C5 7.00106 7 7.50211 7 4.50106C7 1.5 4 1.9987 1 2.00122" stroke="white" stroke-linecap="round" />
                                                        </svg>
                                                    </>
                                                )}
                                                {row.status === 'Succeeded' && (
                                                    <>
                                                        <svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2.66144 4.43355L0.975489 2.8131C0.752333 2.58994 0.390524 2.58994 0.167368 2.8131C-0.0557892 3.03625 -0.0557892 3.39806 0.167368 3.62122L2.25738 5.64573C2.48053 5.86889 2.84234 5.86889 3.0655 5.64573L7.78359 1.12393C8.00674 0.90077 8.00674 0.538962 7.78359 0.315805C7.56043 0.0926483 7.19862 0.0926483 6.97546 0.315805L2.66144 4.43355Z" fill="#1CAF36" />
                                                        </svg>
                                                    </>
                                                )}
                                                {row.status === 'Canceled' && (
                                                    <>
                                                        <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1 1L7 7" stroke="#F43535" stroke-linecap="round" />
                                                            <path d="M1 7L7 1" stroke="#F43535" stroke-linecap="round" />
                                                        </svg>
                                                    </>
                                                )}
                                            </span>
                                        </TableCell>
                                        <TableCell className="table-body-cell  userInfo-payments-description">
                                            {row.description}
                                        </TableCell>
                                        <TableCell className="table-body-cell">
                                            {row.date}
                                        </TableCell>
                                        <TableCell className="table-body-cell" style={{ paddingLeft: '58rem' }}>
                                            <div className="active-action-btn active-action-btn--setting">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M5 1.5L1.5 5L5 8.5" stroke="black" stroke-width="1.5" stroke-linecap="round" />
                                                    <path d="M2 5H9.5C11.9853 5 14 7.01472 14 9.5V9.5C14 11.9853 11.9853 14 9.5 14H3" stroke="black" stroke-width="1.5" stroke-linecap="round" />
                                                </svg>
                                                <i onClick={handleOpenRefund}>
                                                    <span>Refund</span>
                                                </i>


                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>

                    </TableContainer>


                </div>
            </div> */}

{/* 
            <div className="userInfo-active">

                <div className="userInfo__title">Payment method</div>
                <div className="table">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">

                            <TableBody>
                                {methodRowsLoading.map((row) => (
                                    <TableRow
                                    >
                                        <TableCell className="table-body-cell ">
                                            {row.card === 'Visa' && (
                                                <>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 38 38" fill="none">
                                                        <rect width="38" height="38" rx="6" fill="#191D79" />
                                                        <path d="M16.8682 15.6443L15.7597 22.2738H17.5504L18.6589 15.6443H16.8682ZM14.2248 15.6443L12.5194 20.2277L12.3488 19.2455L11.7519 16.2991C11.7519 16.2991 11.6667 15.7262 10.8992 15.7262H8V15.808C8 15.808 8.85271 15.9717 9.87597 16.5446L11.4109 22.2738H13.2868L16.1861 15.6443H14.2248ZM27.1861 15.6443C26.5039 15.6443 26.3334 16.1354 26.3334 16.1354L23.69 22.2738H25.5659L25.907 21.2917H28.2093L28.3799 22.2738H30L28.5504 15.6443H27.1861ZM26.4186 19.9822L27.3566 17.5268L27.8683 19.9822H26.4186ZM21.3024 17.5268C21.3024 16.7902 23.0931 16.872 23.8605 17.2813L24.1163 15.8899C24.1163 15.8899 23.3489 15.5625 22.4962 15.5625C21.6434 15.5625 19.5117 15.9717 19.5117 17.7723C19.5117 19.4911 21.9845 19.4911 21.9845 20.3914C21.9845 21.2917 19.7675 21.128 19 20.5551L18.7442 22.0283C18.7442 22.0283 19.5117 22.4375 20.7907 22.4375C21.9845 22.4375 23.8605 21.8646 23.8605 20.1458C23.7752 18.4271 21.3024 18.2634 21.3024 17.5268Z" fill="white" />
                                                    </svg>
                                                </>
                                            )}
                                            {row.card === 'MasterCard' && (
                                                <>
                                                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect width="38" height="38" rx="6" fill="#F2F4F6" />
                                                        <path d="M21.3467 14.5938H16.6528V23.3898H21.3467V14.5938Z" fill="#FF5F00" />
                                                        <path d="M16.9516 19.0008C16.9523 18.1533 17.1364 17.317 17.4899 16.5551C17.8435 15.7932 18.3573 15.1256 18.9926 14.6028C18.2066 13.9539 17.2626 13.5504 16.2685 13.4383C15.2744 13.3261 14.2704 13.51 13.3711 13.9687C12.4718 14.4275 11.7136 15.1427 11.1831 16.0325C10.6526 16.9224 10.3713 17.951 10.3713 19.0008C10.3713 20.0506 10.6526 21.0792 11.1831 21.9691C11.7136 22.8589 12.4718 23.5741 13.3711 24.0329C14.2704 24.4916 15.2744 24.6754 16.2685 24.5633C17.2626 24.4512 18.2066 24.0476 18.9926 23.3988C18.3562 22.877 17.8415 22.2097 17.4879 21.4475C17.1342 20.6854 16.9507 19.8486 16.9516 19.0008Z" fill="#EC001B" />
                                                        <path d="M27.68 19.0008C27.6726 20.0533 27.3846 21.0826 26.8487 21.9721C26.3127 22.8616 25.5502 23.5757 24.6475 24.0335C23.7449 24.4913 22.7382 24.6744 21.7414 24.5623C20.7447 24.4501 19.7978 24.0471 19.0081 23.3988C19.6438 22.8764 20.158 22.2089 20.5116 21.4469C20.8652 20.6849 21.0489 19.8484 21.0489 19.0008C21.0489 18.1531 20.8652 17.3166 20.5116 16.5546C20.158 15.7926 19.6438 15.1251 19.0081 14.6028C19.9519 13.8262 21.1167 13.4048 22.3158 13.4063C25.2815 13.4063 27.68 15.9238 27.68 19.0008Z" fill="#F89E1B" />
                                                    </svg>

                                                </>
                                            )}
                                            {row.card === 'Apple pay' && (
                                                <>
                                                    <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect width="38" height="38" rx="6" fill="black" />
                                                        <path d="M20.8114 12.967C21.3097 12.3445 21.646 11.4794 21.5546 10.6172C20.8344 10.6476 19.9637 11.1128 19.451 11.7331C18.9865 12.2837 18.5846 13.1651 18.6955 14.0103C19.4963 14.0741 20.313 13.5896 20.8114 12.967ZM22.8804 18.4384C22.8645 16.5738 24.3567 15.6767 24.4258 15.6322C23.584 14.3709 22.2783 14.1958 21.8131 14.1765C20.7004 14.0629 19.6396 14.8517 19.0772 14.8517C18.5126 14.8517 17.6441 14.1943 16.7194 14.2143C15.5066 14.2321 14.3882 14.9407 13.7638 16.0589C12.5071 18.3167 13.4448 21.6512 14.672 23.4772C15.2726 24.3713 15.987 25.3759 16.9282 25.3395C17.8342 25.3032 18.1741 24.7356 19.2688 24.7356C20.3612 24.7341 20.6702 25.338 21.6244 25.321C22.5981 25.2995 23.216 24.4083 23.813 23.5098C24.4993 22.4748 24.7838 21.4694 24.7982 21.4189C24.7787 21.4056 22.902 20.668 22.8804 18.4384Z" fill="white" />
                                                    </svg>

                                                </>
                                            )}

                                        </TableCell>
                                        <TableCell className="table-body-cell userInfo-method-card">

                                            <span>
                                                {row.card}
                                                <i>
                                                    {row.number != '' && (
                                                        <>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                            <span></span>
                                                        </>
                                                    )}
                                                </i>


                                                {row.number}
                                                {row.default == true && (
                                                    <>
                                                        <div className="default">Default</div>
                                                    </>
                                                )}
                                            </span>
                                        </TableCell>

                                        <TableCell className="table-body-cell">
                                            {row.date}
                                        </TableCell>
                                        <TableCell className="table-body-cell" style={{ paddingLeft: '58rem' }}>
                                            <div className="active-action-btn active-action-btn--setting">
                                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <circle cx="3.5" cy="8" r="1.5" fill="black" />
                                                    <circle cx="8" cy="8" r="1.5" fill="black" />
                                                    <circle cx="12.5" cy="8" r="1.5" fill="black" />
                                                </svg>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>

                    </TableContainer>


                </div>
            </div>


            <div className="userInfo-active">

                <div className="userInfo__title">Subscription Payments</div>
                <div className="table">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date and time</TableCell>
                                    <TableCell>Admin account</TableCell>

                                    <TableCell>Change</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row) => (

                                    <TableRow


                                    >
                                        <TableCell className="table-body-cell userInfo-logs-date">
                                            {row.date}
                                        </TableCell>
                                        <TableCell className="table-body-cell">
                                            <div className="table-admin">
                                                <strong>{row.adminName}</strong>
                                                <span>{row.adminEmail}</span>
                                            </div>
                                        </TableCell>

                                        <TableCell className="table-body-cell">
                                            <div className="table-action">
                                                <span className={`table-action-${row.color}`}>{row.action}</span>
                                                <div dangerouslySetInnerHTML={{
                                                    __html: row.description
                                                }}></div>

                                            </div>

                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>


                </div>
            </div> */}




            <Modal
                keepMounted
                open={openPlay}
                onClose={handleClosePlay}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
                className="modal"
            >
                <Box sx={style} className="modal-container">
                    <div className="modal-close" onClick={handleClosePlay}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M6 6L18 18" stroke="black" stroke-width="1.6" stroke-linecap="round" />
                            <path d="M18 6L6 18" stroke="black" stroke-width="1.6" stroke-linecap="round" />
                        </svg>
                    </div>
                    <div className="modal-icon">
                        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="40" cy="40" r="40" fill="#F2F4F6" />
                            <path d="M52.0511 38.017C53.6337 38.8654 53.6337 41.1346 52.0511 41.983L35.4381 50.8894C33.9392 51.693 32.125 50.6071 32.125 48.9064L32.125 31.0936C32.125 29.3929 33.9392 28.307 35.4381 29.1106L52.0511 38.017Z" fill="black" />
                        </svg>
                    </div>

                    <div className="modal-title">Do you really want to <br />renew subscription?</div>
                    <div className="modal-text">This action is irreversible</div>
                    <div className="modal-action">
                        <div className="modal__cancel" onClick={handleClosePlay}>Close</div>
                        <div className="modal__send" onClick={() => {
                            handleClosePlay();

                        }} >Renew</div>
                    </div>

                </Box>
            </Modal>


            <Modal
                keepMounted
                open={openPause}
                onClose={handleClosePause}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
                className="modal"
            >
                <Box sx={style} className="modal-container">
                    <div className="modal-close" onClick={handleClosePause}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M6 6L18 18" stroke="black" stroke-width="1.6" stroke-linecap="round" />
                            <path d="M18 6L6 18" stroke="black" stroke-width="1.6" stroke-linecap="round" />
                        </svg>
                    </div>
                    <div className="modal-icon">
                        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="40" cy="40" r="40" fill="#F2F4F6" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M31 24.8125C31.932 24.8125 32.6875 25.568 32.6875 26.5L32.6875 53.5C32.6875 54.432 31.932 55.1875 31 55.1875C30.068 55.1875 29.3125 54.432 29.3125 53.5L29.3125 26.5C29.3125 25.568 30.068 24.8125 31 24.8125Z" fill="black" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M49 24.8125C49.932 24.8125 50.6875 25.568 50.6875 26.5L50.6875 53.5C50.6875 54.432 49.932 55.1875 49 55.1875C48.068 55.1875 47.3125 54.432 47.3125 53.5L47.3125 26.5C47.3125 25.568 48.068 24.8125 49 24.8125Z" fill="black" />
                        </svg>


                    </div>

                    <div className="modal-title">Do you really want to<br />
                        pause subscription?</div>
                    <div className="modal-text">This action is irreversible</div>
                    <div className="modal-action">
                        <div className="modal__cancel" onClick={handleClosePause}>Close</div>
                        <div className="modal__delete" onClick={() => {
                            handleClosePause();

                        }} >Pause</div>
                    </div>

                </Box>
            </Modal>


            <Modal
                keepMounted
                open={openDelete}
                onClose={handleCloseDelete}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
                className="modal"
            >
                <Box sx={style} className="modal-container">
                    <div className="modal-close" onClick={handleCloseDelete}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M6 6L18 18" stroke="black" stroke-width="1.6" stroke-linecap="round" />
                            <path d="M18 6L6 18" stroke="black" stroke-width="1.6" stroke-linecap="round" />
                        </svg>
                    </div>
                    <div className="modal-icon">
                        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="40" cy="40" r="40" fill="#F2F4F6" />
                            <path d="M33.25 25.375L25.375 33.25L33.25 41.125" stroke="black" stroke-width="3.375" stroke-linecap="round" />
                            <path d="M26.5 33.25H43.375C48.9669 33.25 53.5 37.7831 53.5 43.375V43.375C53.5 48.9669 48.9669 53.5 43.375 53.5H28.75" stroke="black" stroke-width="3.375" stroke-linecap="round" />
                        </svg>





                    </div>

                    <div className="modal-title">Do you really want to<br />
                        cancel subscription?</div>
                    <div className="modal-text">This action is irreversible</div>
                    <div className="modal-action">
                        <div className="modal__cancel" onClick={handleCloseDelete}>Close</div>
                        <div className="modal__delete" onClick={() => {
                            handleCloseDelete();

                        }} >Cancel</div>
                    </div>

                </Box>
            </Modal>


            <Modal
                keepMounted
                open={openRefund}
                onClose={handleCloseRefund}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
                className="modal"
            >
                <Box sx={style} className="modal-container">
                    <div className="modal-close" onClick={handleCloseRefund}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M6 6L18 18" stroke="black" stroke-width="1.6" stroke-linecap="round" />
                            <path d="M18 6L6 18" stroke="black" stroke-width="1.6" stroke-linecap="round" />
                        </svg>
                    </div>
                    <div className="modal-icon">
                        <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="40" cy="40" r="40" fill="#F2F4F6" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M29.261 29.2638C29.92 28.6048 30.9885 28.6048 31.6475 29.2638L50.7394 48.3557C51.3984 49.0147 51.3984 50.0831 50.7394 50.7422C50.0804 51.4012 49.0119 51.4012 48.3529 50.7422L29.261 31.6503C28.602 30.9913 28.602 29.9228 29.261 29.2638Z" fill="black" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M50.7395 29.2596C51.3985 29.9187 51.3985 30.9871 50.7395 31.6461L31.6476 50.738C30.9886 51.397 29.9201 51.397 29.2611 50.738C28.6021 50.079 28.6021 49.0105 29.2611 48.3515L48.353 29.2596C49.012 28.6006 50.0805 28.6006 50.7395 29.2596Z" fill="black" />
                        </svg>




                    </div>

                    <div className="modal-title">Do you really want to<br />
                        do refund?</div>
                    <div className="modal-text">This action is irreversible</div>
                    <div className="modal-action">
                        <div className="modal__cancel" onClick={handleCloseRefund}>Close</div>
                        <div className="modal__delete" onClick={() => {
                            handleCloseRefund();

                        }} >Refund</div>
                    </div>

                </Box>
            </Modal>
        </div>

    );
}

export default UserInfo;
