import React, { useState, useEffect } from 'react';
import Filter from '../../components/filter/Filter';
import Header from '../../components/header/Header';
import Search from '../../components/search/Search';
import SelectCustom from '../../components/selectCustom/SelectCustom';
import './users.scss';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import ArrowBack from '../../svg/ArrowBack';
import ArrowNext from '../../svg/ArrowNext';
import Sidebar from 'react-sidebar';
import SideBar from '../../components/sidebar/SideBar';
import Snackbar from '../../components/snackbar/Snackbar';
import Link from '../../svg/Link';
import Cookies from 'js-cookie';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import AwesomePhoneNumber from 'awesome-phonenumber';
import Skeleton from '@mui/material/Skeleton';
import { useQuery } from 'react-query';
import Tick from '../../svg/Tick';
import config from '../../config';

const fetchUserData = async (activePage, selectedSort, filter) => {
    const url = `${config.apiBaseUrl}admins/users`;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('authToken')}`,
    };
    const skip = activePage * 100 - 100;
    const params = {
        skip: skip,
        limit: 100,
        // filter:  JSON.stringify({"createdAt": {"from": "12-17-2023", "to": "12-18-2023"}}),
        // filter:  JSON.stringify({"pushToken":true}),
        // filter: JSON.stringify({createdAt: '21.10.23'}),
        filter: JSON.stringify(filter),
        sort: JSON.stringify(selectedSort)
    }
    const response = await axios.get(url, { headers, params });
    return response.data;
};

const fetchPlans = async () => {
    const url = `${config.apiBaseUrl}admins/subscriptions/pricing-plans`;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('authToken')}`,
    };
    const response = await axios.get(url, { headers });
    return response.data;
};

const Users = () => {
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = React.useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarText, setSnackbarText] = useState('');
    const [snackbarCount, setSnackbarCount] = useState(10);

    const [total, setTotal] = useState('0');
    const pages = Math.ceil(total / 100)
    const [activePage, setActivePage] = useState(1);

    const handlePageChange = (event, page) => {
        setActivePage(page);
    };


    const location = useLocation();

    const getQueryParams = () => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('activeUserId')) {

        }
        return {
            activeUserId: searchParams.get('activeUserId') || '',
        };
    };

    const [activeUserId, setActiveUserId] = useState(getQueryParams().activeUserId);

    useEffect(() => {
        const queryParams = getQueryParams();
        setActiveUserId(queryParams.activeUserId);

        // Set sidebar open/close state based on activeUserId
        setSidebarOpen(!!queryParams.activeUserId);
    }, [location.search]);

    useEffect(() => {
        const searchParams = new URLSearchParams();
        searchParams.set('activeUserId', activeUserId);
        navigate(`?${searchParams.toString()}`);
    }, [activeUserId, navigate]);

    const onSetSidebarOpen = (open, id) => {
        setSidebarOpen(open);
        if (id) {
            setActiveUserId(id);
        } else {
            setActiveUserId('');
        }
    };

    function createData(id, email, os, website, country, phone, name, date, push, subscription) {
        return { id, email, os, website, country, phone, name, date, push, subscription };
    }

    const [rows, setRows] = useState([]);

    const sorts = [
        { value: 'createdAt', label: 'Created Date' },
        { value: 'email', label: 'Email' },
        { value: 'phone', label: 'Phone' },
        { value: 'name', label: 'Name' },
    ];

    const defaultValue = { value: 'createdAt', label: 'Created Date' };
    const [selectedSort, setSelectedSort] = useState({ [defaultValue.value]: "desc" });
    const handleSelectChange = (selectedOption) => {
        if (selectedOption.value == 'email' || selectedOption.value == 'name' || selectedOption.value == 'phone') {
            setSelectedSort({ [selectedOption.value]: "asc" })
        } else {
            setSelectedSort({ [selectedOption.value]: "desc" })
        }
    };

    const [byActive, setByActive] = useState(true);
    const [byActiveSubscription, setByActiveSubscription] = useState(true);

    const [activeFilters, setActiveFilters] = useState({});
    const [additionalValues, setAdditionalValues] = useState([]);

    const getActivePlatform = (filters) => {
        const activePlatform = Object.keys(filters).find((key) => filters[key] === true && (key === 'android' || key === 'iOS'));
        return activePlatform || null;
    };


    const activePlatform = getActivePlatform(activeFilters);

    const [searchTerm, setSearchTerm] = useState('');

    const filter = {
        pushToken: activeFilters.pushTokenYes,
        firstAppLogin: byActive,
    };

    const handleSearchChange = (value) => {
        setSearchTerm(value);
    };

    const selectedValues = additionalValues.filter(value => activeFilters[value]);



    if (byActiveSubscription) {
        filter.subscription = additionalValues
            .filter(pricingPlan => pricingPlan !== "FREE")
            .map(pricingPlan => ({
                pricingPlan,
                status: "ACTIVE"
            }));
    } else {
        if (selectedValues !== "") {
            filter.subscription = selectedValues.map(pricingPlan => ({
                pricingPlan,
                status: "ACTIVE",
            }))
        }
    }


    if (searchTerm !== "") {
        filter.search = searchTerm;
    }


    if (activePlatform !== null) {
        filter.os = activePlatform;
    }

    const [fromData, setFormData] = useState('');
    const [toData, setToData] = useState('');


    if (activeFilters.createdDate == true) {
        filter.createdAt = { "from": fromData, "to": toData };
    }


    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setByActive(checked);
    };

    const handleCheckboxChangeSubscription = (event) => {
        const { name, checked } = event.target;
        setByActiveSubscription(checked);
    };


    const { isLoading: isLoading, error: error, data: userData } = useQuery(['userData', activePage, selectedSort, filter], () => fetchUserData(activePage, selectedSort, filter));
    const [loading, setLoading] = useState(false);


    const { isLoading: plansLoading, error: plansError, data: plansData } = useQuery(['plans'], () => fetchPlans());

    useEffect(() => {
        if (!plansLoading && !plansError && plansData) {
            setAdditionalValues(plansData.plans);
        }
    }, [plansLoading, plansData]);

    const rowsLoading = [
        createData(),
        createData(),
        createData(),
        createData(),
        createData(),
        createData(),
        createData(),
        createData(),
        createData(),
        createData(),
        createData(),
    ];

    useEffect(() => {
        if (isLoading) {
            setRows(rowsLoading);
            setLoading(true);
        }
        if (!isLoading && !error && userData && userData.items) {
            setTotal(userData.total);
            const data = userData.items.map(user => {
                let urlSites;
                if (user.sites && user.sites.length > 0) {
                    urlSites = user.sites[0].url
                } else {
                    urlSites = null;
                }
                let subscription;

                if (user.subscription && user.subscription.length > 0) {
                    subscription = user.subscription[0].pricingPlan
                } else {
                    subscription = null;
                }

                const dateString = user.createdAt;
                const dateObject = new Date(dateString);

                const formattedDate = dateObject.toLocaleString('en-US', {
                    month: 'short',
                    day: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false
                });


                // const inputDateString = user.createdAt;
                // const inputDate = new Date(inputDateString);
                // const formattedDate = `${inputDate.getDate()}.${(inputDate.getMonth() + 1).toString().padStart(2, '0')}.${inputDate.getFullYear()}`;

                let country
                const phoneNumberString = user.phone;
                if (phoneNumberString !== null) {
                    const phoneNumber = new AwesomePhoneNumber(phoneNumberString)
                    country = phoneNumber.g.regionCode;
                    country = String(country).toLowerCase();
                }

                let pushTokens;
                if (user.pushTokens.length === 0) {
                    pushTokens = 'no';
                } else {
                    pushTokens = 'yes';
                }

                let os;
                if (user.os.length === 0 || user.os == '') {
                    os = 'safari';
                } else {
                    os = user.os;
                }

                return createData(
                    user.id,
                    user.email,
                    os,
                    urlSites,
                    country,
                    user.phone,
                    user.name,
                    formattedDate,
                    pushTokens,
                    subscription
                );
            });

            setRows(data);
            setLoading(false);
        }
    }, [isLoading, error, userData]);

    const sendEmail = (email) => {
        const emailTo = `mailto:${email}`;
        window.location.href = emailTo;
    };

    const sendWeb = (website) => {
        window.open(website, '_blank');
    };

    const handleCopyClick = (value, text) => {
        navigator.clipboard.writeText(value)
            .then(() => {
                setSnackbarText(`${text} was copied`);
                setSnackbarCount(3)
                setSnackbarOpen(true);
            })
            .catch((error) => {
                console.error('Copy failed: ', error);
            });
    };

    return (
        <Sidebar
            sidebar={<SideBar onSetSidebarOpen={onSetSidebarOpen} snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} setSnackbarText={setSnackbarText} activeUserId={activeUserId} setSnackbarCount={setSnackbarCount} />} // Замените этот div на ваше содержимое
            open={sidebarOpen}
            onSetOpen={onSetSidebarOpen}
            pullRight={true}
            docked={false}
            sidebarClassName="sidebar"
        >
            <div className='main'>
                <Header />
                <div className="content">
                    <div className="container">
                        <div className="users-search">
                            <Search onSearchChange={handleSearchChange} />
                            <Filter setActiveFilters={setActiveFilters} additionalValues={additionalValues} setFormData={setFormData} setToData={setToData} />
                        </div>

                        <div className="table">
                            <div className="table-top">
                                <div className="table-top__total">
                                    {/* Total users result: {total.toLocaleString("en-EN")} */}
                                    <label className='checkbox' style={{marginLeft: '0'}}>
                                        <input
                                            type="checkbox"
                                            name="byActive"
                                            checked={byActive}
                                            onChange={handleCheckboxChange}
                                        />
                                        <div className="checkbox__icon">
                                            <Tick />
                                        </div>
                                        <span>Active users</span>
                                    </label>
                                    <label className='checkbox'>
                                        <input
                                            type="checkbox"
                                            name="byActive"
                                            checked={byActiveSubscription}
                                            onChange={handleCheckboxChangeSubscription}
                                        />
                                        <div className="checkbox__icon">
                                            <Tick />
                                        </div>
                                        <span>Users with subscription</span>
                                    </label>
                                </div>

                                <div className="pagination">
                                    <Stack spacing={2}>
                                        <Pagination
                                            count={pages}
                                            siblingCount={1}
                                            page={activePage}
                                            onChange={handlePageChange}
                                            renderItem={(item) => (
                                                <PaginationItem
                                                    slots={{ previous: ArrowBack, next: ArrowNext }}
                                                    {...item}
                                                />
                                            )}
                                        />
                                    </Stack>
                                    <div className="pagination__page">100/Page</div>
                                </div>
                                <SelectCustom sorts={sorts} onSelectChange={handleSelectChange} defaultValue={defaultValue} />
                            </div>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: "650rem" }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>User ID</TableCell>
                                            <TableCell>Email</TableCell>
                                            <TableCell align='center'>OS</TableCell>
                                            <TableCell>Website</TableCell>
                                            <TableCell>Phone</TableCell>
                                            <TableCell>Name</TableCell>
                                            <TableCell>Created date</TableCell>
                                            <TableCell>Push</TableCell>
                                            <TableCell>Subscription rate</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        {rows.map((row) => (
                                            <TableRow
                                                className='table-row'
                                            >
                                                <TableCell onClick={() => onSetSidebarOpen(true, row.id)} style={{ cursor: 'pointer' }} component="th" scope="row">
                                                    {loading ? (
                                                        <Skeleton variant="rounded" width={"320rem"} height={"21rem"} />
                                                    ) : (
                                                        <div>{row.id}</div>
                                                    )}


                                                </TableCell>
                                                <TableCell className='table-email'>
                                                    {loading ? (
                                                        <Skeleton variant="rounded" style={{ margin: '0 24rem ' }} width={"191rem"} height={"21rem"} />
                                                    ) : (
                                                        <div><div className="table-tooltip-info">
                                                            <Link />
                                                            {row.email}
                                                            <div className="table-tooltip-info-open table-tooltip-info__btn" onClick={() => sendEmail(row.email)}>
                                                                Open
                                                            </div>
                                                            <div className="table-tooltip-info-copy table-tooltip-info__btn" onClick={() => handleCopyClick(row.email, 'Email')}>
                                                                Copy
                                                            </div>
                                                        </div>
                                                            <span className='table-tooltip' onClick={() => onSetSidebarOpen(true, row.id)} style={{ cursor: 'pointer' }}>

                                                                {row.email}
                                                            </span></div>

                                                    )}


                                                </TableCell>
                                                <TableCell onClick={() => onSetSidebarOpen(true, row.id)} style={{ cursor: 'pointer' }} align="center">
                                                    {loading ? (
                                                        <Skeleton variant="rounded" style={{ margin: '0 auto' }} width={"20rem"} height={20} />
                                                    ) : (
                                                        <span className={`icon-os`} style={{ background: `url(${process.env.PUBLIC_URL}/img/${row.os.toLowerCase()}.svg)` }}></span>
                                                    )}

                                                </TableCell>
                                                <TableCell className='table-website'>
                                                    {loading ? (
                                                        <Skeleton variant="rounded" style={{ margin: '0 16rem ' }} width={"181rem"} height={"21rem"} />
                                                    ) : (
                                                        <div>{row.website !== null ? (
                                                            <div>
                                                                <div className="table-tooltip-info">
                                                                    <Link />
                                                                    {row.website}
                                                                    <div className="table-tooltip-info-open table-tooltip-info__btn" onClick={() => sendWeb(row.website)}>
                                                                        Open
                                                                    </div>
                                                                    <div className="table-tooltip-info-copy table-tooltip-info__btn" onClick={() => handleCopyClick(row.website, 'Site address')}>
                                                                        Copy
                                                                    </div>
                                                                </div>
                                                                <span className='table-tooltip' onClick={() => onSetSidebarOpen(true, row.id)} style={{ cursor: 'pointer' }}>
                                                                    {row.website}
                                                                </span></div>
                                                        ) : (<div></div>)}
                                                        </div>
                                                    )}


                                                </TableCell >
                                                {/* <Tooltip onClick={() => sendWeb(row.website)} title={row.website} placement="top" arrow>
                                                           
                                                        </Tooltip> */}
                                                <TableCell onClick={() => onSetSidebarOpen(true, row.id)} style={{ cursor: 'pointer' }} className='table-phone'>
                                                    {loading ? (
                                                        <Skeleton variant="rounded" width={"110rem"} height={"21rem"} />
                                                    ) : (
                                                        <div><span className={`icon-flag`} style={{ backgroundImage: `url(${process.env.PUBLIC_URL}/img/flags/4x3/${row.country}.svg)` }}></span> {row.phone}</div>
                                                    )}
                                                </TableCell>
                                                <TableCell onClick={() => onSetSidebarOpen(true, row.id)} style={{ cursor: 'pointer' }} className='table-name'>
                                                    {loading ? (
                                                        <Skeleton variant="rounded" width={"100rem"} height={"21rem"} />
                                                    ) : (
                                                        <span>{row.name}</span>
                                                    )}

                                                </TableCell>
                                                <TableCell onClick={() => onSetSidebarOpen(true, row.id)} style={{ cursor: 'pointer' }}>
                                                    {loading ? (
                                                        <Skeleton variant="rounded" width={"140rem"} height={"21rem"} />
                                                    ) : (
                                                        <span>{row.date}</span>
                                                    )}

                                                </TableCell>
                                                <TableCell onClick={() => onSetSidebarOpen(true, row.id)} style={{ cursor: 'pointer' }}>
                                                    {loading ? (
                                                        <Skeleton variant="rounded" width={"20rem"} height={"16rem"} />
                                                    ) : (
                                                        <span className={`icon-push`} style={{ background: `url(${process.env.PUBLIC_URL}/img/${row.push}.svg)` }}></span>
                                                    )}


                                                </TableCell>
                                                <TableCell onClick={() => onSetSidebarOpen(true, row.id)} style={{ cursor: 'pointer' }}>
                                                    {loading ? (
                                                        <Skeleton variant="rounded" width={"120rem"} height={"21rem"} />
                                                    ) : (
                                                        <div>
                                                            {row.subscription !== null ? (
                                                                <div className={'table-subscription'}><span className={`icon-${row.subscription}`}></span>{row.subscription}</div>
                                                            ) : (<div></div>)}
                                                        </div>
                                                    )}


                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <div className="pagination" style={{ margin: "20rem 0 0 auto", justifyContent: "flex-end" }}>
                                <Stack spacing={2}>
                                    <Pagination
                                        count={pages}
                                        siblingCount={1}
                                        page={activePage}
                                        onChange={handlePageChange}
                                        renderItem={(item) => (
                                            <PaginationItem
                                                slots={{ previous: ArrowBack, next: ArrowNext }}
                                                {...item}
                                            />
                                        )}
                                    />
                                </Stack>
                                <div className="pagination__page">100/Page</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {snackbarOpen && <Snackbar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarText={snackbarText} snackbarCount={snackbarCount} setSnackbarCount={setSnackbarCount} />}
        </Sidebar>
    );
}

export default Users;
