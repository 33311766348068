import React from 'react'
import './style.scss'

export default function ProductsInfo({ data }) {
    const items = {
        "Service": 0,
        "Physical Product": 0,
        "Digital Product": 0
    };
    data?.data?.countByType.forEach(item => {
        items[item.type] = item.count;
    });
    if(!data) {
        return
    }
    return (
        <div className="products-block">
            <div className="products-block-wrap">
                <div className="products-block-item">
                    <strong className={`${!data.data.total ? 'gray' : ''}`}>{data.data.total}</strong>
                    <span>Total items</span>
                </div>
                <div className="products-block-item">
                    <strong className={`${!data.data.externalProductsInAd ? 'gray' : ''}`}>{data.data.externalProductsInAd}</strong>
                    <span>In Ad</span>
                </div>
            </div>
            <div className="products-block-wrap">
                <div className="products-block-item">
                    <strong className={`${!items["Physical Product"] ? 'gray' : ''}`}>{items["Physical Product"]}</strong>
                    <span>Physical Product</span>
                </div>
                <div className="products-block-item">
                    <strong className={`${!items["Digital Product"] ? 'gray' : ''}`}>{items["Digital Product"]}</strong>
                    <span>Digital Product</span>
                </div>
                <div className="products-block-item">
                    <strong className={`${!items["Service"] ? 'gray' : ''}`}>{items["Service"]}</strong>
                    <span>Service</span>
                </div>
            </div>

        </div>
    )
}
