import { Box, Modal } from '@mui/material';
import React, { useState } from 'react'
import './style.scss'
import { gsap } from 'gsap';
import config from '../../../../config';
import axios from 'axios';
import Cookies from 'js-cookie';

export default function Invoice({ openInvoice, setOpenInvoice, external, date, amount, plan, id, setSnackbarOpen }) {



  const handleCloseInvoice = (e) => {
    // if (e.target.className.includes('MuiModal-backdrop')) {
    //   setOpenInvoice(false);
    //   return
    // }
    buttonAnimation(e.target)
    setTimeout(() => {
      setOpenInvoice(false);
    }, 500);
  }

  const [companyName, setCompanyName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [country, setCountry] = useState('')
  const [zipCode, setZipCode] = useState('')

  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
  };

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleCountryChange = (e) => {
    setCountry(e.target.value);
  };

  const handleZipCodeChange = (e) => {
    setZipCode(e.target.value);
  };


  const style = {
    width: "1240rem",
    background: '#fff',
    borderRadius: "24rem",
    padding: '0',
  };

  const buttonAnimation = (element) => {
    const tl = gsap.timeline();
    tl.to(element, {
      duration: 0.085,
      scale: 0.85,
      opacity: 0.85,
      ease: "power4.out",
    });
    tl.to(element, {
      duration: 0.55,
      scale: 1,
      opacity: 1,
      ease: "elastic.out(1,0.6)",
    });
  }

  const downloadPdf = async () => {
    try {
      const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('authToken')}`,
      };

      const body = {
        companyName: companyName,
        email: email,
        phone: phone,
        address: address,
        country: country,
        zipCode: zipCode,
        paymentId: id,
      };

      const response = await axios.post(
        `${config.apiBaseUrl}admins/payments/generate-invoice`,
        body,
        {
          headers,
          responseType: 'blob',
        }
      );
  
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `Invoice(${email}).pdf`;
      link.click();
      setOpenInvoice(false);
      setSnackbarOpen(true)
    } catch (e) {
      console.log(e);
    }
  };



  return (
    <Modal
      keepMounted
      open={openInvoice}
      onClose={handleCloseInvoice}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
      className="modal modal-invoice"
    >
      <Box sx={style} className="modal-container">
        <div className="modal-close" onClick={handleCloseInvoice}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M6 6L18 18" stroke="black" stroke-width="1.6" stroke-linecap="round" />
            <path d="M18 6L6 18" stroke="black" stroke-width="1.6" stroke-linecap="round" />
          </svg>
        </div>
        <div class="invoice">
          <div class="invoice-top">
            <div class="invoice-top-logo">
              <svg style={{ width: "180rem", height: '50rem' }} xmlns="http://www.w3.org/2000/svg" width="125" height="35" viewBox="0 0 125 35" fill="none">
                <g clip-path="url(#clip0_16541_126823)">
                  <path d="M72.2075 17.7508C72.1686 14.8347 71.1957 12.4241 69.2498 10.5189C67.304 8.6526 64.8522 7.71945 61.8556 7.71945C58.859 7.71945 56.3684 8.6526 54.4225 10.5189C52.4378 12.4241 51.4648 14.7958 51.4648 17.7119C51.4648 20.628 52.4378 22.9997 54.4225 24.866C56.3684 26.7323 58.859 27.6655 61.8556 27.6655C64.2296 27.6655 66.37 27.0045 68.238 25.6437C70.106 24.2828 71.3124 22.572 71.8573 20.4725H66.7592C66.0197 22.2221 64.1128 23.5441 61.8946 23.5441C59.2482 23.5441 57.1467 21.7944 56.524 19.2672H72.0518C72.1686 18.8395 72.2075 18.334 72.2075 17.7508ZM61.8946 11.8408C64.3463 11.8408 66.4867 13.2795 67.2651 15.4179H56.7186C57.5359 13.2406 59.5206 11.8408 61.8946 11.8408Z" fill="black" />
                  <path d="M94.2609 17.7508C94.222 14.8347 93.249 12.4241 91.3032 10.5189C89.3574 8.6526 86.9056 7.71945 83.909 7.71945C80.9124 7.71945 78.4217 8.6526 76.4759 10.5189C74.4911 12.4241 73.5182 14.7958 73.5182 17.7119C73.5182 20.628 74.4911 22.9997 76.4759 24.866C78.4217 26.7323 80.9124 27.6655 83.909 27.6655C86.2829 27.6655 88.4234 27.0045 90.2914 25.6437C92.1594 24.2828 93.3658 22.572 93.9106 20.4725H88.8125C88.0731 22.2221 86.1662 23.5441 83.9479 23.5441C81.3016 23.5441 79.2001 21.7944 78.5774 19.2672H94.1052C94.222 18.8395 94.2609 18.334 94.2609 17.7508ZM83.9479 11.8408C86.3997 11.8408 88.5401 13.2795 89.3184 15.4179H78.772C79.5892 13.2406 81.574 11.8408 83.9479 11.8408Z" fill="black" />
                  <path d="M96.6223 27.3155H101.682V0.332031H96.6223V27.3155Z" fill="black" />
                  <path d="M113.903 20.2003L108.533 8.10826H103.123L111.529 25.2548L106.587 35.014H111.88L124.45 8.10826H119.157L113.903 20.2003Z" fill="black" />
                  <path d="M45.442 8.10742H51.2243L37.9737 27.3153H32.1914L45.442 8.10742Z" fill="black" />
                  <path d="M32.7109 8.10742H46.9034V12.4386H32.7109V8.10742Z" fill="black" />
                  <path d="M36.625 22.9844H50.2716V27.3156H36.625V22.9844Z" fill="black" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M24.7337 21.5742H0.629203C0.528734 21.2644 0.439902 20.9494 0.363221 20.6296H24.9997C24.923 20.9494 24.8342 21.2644 24.7337 21.5742ZM20.6732 27.4883H4.68974C4.32552 27.192 3.9781 26.876 3.64913 26.5417H21.7138C21.3848 26.876 21.0374 27.192 20.6732 27.4883ZM1.41837 23.4656C1.62971 23.8747 1.86272 24.2708 2.11589 24.6523H23.2471C23.5002 24.2708 23.7332 23.8747 23.9446 23.4656H1.41837ZM12.6815 30.3242C10.9785 30.3242 9.35395 29.9885 7.87064 29.3796H17.4923C16.009 29.9885 14.3845 30.3242 12.6815 30.3242ZM0.0598164 18.7422C0.0334902 18.4285 0.01862 18.1115 0.015625 17.7917H25.3473C25.3443 18.1115 25.3295 18.4285 25.3031 18.7422H0.0598164ZM0.137801 15.9023C0.194075 15.5004 0.269251 15.1045 0.362405 14.7156H25.0005C25.0937 15.1045 25.1689 15.5004 25.2251 15.9023H0.137801ZM0.977499 12.8242C1.11174 12.5011 1.259 12.1847 1.41863 11.8757H23.9443C24.1039 12.1847 24.2512 12.5011 24.3854 12.8242H0.977499ZM2.61919 9.98438C2.87109 9.65579 3.13876 9.3399 3.42106 9.03784H21.9419C22.2242 9.3399 22.4918 9.65579 22.7438 9.98438H2.61919ZM12.6815 5.01758C15.2841 5.01758 17.7035 5.80175 19.7156 7.14648H5.64733C7.65947 5.80175 10.0788 5.01758 12.6815 5.01758Z" fill="black" />
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8795 9.98633C11.4086 9.29622 12.0072 8.66212 12.6648 8.09448H4.38945C3.73178 8.66212 3.13319 9.29622 2.60413 9.98633H10.8795ZM10.2203 10.9324H1.94486C1.56694 11.531 1.23788 12.1635 0.963251 12.8242H9.23864C9.51327 12.1635 9.84234 11.531 10.2203 10.9324ZM0.123563 15.8984C0.226296 15.1671 0.391605 14.4559 0.613909 13.7703H8.8893C8.667 14.4559 8.50169 15.1671 8.39895 15.8984H0.123563ZM0.0267099 16.8445C0.00899482 17.1183 0 17.3945 0 17.6729C0 18.031 0.0148963 18.3857 0.0441038 18.7363H8.31949C8.29029 18.3857 8.27539 18.031 8.27539 17.6729C8.27539 17.3945 8.28438 17.1183 8.3021 16.8445H0.0267099ZM0.613546 21.5743C0.415312 20.9627 0.262426 20.3308 0.15885 19.6824H8.43424C8.53782 20.3308 8.6907 20.9627 8.88894 21.5743H0.613546ZM0.962787 22.5204C1.27372 23.2687 1.65448 23.9807 2.09694 24.6484H10.3723C9.92987 23.9807 9.54911 23.2687 9.23818 22.5204H0.962787ZM4.67229 27.4883C3.97994 26.9249 3.34833 26.2898 2.78879 25.5945H11.0642C11.6237 26.2898 12.2553 26.9249 12.9477 27.4883H4.67229ZM6.00059 28.4343C7.9368 29.6337 10.2207 30.3262 12.6664 30.3262C14.1153 30.3262 15.5074 30.0831 16.8041 29.6357C15.912 29.3278 15.0651 28.9232 14.276 28.4343H6.00059ZM16.8041 5.71005C15.7716 6.06637 14.7996 6.55233 13.9077 7.14844H5.63227C7.64441 5.8037 10.0637 5.01953 12.6664 5.01953C14.1153 5.01953 15.5074 5.26257 16.8041 5.71005Z" fill="black" />
                </g>
                <defs>
                  <clipPath id="clip0_16541_126823">
                    <rect width="124.444" height="35" fill="black" />
                  </clipPath>
                </defs>
              </svg>
            </div>

            <div class="invoice-top-center">
              <h2>Zeely, Inc</h2>
              <p>2810 N Church St, Ste 27833
              </p>
              <p>Wilmington, Delaware 19802-4447
              </p>
              <p>United States of America (USA)
              </p>
              <p>finance@zeely.app</p>
            </div>
            <div class="invoice-top-right">
              <p> INVOICE
                <span>{external}</span>
              </p>
              <p> DATE
                <span>{date}
                </span>
              </p>
              <p> DUE
                <span>On Receipt</span>
              </p>

            </div>
          </div>
          <div class="invoice-middle">
            <h3>BILL TO</h3>
            <div className="invoice-inputs" style={{ marginBottom: '20rem' }}>
              <div className="input">
                <span>Company name*</span>
                <input type="text" placeholder='Enter your company name' value={companyName} onChange={handleCompanyNameChange}/>
              </div>
              <div className="input">
                <span>Email*</span>
                <input type="text" placeholder='Enter your email' value={email} onChange={handleEmailChange}/>
              </div>
            </div>
            <div className="input" style={{ marginBottom: '20rem' }}>
              <span>Phone Number*</span>
              <input type="text" placeholder='Enter your phone number' value={phone} onChange={handlePhoneChange}/>
            </div>
            <div className="input" style={{ marginBottom: '20rem' }}>
              <span>Address*</span>
              <input type="text" placeholder='Enter your Address' value={address} onChange={handleAddressChange}/>
            </div>

            <div className="invoice-inputs" style={{ marginBottom: '20rem' }}>
              <div className="input">
                <span>Country*</span>
                <input type="text" placeholder='Enter your country' value={country} onChange={handleCountryChange}/>
              </div>
              <div className="input">
                <span>Zip code*</span>
                <input type="text" placeholder='Enter your zip code' value={zipCode} onChange={handleZipCodeChange} />
              </div>
            </div>

          </div>
          <table>
            <thead>
              <tr>
                <td>DESCRIPTION</td>
                <td>RATE</td>
                <td>QTY</td>
                <td>AMOUNT</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Subscription Plan ({plan})</td>
                <td>${amount}</td>
                <td>1</td>
                <td>${amount}</td>
              </tr>
            </tbody>
          </table>
          <div class="invoice-bottom">
            <p>
              <span>SUBTOTAL</span>
              <span>${amount}</span>
            </p>
            <p>
              <span>SERVICE CHARGE</span>
              <span>$0</span>
            </p>

            <p class="border">
              <span>TOTAL</span>
              <span>${amount}</span>
            </p>
            <p class="border">
              <span>AMOUNT DUE</span>
              <strong>USD ${amount}</strong>
            </p>
          </div>
          <div className="modal-action">
            <div className="modal__cancel" onClick={handleCloseInvoice}>Cancel</div>
            <div className="modal__send" onClick={(e) => {


downloadPdf();

            }} >Download Invoice</div>
          </div>
        </div>



      </Box>
    </Modal>
  )
}
