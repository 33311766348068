import axios from 'axios';
import Cookies from 'js-cookie';
import config from '../../../../config';

export const fetchAdminsList = async () => {
    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('authToken')}`,
        // 'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkremVCJ9.eyJpZCI6IjA5YjIwNzc3LTVlODEtNDM1NC1hMzk1LWNjNDllMmEwNDhhMSIsImVtYWlsIjoiYWRtaW5AemVlbHkuYXBwIiwicm9sZSI6ImFkbWluIiwiaWF0IjoxNzExMzQyNjAzLCJleHAiOjE3MTE0MjkwMDN9.GW3yB8N8VJcXojgDedxnKp1qNfUAedFA44eQnmnjvSw`,
    };

    const url = `${config.apiBaseUrl}admins/list`;

    const params = {
        "isActive": true,
        filter: JSON.stringify({ "roleNames": ["ad_manager"] }),
    }
    const response = await axios.get(url, {
        headers,
        params
    });
    return response.data.data;
};
