import React, { useEffect, useState } from 'react'
import './style.scss'
import { useQuery } from 'react-query'
import Cookies from 'js-cookie';
import axios from 'axios';
import config from '../../../../config';

const fetchPromationTotal = async (activeUserId) => {
    if (!activeUserId) {
        return null;
    }
    const url = `${config.apiBaseUrl}admins/facebook-ads`;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('authToken')}`,
    };
    const skip = 1 * 20 - 20;
    const params = {
        skip: skip,
        limit: 250,
        filter: JSON.stringify({ userId: activeUserId }),
    }
    const response = await axios.get(url, {
        headers,
        params
    });
    return response.data;
};

const fetchPromationApproved = async (activeUserId,) => {
    if (!activeUserId) {
        return null;
    }
    const url = `${config.apiBaseUrl}admins/facebook-ads`;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('authToken')}`,
    };
    const skip = 1 * 20 - 20;
    const params = {
        skip: skip,
        limit: 250,
        filter: JSON.stringify({ userId: activeUserId, "status": "APPROVED" }),
    }
    const response = await axios.get(url, {
        headers,
        params
    });
    return response.data;
};

const fetchPromationRejected = async (activeUserId) => {
    if (!activeUserId) {
        return null;
    }
    const url = `${config.apiBaseUrl}admins/facebook-ads`;

    const headers = {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${Cookies.get('authToken')}`,
    };
    const skip = 1 * 20 - 20;
    const params = {
        skip: skip,
        limit: 250,
        filter: JSON.stringify({ userId: activeUserId, "status": "REJECTED" }),
    }
    const response = await axios.get(url, {
        headers,
        params
    });
    return response.data;
};

export default function PromotionStat({ activeUserId }) {
    const { isLoading: isLoadingTotal, error: errorTotal, data: promotionTotal } = useQuery(['promotionTotal', activeUserId], () => fetchPromationTotal(activeUserId));
    const { isLoading: isLoadingApproved, error: errorApproved, data: promotionApproved } = useQuery(['promotionApproved', activeUserId], () => fetchPromationApproved(activeUserId));
    const { isLoading: isLoadingRejected, error: errorRejected, data: promotionRejected } = useQuery(['promotionRejected', activeUserId], () => fetchPromationRejected(activeUserId));

    const [total, setTotal] = useState('');
    const [rejected, setRejected] = useState('');
    const [approved, setApproved] = useState('');

    useEffect(() => {
        if (isLoadingTotal) {
        }

        if (!isLoadingTotal && !errorTotal && promotionTotal) {
            setTotal(promotionTotal.data.total)
        }
    }, [isLoadingTotal, errorTotal, promotionTotal]);
    useEffect(() => {
        if (isLoadingApproved) {
        }

        if (!isLoadingApproved && !errorApproved && promotionApproved) {

            setApproved(promotionApproved.data.total)
        }
    }, [isLoadingApproved, errorApproved, promotionApproved]);

    useEffect(() => {
        if (isLoadingRejected) {
        }

        if (!isLoadingRejected && !errorRejected && promotionRejected) {
            setRejected(promotionRejected.data.total)
        }
    }, [isLoadingRejected, errorRejected, promotionRejected]);

    return (
        <div className="promotionsNew-stat">
            <div className="promotions-block">
                <div className="promotionsNew-stat-item">
                    <strong>{total}</strong>
                    <span>Total ads</span>
                </div>
                <div className="promotionsNew-stat-item">
                    <strong>{approved}</strong>
                    <span>Approved</span>
                </div>
                <div className="promotionsNew-stat-item">
                    <strong>{rejected}</strong>
                    <span>Rejected</span>
                </div>
            </div>
        </div>
    )
}
