const Link = () => {
    return (
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1756_29892)">
                <path d="M5.34887 3.15113C6.44882 2.05118 8.83203 -0.332032 11.5819 2.41783C14.3318 5.16769 11.9485 7.5509 10.8486 8.65084" stroke="white" stroke-linecap="round" />
                <path d="M5.34887 3.15113C6.44882 2.05118 8.83203 -0.332032 11.5819 2.41783C14.3318 5.16769 11.9485 7.5509 10.8486 8.65084" stroke="white" stroke-linecap="round" />
                <path d="M3.14722 5.35278C2.04727 6.45273 -0.335938 8.83594 2.41392 11.5858C5.16378 14.3357 7.54699 11.9524 8.64694 10.8525" stroke="white" stroke-linecap="round" />
                <path d="M4.61639 9.38361L9.38281 4.61719" stroke="white" stroke-linecap="round" />
            </g>
            <defs>
                <clipPath id="clip0_1756_29892">
                    <rect width="14" height="14" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
}

export default Link;
